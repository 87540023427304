export default {
  computed: {
    isCH() {
      return this.countryCode == this.chCountryCode
    },
    countryCode() {
      return this.$store.state.common.countryCode
    },
    showAppleSolidpay() {
      //only safari show
      return this.appleSolidpayIncludeList.includes(parseInt(this.countryCode)) && this.isSafari
    },
    isSafari() {
      return (
        navigator.vendor &&
        navigator.vendor.indexOf('Apple') > -1 &&
        navigator.userAgent &&
        navigator.userAgent.indexOf('CriOS') == -1 &&
        navigator.userAgent.indexOf('FxiOS') == -1
      )
    },
  },
}
