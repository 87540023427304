// global variable
import { PROMOTION_TYPE } from '@/constants/promotion'
// global function
import { accountTypeMaps } from '@/config'
// services
import { apiCampaignInfo } from '@/resource'
import { isSuccess } from '@/util/restApi'

const state = () => ({
  optIn: true, // true: availableForOptIn, BE will always return false once user has been opt-in.
  participantStatus: 'OO', //  just focus on 'E' status. 'E' means can deposit
  participateAccount: null, // formatted participate account from api data
  canSpin: 0, // luckyDraw status => 0: not show luckyDraw, 1: show luckyDraw, 2: promotion over, 3: comming soon. status 3 is added by FE (BE does not return this value), for Xmas coming soon duration.
  currentPrize: {
    // luckyDraw current prize
    deposit: 0,
    tradingLots: 0,
    currentPrizeList: [],
  },
  transactionHistory: [], // transaction history data
  giftRedemptionData: {}, // git redemption data
  currentDepositLotsData: {
    // current user "deposit" & "lots" data
    deposit: '-',
    lots: '-',
  },
  canCreditRedeem: 0, // 0: notAllow, 1: alreadyRedeemed, 2: canRedeem
  canVoucherRedeem: 0, // 0: notAllow, 1: alreadyRedeemed, 2: canRedeem
  callbackErrorMsg: '',
  showResultDialog: false,
  callbackStatus: null, // success, failNotOptout, failOptout, redeemCreditAndVoucher
  showLuckyDrawDialog: false,
  luckyDrawType: null,
  startLuckyDraw: false,
  prize: "",
})

const mutations = {
  UPDATE_OPT_IN: (state, payload) => (state.optIn = payload),
  UPDATE_PARTICIPANT_STATUS: (state, payload) => (state.participantStatus = payload),
  SET_PARTICIPATE_ACCOUNT: (state, payload) => (state.participateAccount = payload),
  UPDATE_CAN_SPIN: (state, payload) => (state.canSpin = payload),
  UPDATE_CURRENT_PRIZE: (state, payload) => (state.currentPrize = { ...payload }),
  SET_TRANSACTION_HISTORY: (state, payload) => (state.transactionHistory = [...payload]),
  SET_GIFT_REDEMPTION_DATA: (state, payload) => (state.giftRedemptionData = { ...payload }),
  UPDATE_CURRENT_DEPOSIT_LOTS_DATA: (state, payload) => (state.currentDepositLotsData = { ...payload }),
  SET_CAN_CREDIT_REDEEM: (state, payload) => (state.canCreditRedeem = payload),
  SET_CAN_VOUCHER_REDEEM: (state, payload) => (state.canVoucherRedeem = payload),
  SET_CALLBACK_ERROR_MSG: (state, payload) => (state.callbackErrorMsg = payload),
  SET_SHOW_RESULT_DIALOG: (state, payload) => (state.showResultDialog = payload),
  SET_CALLBACK_STATUS: (state, payload) => (state.callbackStatus = payload),
  SET_SHOW_LUCKYDRAW_DIALOG: (state, payload) => (state.showLuckyDrawDialog = payload),
  SET_LUCKYDRAW_TYPE: (state, payload) => (state.luckyDrawType = payload),
  SET_START_LUCKYDRAW: (state, payload) => (state.startLuckyDraw = payload),
  SET_PRIZE: (state, payload) => (state.prize = payload),
}

const actions = {
  actionFetchCampaignInfo: async ({ commit }, campaignID) => {
    try {
      const { data: apiData } = await apiCampaignInfo(campaignID)

      if (isSuccess(apiData)) {
        const {
          canOptIn,
          participantStatus,
          accountMt4Dto,
          canSpin,
          currentPrize,
          transactionHistory,
          creditBonus,
          cashVoucher,
          physicalRewards,
          depositAmount,
          lotsTraded,
          canCreditRedeem,
          canVoucherRedeem,
        } = apiData.data

        commit('UPDATE_OPT_IN', !canOptIn)
        commit('UPDATE_PARTICIPANT_STATUS', participantStatus)

        if (accountMt4Dto) {
          commit('SET_PARTICIPATE_ACCOUNT', {...accountMt4Dto})
        }

        commit('UPDATE_CAN_SPIN', Number(canSpin) || 0)

        commit(
          'UPDATE_CURRENT_PRIZE',
          currentPrize || {
            deposit: 0,
            tradingLots: 0,
            currentPrizeList: [],
            groupId: 0,
          }
        )

        if (transactionHistory?.length) {
          commit('SET_TRANSACTION_HISTORY', [...transactionHistory])
        }

        commit('SET_GIFT_REDEMPTION_DATA', {
          creditBonus: creditBonus || '-',
          cashVoucher: cashVoucher || '-',
          physicalRewards: physicalRewards && physicalRewards.length ? [...physicalRewards] : [],
        })

        commit('UPDATE_CURRENT_DEPOSIT_LOTS_DATA', {
          deposit: depositAmount,
          lots: lotsTraded,
        })

        commit('SET_CAN_CREDIT_REDEEM', canCreditRedeem)
        commit('SET_CAN_VOUCHER_REDEEM', canVoucherRedeem)
      }
    } catch (error) {
      commit('SET_CALLBACK_ERROR_MSG', error)
    }
  },
  actionSetOptnIn: ({ commit }, value) => commit('UPDATE_OPT_IN', value),
  actionSetCallbackErrorMsg: ({ commit }, message) => commit('SET_CALLBACK_ERROR_MSG', message),
  actionSetShowResultDialog: ({ commit }, value) => commit('SET_SHOW_RESULT_DIALOG', value),
  actionSetCallbackStatus: ({ commit }, value) => commit('SET_CALLBACK_STATUS', value),
  actionClosCbDialog({ commit }) {
    commit('SET_SHOW_RESULT_DIALOG', false)
    setTimeout(() => {
      commit('SET_CALLBACK_STATUS', null)
    }, 300)
  },
  actionSetShowLuckyDrawDialog: ({ commit }, value) => commit('SET_SHOW_LUCKYDRAW_DIALOG', value),
  actionSetLuckyDrawType: ({ commit }, value) => commit('SET_LUCKYDRAW_TYPE', value),
  actionSetStartLuckyDraw: ({ commit }, value) => commit('SET_START_LUCKYDRAW', value),
  actionSetPrize: ({ commit }, value) => commit('SET_PRIZE', value),
}

const getters = {
  campaignID: (state, getters, rootState) => {
    let campaign =
      rootState.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.XMASMAKEAWISH) || {}
    
    return campaign.id ? campaign.id : null;
  },
  getOptParticipantStatus: state => {
    // availableForOptIn, alreadyOptIn, alreadyOptOut
    if (!state.optIn) {
      return 'availableForOptIn'
    } else {
      return state.participantStatus === 'E' ? 'alreadyOptIn' : 'alreadyOptOut'
    }
  },
  getFormatParticipateAccount: state => {
    if (state.participateAccount && Object.keys(state.participateAccount).length) {
      const { mt4Account, currency, accountDisplayType } = state.participateAccount
      let accountType = accountTypeMaps[Number(accountDisplayType)] || null
      return accountType ? `${mt4Account} - ${accountType} - ${currency}` : `${mt4Account} - ${currency}`
    }

    return ""
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
