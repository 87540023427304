<template>
  <el-form :model="form" :rules="rules" ref="ruleForm" class="id-form">
    <div class="title">
      {{ $t('identityProof.shufti.content') }}
    </div>
    <ul>
      <li>
        <el-form-item :label="$t('register.personalDetails.page1.firstName')" prop="firstName">
          <el-input v-model="form.firstName" data-testid="firstName" maxlength="128" type="text"></el-input>
        </el-form-item>
      </li>
      <li>
        <el-form-item :label="$t('register.personalDetails.page1.middleName')" prop="middleName">
          <el-input
            v-model="form.middleName"
            data-testid="middleName"
            id="middleName"
            maxlength="128"
            type="text"
          ></el-input>
        </el-form-item>
      </li>
    </ul>
    <ul>
      <li>
        <el-form-item :label="$t('register.personalDetails.page1.lastName')" prop="lastName">
          <el-input v-model="form.lastName" data-testid="lastName" id="lastName" maxlength="128" type="text"></el-input>
        </el-form-item>
      </li>
      <li>
        <el-form-item :label="$t('identityProof.shufti.countryOfDocument')" prop="countryId">
          <el-select v-model="form.countryId" id="countryCode" data-testid="countryId" filterable>
            <el-option
              v-for="(item, index) in countries"
              :key="index"
              :label="item.countryName"
              :value="item.id"
              :data-testid="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </li>
    </ul>
    <ul>
      <li>
        <el-form-item :label="$t('register.personalDetails.page1.idType')" prop="idType">
          <el-select v-model="form.idType" data-testid="idType" id="idType" filterable>
            <el-option
              v-for="(item, index) in identificationTypes"
              :key="index"
              :label="item.label"
              :value="item.value"
              :data-testid="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
      </li>
      <li>
        <el-form-item :label="idTypeLabel" prop="idNumber">
          <el-input v-model="form.idNumber" data-testid="idNumber" id="idNumber" type="text" />
        </el-form-item>
      </li>
    </ul>
  </el-form>
</template>

<script>
export default {
  props: {
    userData: Object
  },
  name: 'idPoaForm',
  data() {
    return {
      form: {
        firstName: null,
        middleName: null,
        lastName: null,
        countryId: null,
        idType: null,
        idTypeLabel: null
      },
      rules: {
        firstName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.firstNameReq')
          },
          {
            max: 50,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 50 })
          },
          {
            min: 1,
            message: this.$t('common.formValidation.noteLessThan', { limit: 1 })
          }
        ],
        lastName: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.lastNameReq')
          },
          {
            min: 1,
            message: this.$t('common.formValidation.noteLessThan', { limit: 1 })
          },
          {
            max: 50,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 50 })
          }
        ],
        countryId: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.countryReq')
          }
        ],
        idType: [
          {
            required: true,
            trigger: 'change',
            message: this.$t('common.formValidation.idTypeReq')
          }
        ],
        idNumber: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.personalDetails.page1.idNum')
            })
          },
          {
            max: 45,
            message: this.$t('common.formValidation.noteMoreThan', { limit: 45 })
          }
        ]
      },
      identificationTypes: [
        { value: 1, label: this.$t('register.personalDetails.page1.idTypeDropdown.idCard') },
        { value: 2, label: this.$t('register.personalDetails.page1.idTypeDropdown.passport') },
        { value: 3, label: this.$t('register.personalDetails.page1.idTypeDropdown.driverLicense') }
      ]
    };
  },
  mounted() {
    this.getUserDate();
    this.$store.dispatch('register/actionCountries', this.lang);
  },
  methods: {
    getUserDate() {
      this.form = { ...this.userData };
    }
  },
  computed: {
    lang() {
      return this.$store.state.common.lang;
    },
    countries() {
      return this.$store.state.register.countries;
    },
    idTypeLabel() {
      switch (this.form.idType) {
        case 2:
          return this.$t('register.personalDetails.page1.passportNum');
        case 3:
          return this.$t('register.personalDetails.page1.driverLicenseNum');
        default:
          return this.$t('register.personalDetails.page1.idNum');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/personalDetails.scss';
</style>
