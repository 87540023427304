export const NO_DEPOSIT_BONUS_3_PATHNAME = '/noDepositBonus/'
export const REFER_A_FRIEND_PATHNAME = '/referFriends'
export const DEPOSIT_BONUS_PATHNAME = '/depositBonus'
export const TRADING_BONUS_PATHNAME = '/tradingBonus'
export const DEPOSIT_PATHNAME = '/depositFunds'
export const CASHBACK_PATHNAME = '/cryptoCashback'
export const DEPOSIT_20_BONUS_PATHNAME = '/deposit20Bonus'
export const XMAS_MAKE_A_WISH_PATHNAME = '/xmasMakeAWish'
export const FIRST_DEPOSIT_CASHBACK_PATHNAME = '/firstDepositCashback'
export const MILLION_DOLLAR_CASH_POOL_PATHNAME = '/millionDollarCashPool'
export const FIRST_TIME_DEPOSIT_PATHNAME = '/firstTimeDeposit'
export const PROP_TRADING = '/propTrading/createAccount'
export const PROP_TRADING_ACHIEVEMENT = '/propTrading/achievement'
export const RUGBY_PATHNAME = '/rugby'
export const XMAS_SPIN_AND_WIN_PATHNAME = '/xmasSpinAndWin'
export const DEPOSIT_MY_20_BONUS_PATHNAME = '/depositMY20Bonus'
export const DYNAMIC_DEPOSIT_BONUS_PATHNAME = '/depositBonus/'
export const NFP_PATHNAME = '/nfp'
export const VDAY_PROMO_PATHNAME = '/vdayPromo'
export const TRADING_CONTEST_PATHNAME = '/tradingContest/'
export const EASTER = 'https://puprime.com/easter-promotion/'
export const EURO2024_PATHNAME = '/euro2024'
export const OLYMPIC2024_PATHNAME = '/olympic2024'
export const FUNDS_GROWTH_PROMO_PATHNAME = '/fundsGrowthPromotion'

//register
export const REGISTERROUTER = {
  REGISTER: '/register',
  ACC_OPENING_VERIFICATION: '/register/accOpeningVerification',
  PERSONAL_INFORMATION: '/register/accOpeningVerification/personalInformation',
  CREATE_LIVE_ACCOUNT: '/register/accOpeningVerification/createLiveAccount',
  IDENTITY_VERIFICATION: '/register/identityVerification',
  POA_VERIFICATION: '/register/poaVerification',
  FINANCIAL_VERIFICATION: '/register/financialVerification',
  FINANCIAL_INFO: 'financialInfo',
  ID_PROOF: 'iDProof',
  ADDRESS_PROOF: 'addressProof',
}
