export default {
  common: {
    tnc: 'Si prega di accettare i Termini e le Condizioni del Trading Proprietario di PU.',
    cancellation: "Si prega di accettare l'Accordo del Trader di Trading Proprietario PU.",
    field: {
      reversedfailed: 'Fallimento Invertito',
      cardNumber: 'Numero di carta',
      beneficiaryAccountName: 'Nome del Conto Beneficiario',
      beneficiaryBankSwiftCode: 'Codice Swift della Banca del Beneficiario',
      beneficiaryAccountNumber: 'Numero del Conto Beneficiario',
      beneficiaryBankName: 'Nome della Banca del Beneficiario',
      BeneficiaryBankAddress: 'Indirizzo della Banca del Beneficiario',
      paymentReference: 'Riferimento di Pagamento:**',
      paymentReferenceContext:
        'Si prega di includere {accountNumber} nel campo di riferimento del bonifico bancario.',
      bankIbanNum: 'Numero IBAN del Beneficiario',
      beneficiaryBankBsbNumber: 'Numero BSB della Banca Beneficiaria',
      beneficiaryName: 'Nome del beneficiario',
      supermarket: 'Negozio di Taiwan',
      taiwanBankCode3digits: 'Codice bancario taiwanese (3 cifre)',
      partialsuccess: 'Successo Parziale',
    },
    column: {
      date: 'DATA',
      type: 'TIPO',
      server: 'SERVER',
      status: 'STATO',
      comment: 'COMMENTO',
      accNum: 'NUMERO DI CONTO',
      currency: 'VALUTA',
      equity: 'EQUITY',
      credits: 'CREDITI',
      balance: 'BILANCIA',
      leverage: 'LEVA',
      resetMT4PW: 'REIMPOSTA LA PASSWORD MT4',
      resetMT5PW: 'REIMPOSTA LA PASSWORD MT5',
      tradingAcc: 'CONTO DI TRADING',
      method: 'METODO',
      amt: 'IMPORTO',
      procNote: 'NOTE SUL PROCESSO',
      number: 'NUMERO DI CARTA',
      resetBal: 'RESETTA SALDO',
      remove: 'RIMUOVI',
      remark: 'OSSERVAZIONE',
    },
    withdrawChannel: {
      ewallet: 'Portafoglio elettronico',
      airtm: 'AirTM',
      dutchinstantbanktransfer: 'Bonifico bancario istantaneo olandese',
      internetbankingtaiwan: 'Banca online (Taiwan)',
      internetbankingeu: 'Banca online (UE)',
    },
    formValidation: {
      selectBAReq: 'Seleziona un conto bancario per procedere.',
      validateCcDetails:
        'Si prega di compilare tutti i dettagli della carta di credito prima di inviare la richiesta di prelievo.',
      clickUpdate: 'Fare clic sul pulsante di aggiornamento in basso',
      beneficiaryNameReq: 'Il nome del beneficiario della banca è richiesto',
      copyTradingTnc: 'Si prega di accettare i Termini e Condizioni del Copy Trading',
    },
    button: {
      copyTrade: 'Copia Commercio',
      resetPassword: 'Reimposta password',
      resetBalance: 'Resetta Saldo',
      continue: 'Continua',
    },
  },
  menu: {
    propTradeCreateAccount: 'Crea Account',
    propTradeAchievement: 'Risultati',
    propTradeDashboard: 'Cruscotto',
    euro2024: 'Euro 2024',
    fgp: '',
  },
  propTrade: {
    tags: {},
    mainTitle: 'Pannello delle Sfide',
    end: 'Fine',
    payout: 'Pagamento',
    achievementTitle: 'Raggiungimento',
    certificates: 'Certificati',
    howItWorks: {
      title: 'Come funziona',
      desc: 'Registrati e dimostra le tue capacità di trading per qualificarti per un conto di trading in cui puoi guadagnare denaro reale operando su un conto demo.',
      register: 'Registrati',
      registerDesc: {
        1: 'Dimostra le tue capacità di trading e diventa un Trader PU',
        2: 'Ottieni finanziamenti fino a $200,000 in capitale simulato, ma guadagna denaro reale dai profitti.',
        3: "Ricevi indietro l'intera quota di registrazione",
      },
      trade: 'Commercia',
      tradeDesc: {
        1: 'Due modalità di rischio: Base e Plus (maggior tolleranza al rischio e obiettivo di profitto)',
        2: 'Incontra gli obiettivi di profitto ed evita i limiti di perdita',
        3: 'Effettua trading per un minimo di quattro giorni',
        4: 'Soddisfa tutti i requisiti per qualificarti per un conto PU Trader',
      },
      getFunded: 'Ottieni Finanziamenti',
      getFundedDesc: {
        1: 'Account finanziato con il tuo Saldo Iniziale scelto al momento della registrazione',
        2: 'Fai trading in un ambiente simulato "dal vivo" e ottieni l\'80% dei tuoi profitti di trading.',
        3: 'Tariffe di registrazione restituite con la tua prima divisione di profitto',
      },
    },
    reminder: {
      title: 'Promemoria',
      narrative:
        "Avremo bisogno della tua Prova di Identità per concederti l'accesso al tuo account PU Trader; e della tua Prova di Residenza per elaborare il tuo pagamento. Ti preghiamo di inviarli tramite la scheda Autenticazione.",
    },
    button: {
      more: 'Più',
      createNewAccount: 'Crea un Nuovo Account',
      viewMetrics: 'Visualizza Metrica',
      view: 'Visualizza',
      backToClientPortal: 'Torna al Portale Cliente',
      submitProceedPayment: 'Procedi al Pagamento',
      sent: 'Inviato',
      back: 'Indietro',
      proceedPayment: 'Procedi al Pagamento',
      apply: 'Applica',
      joinChallenge: 'Unisciti alla Sfida',
      join: 'Unisciti',
      payout: 'Pagamento',
      cancel: 'Annulla',
      changePwd: 'Cambia Password',
      downloadPdf: 'Scarica PDF',
      verifyNow: 'Verifica Ora',
      resetPassword: 'Reimposta Password',
      history: 'Storia',
      yes: 'Sì',
    },
    dashboard: {
      accounts: 'Conti',
      paymentStatus: 'Stato del Pagamento',
      completeAuthentication: "Esegui l'autenticazione completa per accedere a PU Trader",
      accountStatusInfo: {
        1: 'Attivo : la tua sessione attuale è in corso e il trading è abilitato',
        2: 'Verifica : hai raggiunto tutti i tuoi obiettivi di trading e la tua cronologia di trading è in fase di valutazione',
        3: 'Superato : la tua valutazione di trading è stata un successo e stiamo preparando il tuo account PU Trader.',
        4: 'Fallito : hai raggiunto il limite di perdita o di tempo e hai fallito questa sessione',
        5: 'Elaborazione : il tuo pagamento è in fase di elaborazione',
      },
      PaymentStatusInfo: {
        1: 'Successo',
        2: 'Fallito',
        3: 'Elaborazione (contatta il servizio clienti se il tempo di elaborazione supera le 3 ore).',
      },
      applicationTime: 'Tempo di applicazione',
      paymentTime: 'Tempo di pagamento',
      failedTime: 'Tempo fallito',
    },
    resetPassword: {
      currentPwd: 'Password Attuale',
      currentPwdPlaceholder: 'Inserisci la password attuale',
      forgotPwd: 'Password dimenticata',
      newPwd: 'Nuova Password',
      newPwdPlaceholder: 'Si prega di inserire una nuova password',
      confirmNewPwd: 'Conferma nuova password',
      confirmNewPwdPlaceholder: 'Si prega di confermare la nuova password',
      account: 'Conto',
      info: 'Informazione',
      succ: "È stata inviata un'email per modificare la tua password.",
      succ2:
        'La tua password è stata aggiornata. Ora puoi utilizzare la tua nuova password per accedere al tuo account di trading MT4/MT5.',
      infoText:
        "Abbiamo appena inviato un'email con le istruzioni per reimpostare la tua password. Assicurati di controllare la cartella spam della tua email.",
      newPwdErrorMsg: 'Non è possibile utilizzare la Password attuale come Nuova Password',
      confirmNewPwdErrorMsg: 'La password non corrisponde alla Nuova Password',
    },
    achievement: {
      noData: 'Supera una Sfida o Valutazione per ricevere un certificato di riconoscimento.',
      certificate: 'Questo certificato è presentato a',
      description:
        'Per aver superato con successo la {stage}. Hanno dimostrato notevole disciplina e abilità nel trading e ora sono idonei a essere valutati come trader proprietario.',
    },
    selection: { newest: 'Prima i più recenti', oldest: 'Prima i più vecchi' },
    statusContext: {
      allStatus: 'Tutti gli Stati',
      active: 'Attivo',
      verifying: 'Verificando',
      passed: 'Superato',
      failed: 'Fallito',
      submitted: 'Inviato',
      processing: 'Elaborazione',
      payoutPending: 'Pagamento in Sospeso',
    },
    accountType: { basic: 'Basic', plus: 'Plus' },
    accountStageContext: {
      allStages: 'Tutte le Fasi',
      liveTrading: 'PU Trader',
      liveTradingPlus: 'PU Trader+',
      evaluation: 'Valutazione',
      evaluationPlus: 'Valutazione+',
      challenge: 'Sfida',
      challengePlus: 'Sfida+',
    },
    stageContext: { challenge: 'Sfida', evaluation: 'Valutazione', liveTrading: 'PU Trader' },
    sessionContext: {
      all: 'Tutti gli Stati',
      submitted: 'Inviato',
      expired: 'Scaduto',
      mismatch: 'Discrepanza',
      success: 'Successo',
      fail: 'Fallito',
      Processing: 'Elaborazione',
    },
    history: {
      title: 'Storia',
      sessionNumber: 'Numero di Sessione',
      field: { date: 'Data', event: 'Evento' },
      status: {
        0: 'Hai soddisfatto tutti i requisiti di trading! Ora sei idoneo per la prossima fase.',
        1: 'Gli obiettivi di trading non sono stati raggiunti. Puoi provare di nuovo.',
        2: 'Complimenti! Hai soddisfatto tutti i requisiti di trading per la fase di Valutazione. Il nostro team ti contatterà presto riguardo al programma PU Xtrader.',
        3: 'Stiamo elaborando la tua divisione dei profitti. Questo potrebbe richiedere fino a 3 giorni lavorativi.',
        4: 'Il tuo dividendo di profitto è pronto per il prelievo. Si prega di procedere al pagamento.',
        refunded: 'Commissione Rimborsata & Profitto Diviso',
        liveAccountCreated: 'Account Live Creato (Numero di Conto di Trading: {account})',
        verifyAccount: 'Verifica Account {account} Superata',
        challangeAccountPassed: 'Account {account} Superato la Sfida',
        challangeAccountCreated: 'Account Sfida {account} Creato',
        paymentSuccess: 'Pagamento Riuscito',
      },
      historyEvent: {
        0: 'Successo del Pagamento',
        1: 'Pagamento Fallito',
        2: '{accountStage} Account {accountNumber} Creato',
        3: '{accountStage} Account {accountNumber} Non riuscito',
        4: '{accountStage} Account {accountNumber} Passato',
        5: '{accountStage} Account {accountNumber} Verifica',
        6: 'Commissione Rimborsata ({currency}{amount})',
        7: 'Commissione Rimborsata Fallita',
        8: 'Divisione del Profitto ({currency}{amount})',
        9: 'Il Profit Split non è riuscito',
        10: 'Il tuo PU Trader è pronto per fare trading di nuovo!',
        11: 'Pagamento richiesto. PU Trader impostato solo lettura.',
      },
    },
    metricsPage: {
      mainTitle: 'Metriche',
      mainDesc:
        'Unisciti alla PU Xtrader Challenge e qualificati per diventare un PU Xtrader per iniziare a guadagnare denaro reale dai tuoi profitti di trading simulati.',
      equity: 'Patrimonio netto',
      infoContext:
        'Il patrimonio include il P&L di tutte le posizioni aperte e chiuse. Questo valore viene aggiornato ogni 10 minuti, controlla la tua piattaforma di trading per il valore in tempo reale.',
      initBalance: 'Saldo iniziale',
      endDate: 'Data di Fine',
      accountNo: "Numero dell'account",
      topInfoField: {
        leverage: 'Leva',
        server: 'Server',
        platform: 'Piattaforma',
        status: 'Stato',
        accountType: 'Tipo di Conto',
        startDate: 'Data di Inizio',
      },
      tradingGoals: 'Obiettivi di Trading',
      tradingGoalsDesc: 'Tieni traccia dei progressi per questo account',
      goals: 'Obiettivi',
      tradingGoalsFiels: {
        minTradingDay: 'Minimo {minTradingDay} Giorni di Trading',
        maxDailyLoss: 'Perdita Giornaliera Massima non superiore al {maxDailyLoss}',
        maxLoss: 'Perdita massima non superiore al {maxTotalLoss}',
        profitTarget: 'Obiettivo di Profitto {profitTarget}',
      },
      chart: {
        title: 'Prestazione Corrente',
        markLine: { profit: 'Profitto', maxLoss: 'Perdita Massima' },
        tooltip: {
          date: 'Data',
          equity: 'Patrimonio netto',
          trades: 'Numero di operazioni',
          maxDailyLoss: 'Perdita Massima Giornaliera',
        },
        toolbox: {
          zoom: 'Zoom',
          zoomReset: 'Ripristino Zoom',
          refresh: 'Aggiorna',
          saveImage: 'Salva Immagine',
        },
      },
      download: 'Scarica',
    },
    createAccount: {
      noBalance:
        'Impossibile creare un nuovo account PT. Il saldo totale della sessione non può superare 3.000.000.',
      narrative:
        'Unisciti a PU Xtrader e inizia a fare trading sul tuo account simulato per qualificarti per un account PU Xtrader.',
      chooseAccount: {
        title: 'Metti alla prova il tuo talento di trading con The PU Xtrader Challenge',
        subTitle: 'Scegli Account',
        desc: 'Completa alcuni passaggi per aprire un account e inizia la Sfida.',
        tradingPlatform: 'Piattaforma di Trading',
        accountCurrency: 'Scegli la Valuta del Conto',
        accountType: 'Scegli il Tipo di Account',
        accountTypeInfo:
          'Gli account Plus offrono un limite di perdita massimo maggiore, ma hanno obiettivi di profitto più alti e commissioni più elevate.',
        tradingGoals: 'Obiettivi di Trading',
        maxDailyLoss: 'Perdita Massima Giornaliera',
        maxLoss: 'Perdita Massima',
        targetProfit: 'Obiettivi di Trading',
        accountBalance: 'Saldo del Conto',
        leverage: 'Leva finanziaria',
        leverageInfo:
          '\n          <div>\n              <div>FX - 100:1</div>\n              <div>XAU - 30:1</div>\n              <div>XAG, Petrolio, Indici - 50:1</div>\n              <div>Azioni USA - 3.3:1</div>\n              <div>Crypto - 3:1</div>\n          </div>\n        ',
        agreeTermsAndConditions:
          'Sono d\'accordo a <a href="{link}" target="_blank" rel="">Termini e Condizioni di PU Xtrader</a>',
        agreeCancellationAndRefundPolicy:
          'Sono d\'accordo a <a href="{link}" target="_blank" rel="">Accordo PU Xtrader</a>',
        agreeTermsAndConditionsDesc:
          'Accettando i Termini e Condizioni di PU Xtrader, riconosci esplicitamente che il tuo consenso riguarda unicamente ed esclusivamente i nostri servizi PU Xtrader. Qualsiasi altra funzionalità e servizio che non sono correlati a PU Xtrader sono regolati da un insieme separato di termini e condizioni. Se desideri accedere ad altri servizi offerti da PU Prime, devi completare una registrazione separata.',
        openInstantAccount:
          'Apri un conto PUXtrader istantaneo. Non è necessaria una fase di valutazione.',
      },
      billingDetail: {
        title: 'Dettaglio Fatturazione',
        subTitle: 'Metodo di Pagamento',
        creditcard: 'Carta di credito',
        crypto: 'Cripto',
        bitcoin: 'Bitcoin',
        usdterc20: 'USDT-ERC20',
        usdttrc20: 'USDT-TRC20',
        eth: 'ETH',
        usdcerc20: 'USDC-ERC20',
        ewallet: 'Portafoglio elettronico',
        perfectMoney: 'Perfect Money',
        fasapay: 'FasaPay',
        alipay: 'Alipay',
        mobilePay: 'MobilePay',
        sticpay: 'SticPay',
        item: 'Articolo',
        accountType: 'Tipo di Conto',
        accountBalance: 'Saldo del Conto',
        targetProfit: 'Piattaforma di Trading',
        promoCode: 'Codice promozionale',
        cancelPromoCodeInfo: 'Sei sicuro di voler annullare il codice Promo?',
      },
      billingCreditCard: {
        title: 'Dettaglio Fatturazione',
        description:
          'Unisciti al PU Xtrader e inizia a fare trading sul tuo account simulato per qualificarti per un account live finanziato',
        subTitle: '',
      },
      payment: {
        payment: 'Pagamento',
        totalRegistrationFee: 'Tariffa Totale di Registrazione',
        totalRegistrationFeeInfo:
          'La tua quota di registrazione viene calcolata in base al tuo capitale iniziale scelto, convertito nella valuta del tuo account scelto.',
        paymentDetails: 'Dettagli del Pagamento',
        subtotal: 'Subtotale',
        discount: 'Sconto',
        grandTotalRegistrationFee: 'Tariffa Totale di Registrazione',
      },
      cryptoQrcode: { title: 'Dettagli del Pagamento' },
    },
    banner: {
      title:
        '<div class="title">Inizia il Tuo Viaggio<br/>A Un Conto Finanziato</div>\n      <div class="des">Fai trading con il capitale della nostra azienda e ottieni l\'80% dei profitti.</div>',
      dashboardBannerTitle: 'Inizia il Tuo Viaggio Verso un Conto Finanziato',
    },
    promoCode: {
      unknownError: 'Errore Sconosciuto',
      validPromoCode: 'Inserisci un codice promozionale valido',
      applyFirst: 'Applica il Codice Promozionale Prima',
    },
    payoutPage: {
      submit: 'Invia',
      refundChannel: 'Canale di Rimborso',
      paymentChannel: 'Canale di Rimborso',
      summary: 'Riepilogo',
      refund: 'Rimborso',
      profitSplit: 'Divisione dei Profitti',
      sessionNumber: 'Numero di Sessione',
      currency: 'Valuta',
      profitsplit: 'Ripartizione del profitto del {percent}',
      channelCharges: '{channel} Commissioni',
      registrationFeeRefund: 'Rimborso della Tassa di Registrazione',
      remark: 'Osservazione',
      totalEligiblePayoutAmount: 'Totale Ammissibile<br />Importo del Pagamento :',
      totalEligiblePayoutAmountInfo:
        "L'importo visualizzato sopra è la tariffa per la sfida. Se applicabile, questo importo verrà riaccreditato sulla carta di credito originale utilizzata per il pagamento.",
      profitSplitFee: 'Commissioni {channel}',
      refundAmountFee: 'Commissioni per il rimborso',
      payoutSuccessDialog: 'La tua richiesta di pagamento è in fase di elaborazione.',
      howToProcessYourProfitSplit: 'Come Elaborare la Tua Ripartizione dei Profitti',
      howToProcessYourProfitSplitDesc:
        'I seguenti passaggi devono essere completati per elaborare il pagamento dal tuo dividendo dei profitti :',
      howToProcessYourProfitSplitDesc2:
        '1. Assicurati che il patrimonio del tuo account sia in profitto.',
      howToProcessYourProfitSplitDesc3: '2. Chiudi tutte le posizioni aperte.',
      howToProcessYourProfitSplitDesc4:
        'Si prega di notare che il tuo account sarà impostato come sola lettura una volta che fai clic su invia. Puoi continuare a fare trading dopo che il tuo pagamento è stato elaborato e ti è stato pagato.',
      howToProcessYourProfitSplitDesc5:
        'Se non vuoi prelevare i tuoi profitti in questo momento, fai clic su annulla.',
      poaDialog: {
        title: 'È richiesta la prova di residenza',
        message:
          'La tua prova di residenza deve essere verificata prima che possiamo elaborare il tuo primo pagamento. Si prega di inviarla almeno 3 giorni prima della data di divisione degli utili di questo ciclo.',
        verifyNow: 'Verifica Ora',
      },
      payoutRequestIsUnsuccessful: 'La richiesta di pagamento non è stata eseguita con successo',
      payoutRequestIsUnsuccessfulDesc:
        'La richiesta di pagamento non è stata effettuata con successo. Assicurati di seguire i seguenti passaggi prima di richiedere un pagamento :',
      payoutRequestIsUnsuccessfulDesc2: '1. Il Conto Live ha un patrimonio netto positivo',
      payoutRequestIsUnsuccessfulDesc3: '2. Chiudi tutte le posizioni aperte',
    },
    notification: {
      profitSplit: {
        title: 'Prossima Data di Ripartizione del Profitto',
        context:
          'Il prossimo dividendo dei profitti sarà il {date}. Ricorda di chiudere tutte le tue operazioni prima di tale data.',
      },
    },
  },
  promotion: {
    noDepositBonus: {
      tnc: 'Terms and Conditions',
      faceVerificationReview: 'Face Verification in Review',
      faceVerificationReviewDesc:
        'This process may take approximately 3 minutes to 24 hours to complete.',
      bonusHistory: 'Bonus History',
      tradeNow: 'Trade Now',
      filetip: 'Please upload file',
      IDVerification: 'ID Verification',
      POAVerification: 'Address Verification',
      faceVerification: 'Face Verification',
      claimDate: 'Claim Date',
      tradingAccount: 'Trading Account',
      credit: 'Credit Amount',
      verifyNow: 'Verify Now',
      poftit1: 'ID must clearly show your full name and date of birth',
      uploadTemplate: {
        good: 'Good',
        blurry: 'Blurry',
        edgeMissing: 'Edge Missing',
        reflective: 'Reflective',
      },
      errorMessageFor1308:
        'The maximum withdrawal limit is ${amount}. Any additional balance exceeding this limit will be removed upon withdrawal. Please make a deposit to retain your balance. ',
      errorMessageFor1307:
        'Il tuo limite di prelievo di ${amount} è stato raggiunto. Effettua un deposito per rimuovere la restrizione di prelievo.',
      errorMessageFor1306lots:
        'Withdrawal is currently unavailable. Please complete at least {amount} lot(s) to proceed with withdrawal.',
      errorMessageFor1306trades:
        'Withdrawal is currently unavailable. Please complete at least {amount} trade(s) to proceed with withdrawal.',
      pofDesc:
        'Please ensure that your face is fully visible, and that you are holding the same ID used for your ID verification in the face verification photo. ',
    },
    xmasSpinAndWin: {
      title: 'PU Prime Luci Tremolanti di Natale',
      desc: "Immergiti nell'emozione festiva con noi questo Natale. Gira la ruota e lascia che le luci tremolanti ti guidino verso premi entusiasmanti, rendendo ancora più magici i tuoi festeggiamenti natalizi.",
      buttonDesc: 'Clicca il pulsante qui sotto per partecipare alle attività',
      button: { join: 'Partecipa Ora' },
      claimTitle: 'Come Funziona?',
      eligibilityTitle: 'Idoneità',
      claimDescriptions: [
        "Completa le attività giornaliere e settimanali sulle app PU Prime per accumulare punti che potrai spendere per far girare la nostra ruota dell'avvento! Entra nella classifica per vincere premi in denaro fino a 3.000 dollari USA.",
      ],
      eligibleDescriptions: [
        'Gli utenti devono scaricare le app PU Prime per partecipare a questa promozione.',
        'Bonus di credito, buoni sconto per le perdite di trading e premi in denaro saranno DISPONIBILI SOLO per il conto standard e il conto standard islamico.',
        'I depositi e le negoziazioni devono essere effettuati durante il periodo promozionale per essere considerati validi.',
      ],
      tnc: '*Si applicano termini e condizioni. {link}',
      link: 'Clicca qui per visualizzare ',
      dialog: {
        back: 'Indietro',
        tnc: 'Termini e Condizioni',
        tncContext: {
          tnc1: '1. I clienti devono scaricare e accedere alle app PU Prime per partecipare a questa promozione. Completa tutte le attività giornaliere e settimanali per ricevere le ricompense corrispondenti.',
          tnc2: '2.\tPeriodo di promozione: 1 dicembre 2023 - 31 dicembre 2023. Periodo di riscatto del voucher: 1 dicembre 2023 - 31 gennaio 2024.',
          tnc3: '3.\tVolume di trading efficace: Lotti standard di coppie di valute, oro, argento, petrolio greggio spot, future sul petrolio greggio e BTCUSD. Gli ordini devono essere aperti e chiusi durante il periodo promozionale. Le posizioni aperte per meno di 5 minuti, le posizioni bloccate e le posizioni hedge non vengono considerate come operazioni valide per questa promozione.',
          tnc4: '4.\tQuesta offerta non può essere utilizzata insieme a qualsiasi altra offerta dello stesso tipo.',
        },
      },
    },
    nfp: {
      title: 'Promozione PU Prime Non-Farm Payroll',
      desc: "I Nonfarm Payrolls (NFP) sono un indicatore economico essenziale legato all'occupazione negli Stati Uniti. In base alle variazioni dell'occupazione, i dati NFP possono agire come stimolo per il trading sul mercato dei cambi. L'analisi tecnica può essere impiegata nel rapporto NFP utilizzando intervalli di grafico di 5 o 15 minuti. I rapporti NFP vengono generalmente pubblicati il primo venerdì di ogni mese.",
      buttonDesc: "Clicca sul pulsante sottostante per partecipare all'attività",
      buttonTitle: 'Partecipa ora',
      claimTitle: 'Come funziona?',
      claimDescriptions: [
        'La promozione PU Prime APP NFP consente agli utenti di prevedere la cifra del mese successivo e verranno premiati con buoni spesa per un totale fino a $500 per i primi 10 vincitori con la previsione più vicina.',
      ],
      eligibilityTitle: 'Idoneità',
      eligibleDescriptions: [
        'Gli utenti devono scaricare l‘app PU Prime per partecipare a questa promozione.',
        'I premi in denaro saranno disponibili solo per il conto standard e il conto standard islamico.',
        'I requisiti di trading per il riscatto dei premi in denaro devono essere soddisfatti durante il periodo promozionale per essere considerati validi.',
      ],
      tnc: '*Si applicano termini e condizioni. {link}',
      link: 'Clicca qui per visualizzare',
    },
    tradingContest: {
      stepDescTitle: '3 Passaggi per Vincere Attraenti Premi in Contanti',
      prizes: 'Premi',
      more: 'Altro',
      step: 'PASSO {number}',
      step1Desc: 'Partecipa alla promozione utilizzando il tuo account idoneo.',
      step2Desc: 'Deposita minimo di {amount}USD$',
      step3Desc:
        'Inizia a fare trading per assicurarti un posto in classifica in base alla tua {mechanics}.',
      deposit: 'Deposita ora',
      trade: 'Fai trading ora',
      optin: 'Opt-in ora',
      statusTitle: 'Stato:',
      status: {
        noteligible: {
          title: 'Deposito in Sospeso',
          tooltip:
            'Deposita almeno <span class="font_bold">USD${amount}</span> per partecipare al contest di trading.',
        },
        pending: {
          title: "Elaborazione dell'Account",
          tooltip:
            'La richiesta del conto è attualmente in fase di elaborazione e sarà pronta per il finanziamento a breve.',
        },
      },
      contestAcc: 'Account del Contest:',
      rank: 'Posizione',
      profit: 'Profitto',
      leaderboard: 'Classifica',
      leaderboardDesc1: 'La classifica viene aggiornata ogni ora.',
      leaderboardDesc2: 'Ultimo aggiornamento il {dateTime}.',
      column: {
        rank: 'Posizione',
        name: 'Nome',
        accountNo: "Numero dell'account",
        country: 'Paese',
        profit: 'Profitto ({usedSymbol})',
        usd: 'USD',
      },
      cancel: 'Annulla',
      submit: 'INVIA',
      back: 'INDIETRO',
      notice: 'Avviso',
      confirm: 'Conferma',
      withdrawDesc1:
        'Effettuare prelievi o trasferimenti interni di fondi da questo conto di trading comporterà la squalifica dal {tradingContest}.',
      withdrawDesc2: 'Questa azione è irreversibile.',
      withdrawDesc3: 'Desideri procedere?',
      confirmation: 'Conferma',
      oneStepAway: 'Solo un altro passo',
      oneStepAwayDesc: 'Hai creato con successo un account partecipante.',
      oneStepAwayDesc2:
        'Per essere pienamente idoneo al contest di trading, dovrai finanziare il tuo account partecipante con almeno <span class="highlight">${amount}</span>.',
      tnc: 'Termini e Condizioni',
      accountConfiguration: 'Configurazione conto',
      accountOpening: 'Apertura Conto',
      agreeText: 'Ho letto e accettato il {link}',
    },
    appPromotionInfo: {
      buttonDesc: "Clicca sul pulsante qui sotto per partecipare all'attività",
      buttonTitle: 'Partecipa ora',
      tnc: '*Si applicano termini e condizioni. {link}',
      link: 'Clicca qui per visualizzare',
      claimTitle: 'Come funziona?',
      eligibilityTitle: 'Ammissibilità',
    },
    euro2024: {
      title: 'PU Prime Euro 2024',
      desc: "Euro 2024 in Germania è un evento calcistico mondiale e si svolge anche in PU Prime.\nUn totale di 5 giochi spettacolari a cui gli utenti possono partecipare e un montepremi sorprendente del valore di 2.480 dollari da vincere. \nQuesta campagna è disponibile solo nella nostra App PU Prime. L'app PU Prime è disponibile su App Store e Google Play.",
      eligibleDescriptions: {
        0: 'Periodo di promozione dal 7 giugno al 28 luglio 2024',
        1: "L'utente deve scaricare l'App PU Prime; gli utenti nuovi ed esistenti sono invitati a partecipare.",
        2: 'I bonus di credito, i buoni di perdita commerciale e i premi in denaro saranno disponibili solo per il conto Standard e il conto Standard islamico. ',
        3: 'Se gli utenti hanno più account Standard, possono selezionare un solo account Standard per partecipare a questa promozione.',
        4: "Aree di promozione - Tutti i Paesi, ad eccezione delle giurisdizioni vietate a discrezione dell'azienda.",
        5: 'Per essere considerati validi, i depositi e gli scambi devono essere effettuati durante il periodo della promozione.',
      },
      tableTitle: 'Offerta esclusiva per la stagione sportiva',
      tableDesc:
        'Gli utenti nuovi ed esistenti possono ricevere ulteriori premi effettuando depositi durante il periodo del bonus. Il pagamento dei premi avverrà entro il 10 del mese successivo.',
      tableDate: 'Dal 1° luglio al 31 agosto 2024',
    },
    clearCreditDialog: {
      desc: '1) Assicurati di non avere posizioni aperte.<br>2) Non sarà possibile ripristinare i crediti bonus dopo che avrai cliccato su conferma.',
      title: 'Questa azione rimuoverà tutti i crediti guadagnati da questa campagna.',
    },
    fgp: { faq: { payout: '', fundsLot: '', total: '' } },
  },
  contact: { chatNow: 'Chattiamo ora', globalOffice: 'Ufficio Globale' },
  responseMsg: {
    434: 'La password attuale non è corretta',
    624: 'Questo account non è idoneo per il reset del saldo negativo. Si prega di contattare il nostro team di Customer Care per ulteriori informazioni.',
    625: 'La tua domanda è in corso. Si prega di contattare il nostro team di Customer Care per ulteriori informazioni.',
    626: 'Il tuo saldo è ora pari o superiore a zero, per favore aggiorna la pagina.',
    651: 'Tieni presente che il trasferimento interno non è disponibile durante i fine settimana per i conti che hanno posizioni aperte. Si prega di inviare la richiesta durante i giorni feriali.',
    1103: "L'account è stato disabilitato",
    1501: 'Parametro non valido',
    1502: 'Codice promozionale non valido',
    1503: 'Non sei idoneo a utilizzare questo codice promozionale',
    1504: 'Il codice promozionale è stato utilizzato',
    1505: 'Il codice promozionale ha superato il limite massimo.',
    1508: 'Questo codice promozionale è disponibile solo per i nuovi utenti',
    1509: 'Questo codice promozionale non è disponibile per il tuo paese',
    1510: 'Questo codice promozionale non è ancora attivo',
    1511: 'Il pagamento è limitato. Contattare il team di assistenza.',
  },
  zh: 'AirTm',
  deposit: {
    default: {
      channels: {
        euroinstantbanktransfer: 'Trasferimento bancario istantaneo in euro',
        taiwanbanktransfer: 'Bonifico bancario taiwanese',
      },
    },
    reminder: {
      inst23:
        'Você será redirecionado para outro formulário em {name} para inserir os detalhes do seu cartão.  ',
      inst24: 'Dopo aver inserito i dettagli della tua carta in {name}, premi "Invia".',
      inst1: 'Complete o formulário abaixo e pressione ‘Enviar’. ',
      inst6:
        'Você será redirecionado de volta ao portal do cliente e sua transação será finalizada.',
      inst25: 'Compila il modulo sottostante e premi ‘Invia’',
      inst26:
        'Seleziona la banca nella pagina di selezione delle banche e accedi alla tua banca online',
      inst27: 'Segui le istruzioni e effettua il pagamento tramite la tua banca online/mobile',
      inst28: 'Verrai reindirizzato al portale clienti e la tua transazione sarà finalizzata',
      inst29: "Segui le istruzioni nell'account per completare il pagamento",
      inst30: "Compila il modulo qui sotto e premi 'Continua'",
      currencyConversion:
        'Se la valuta del tuo bonifico non corrisponde a quella del tuo conto di trading, convertiremo i fondi nella valuta del conto per il deposito.',
    },
    qrcode: {
      step1: 'FASE 1',
      step2: 'FASE 2',
      method1: 'Metodo 1',
      method2: 'Metodo 2',
      copyPayment: "Copia l'indirizzo del portafoglio {payment}\n",
      haveMadeThePayment: 'Ho effettuato il pagamento',
      copyAddress: "Copia l'indirizzo",
      copyAmount: 'Copia importo',
      copied: 'Copiato',
      note: 'Nota',
      scanThisWithYourPhon: 'Scansiona questo con il tuo telefono',
      enterTheAddressMaually: "Oppure inserisci l'indirizzo manualmente",
      logInYourWallwet:
        "Accedi al tuo portafoglio {payment} personale e trasferisci l'importo desiderato all'indirizzo {payment}.",
      sumbitNote: 'Se hai completato il pagamento, clicca su "Ho effettuato il pagamento"',
      continueToWalletAddress: "Continua all'indirizzo del portafoglio",
      usdtNote1:
        "1. Si prega di assicurarsi che l'importo del pagamento in {payment} superi {number} {currency}. Gli importi inferiori a questa soglia non saranno accreditati.",
      usdtNote2:
        "2. I trasferimenti {payment} sono categorizzati in Transazioni e Transazioni Interne. Utilizzare le Transazioni Interne per i trasferimenti può comportare la perdita di transazioni. Si prega di astenersi dall'utilizzare questo metodo.",
      depositSubMissionFailed: 'Invio del deposito fallito. Si prega di riprovare più tardi.',
      depositAmount: 'Importo del deposito',
    },
  },
  home: {
    explanation:
      'I crediti possono essere utilizzati per aumentare il saldo di trading. I crediti non sono disponibili per il prelievo in quanto vengono tenuti separati dal saldo del tuo conto.',
    pro1000: {
      desc1: 'Le seguenti restrizioni si applicano a un account con leva di 1:1000:',
      conditions: [
        'I limiti di leva possono variare in base alle normative applicabili.',
        "a leva potrebbe essere automaticamente ridotta a 1:500 dal sistema se il patrimonio netto dell'account selezionato supera i 20.000 USD o equivalente. Se ci sono operazioni aperte, verrà inviata una notifica via email prima dell'adeguamento della leva.",
        "Si prega di notare che la leva 1:1000 è disponibile solo per strumenti finanziari come le principali coppie Forex e l'Oro, mentre la leva per altri strumenti è mantenuta in conformità con i requisiti di margine fisso degli strumenti e non è influenzata dalla leva 1:1000.",
      ],
      desc2:
        "Inviando questa richiesta, riconosco di essere consapevole che un'alta leva comporta un grande rischio per il mio capitale e c'è la possibilità che potrei subire una perdita superiore e non limitata al capitale che ho depositato.",
      desc3:
        "Accetto che l'emittente dei prodotti abbia il diritto di esaminare le mie attività di trading e di regolare i livelli di leva sul mio account di trading a sua discrezione e senza alcun preavviso o notifica.",
      desc4:
        "Capisco che scegliendo un'alta leva, posso fare trading con un margine più grande ma potenzialmente subire perdite significative.",
    },
    pro2000: {
      desc1: 'Le seguenti restrizioni si applicano a un conto con leva di 1:2000:',
      conditions: [
        'Il conto selezionato non è idoneo a partecipare a promozioni legate al credito.',
        "La leva potrebbe essere automaticamente ridotta a 1:500 dal sistema se il patrimonio netto del conto selezionato supera 5.000 USD o equivalente. Se ci sono operazioni aperte, verrà inviata una notifica via email prima dell'adeguamento della leva.",
        'I limiti di leva possono variare in base alle normative applicabili.',
        'Si prega di notare che la leva 1:2000 non è disponibile per strumenti finanziari come Cryptocurrencies, Shares, Silver e Commodities. Il margine per questi strumenti è mantenuto in conformità con i requisiti di margine fisso degli strumenti e non è influenzato dalla leva 1:2000.',
      ],
      desc2:
        "Inviando questa richiesta, riconosco di essere consapevole che un'alta leva comporta un grande rischio per il mio capitale e c'è la possibilità che potrei subire una perdita superiore e non limitata al capitale che ho depositato.",
      desc3:
        "Accetto che l'emittente dei prodotti abbia il diritto di rivedere le mie attività di trading e di regolare i livelli di leva sul mio conto di trading a sua discrezione e senza alcun preavviso o notifica.",
      desc4:
        "Capisco che scegliendo un'alta leva, posso fare trading con un margine maggiore ma potenzialmente incorrere in perdite significative.",
    },
    resetBalance: {
      descWait1:
        'Il credito del tuo account {credit} verrà detratto dopo il reset del saldo negativo.',
      descWait2: "Sei d'accordo a procedere?",
      resetBtn: 'Reset',
      title1: 'Aspetta un momento',
      title2: 'Richiesta non riuscita',
      resetSuccess: 'Ripristino riuscito',
    },
    copyTrading: {
      actionTooltip:
        "Questa azione non è applicabile all'account Copy Trading. Accedi all'app PU Prime per accedere al conto di Copy Trading.",
    },
  },
  withdraw: {
    default: {
      onlyWithdrawalCcDesc:
        "Solo l'importo della Carta di Credito / del Bonifico Bancario Locale sarà completamente prelevato.",
    },
    transfer: { minAmount: "L'importo minimo di prelievo è 100 {currency}" },
    LBTchannelsNote:
      'Nota:\n  Il nome del conto bancario/il nome del beneficiario deve corrispondere al\n  nome sul tuo conto bancario.',
  },
  downloads: {
    tabTitle: 'PU Prime APP',
    downloadTextTitle1: 'Scarica la nostra app di trading',
    text1: 'Strumenti di trading semplici ma potenti',
    text2: 'Velocità di esecuzione super veloce',
    text3: 'Accesso a oltre 800 strumenti.',
    text4: 'Canali di finanziamento multipli',
    text5: 'Rapporto di trading visuale e analitico',
    text6: 'Gestione multi-account',
    text7: 'Notizie di mercato globale in movimento',
    text8: 'Accesso al supporto clienti professionale 24/7',
    downloadTextTitle2: 'Scarica ora',
  },
  openAdditAcc: {
    chooseLeverage: 'Scegli la Leverage',
    chooseBalance: 'Scegli il saldo del conto',
    byTickingCopyTrading:
      'Spuntando questa casella, accetto i Termini e Condizioni del Copy Trading. {tnc}',
    importantNote: 'Nota importante: ',
    islamicTnc:
      'Si prega di notare che gli Islamic Trading Accounts traggono vantaggio dal mantenere posizioni per un periodo di 14 giorni durante il quale non vengono applicate commissioni amministrative. Dopo questo periodo, le commissioni amministrative standard verranno applicate per le posizioni mantenute per più di 14 giorni.',
    nonIslamicTnc:
      'Gli account Islamici permettono ai trader musulmani di seguire la legge Sharia offrendo trading senza swap e senza commissioni di interesse overnight. Invece, verrà addebitata una tariffa amministrativa su ogni posizione dal conto. Per maggiori informazioni, si prega di cliccare {link}.',
    here: 'qui',
    selectMt4Tips: {
      title: 'Siete sicuri di voler selezionare MetaTrade 4?',
      tipText:
        'MetaTrade 4 offre un minor numero di funzioni integrate e una limitata varietà di prodotti.',
      switchBtn: 'Passare a MetaTrade 5',
      continueBtn: 'Continua con MetaTrade 4',
    },
  },
  openDemoAdditAcc: { title: 'Crea un account demo' },
  register: {
    accOpeningVerification: {
      createLiveAccount: {
        mamNumber: 'Numero MAM',
        mamNumberPlaceholder: 'Inserisci il numero MAM (opzionale)',
        islamicTooltip:
          '{platform} potrebbe richiedere di fornire documenti di supporto per verificare che tu sia di fede islamica. {platform} si riserva il diritto di modificare la configurazione del tuo account se la verifica non ha successo.',
      },
    },
  },
  verificationStatus: {
    accOpeningVerification: {
      subTitle: 'Maggiori informazioni sui nostri tipi di account {link}.',
      here: 'qui',
    },
  },
  copyTradingBanner: {
    header: 'Sincronizza e fai trading con PU Prime',
    desc: 'Lascia che gli esperti facciano il lavoro mentre tu impari.',
    download: 'Scarica Ora',
  },
  transcationHistory: {
    withdrawDesc: {
      partialSucc:
        'La tua transazione è stata parzialmente rimborsata. Si prega di contattare {mailTo} per ulteriori informazioni.',
    },
  },
};
