import router from '@/router'
import { startLoading, endLoading } from '@/util/loading'
import helper from '@/util/signinHelper'
import store from '@/store'
import { getCookies } from '@/util/cookies'
import redirect from '@/util/redirect'
import { sensorsBindLogin } from '@/util/sensorsdata'
import { parseSecureURL } from '@/util/secureURLUtils'

export default {
  authorize() {
    router.beforeEach(async (to, from, next) => {
      const registerToken = to.query.a
      const accessToken = to.query.accessToken
      const cpPath = to.query.path
      const lang = to.query.lang

      if (lang) store.commit('common/setLang', lang)

      // ib 登出回到 cp 的 logout page
      if (to.path == '/logout') helper.signOut(null, 'manuallyTypeUrl')
      else if (accessToken) {
        try {
          const decryptPayload = await parseSecureURL(accessToken)
          helper.refreshToken(decryptPayload.accessToken, decryptPayload.pk, cpPath)
        } catch (error) {
          console.error('Failed to decrypt accessToken:', error.message)
          helper.signOut()
        }
      } else if (registerToken) {
        // reset session
        store.commit('common/setSessionId', { sessionId: null, sessionInternalId: null })
        helper.register(registerToken)
      } else if (store.state.common.loginStatus) {
        //  if login but doesn't have token, logout
        if (!getCookies('token')) helper.signOut(null, 'missingToken')
        sensorsBindLogin(store.state.common.userId, store.state.common.eventEmailId, store.state.common.eventMobileId)
        if (to.name == '/' || to.name == 'login' || to.name == 'to_login') next('/home')
        if (to.meta.authority && store.state.common.registerStep < 5) next('/authority')
        else {
          if (!to.meta.noShowLoading) startLoading('router')
          next()
        }
      } else {
        to.meta.allowAnonymous ? next() : redirect.redirectToLogoutUrl()
      }
    })
    router.afterEach((to, from) => {
      endLoading()
    })
  },
}
