<template>
  <ul class="link-box">
    <!-- <li v-if="restrictedChina" @click="mobileClick" data-testid="mobile">
      <div class="top title">{{ $t('deposit.default.channels.mobilePay') }}</div>
      <div class="info">
        <div class="logo rectangle mobile"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="fasapayClick" data-testid="fasapay">
      <div class="top title">{{ $t('deposit.default.channels.fasa') }}</div>
      <div class="info">
        <div class="logo rectangle fasapay"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->

    <!-- <li @click="sticPayClick" data-testid="sticpay" v-if="notRestricted(17)">
      <div class="top title">{{ $t('deposit.default.channels.sticPay') }}</div>
      <div class="info">
        <div class="logo rectangle sticPay"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li>
    -->

    <!-- <li @click="skrillClick" data-testid="skrill" v-if="notRestricted(21)">
      <div class="top title">{{ $t('deposit.default.channels.skrill') }}</div>
      <div class="info">
        <div class="logo rectangle skrill"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->

    <!-- <li @click="bitwalletClick" data-testid="bitwallet">
      <div class="top title">{{ $t('deposit.default.channels.bitwallet') }}</div>
      <div class="info">
        <div class="logo circle bitwallet"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="astropayClick" data-testid="astropay">
      <div class="top title">{{ $t('deposit.default.channels.astropay') }}</div>
      <div class="info">
        <div class="logo rectangle astropay"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="netellerClick" data-testid="neteller" v-if="notRestricted(11)">
      <div class="top title">{{ $t('deposit.net.header') }}</div>
      <div class="info">
        <div class="logo rectangle neteller"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->

    <!-- CPS Deposit List -->
    <template v-for="(payment, index) in cpsDepositList">
      <DepositListing
        :payment="payment"
        :key="'cps-' + index"
        v-if="permittedCpsEWalletPaymentMethods.includes(payment.payment_method)"
      ></DepositListing>
    </template>
  </ul>
</template>

<script>
import mixin from '@/mixins/page/deposit/deposit';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import DepositListing from '@/components/cps/DepositListingCps';

export default {
  mixins: [mixin, cpsMixin],
  components: { DepositListing },
  props: ['cpsDepositList']
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
</style>
