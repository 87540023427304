<template>
  <el-form :model="form" ref="ruleForm" label-position="left" label-width="25%">
    <section>
      <i18n class="left" path="register.confirmYourId.description" tag="p">
        <template v-slot:mailTo>
          <a :href="'mailto:' + GLOBAL_ACCOUNT_EMAIL">
            <u>{{ GLOBAL_ACCOUNT_EMAIL }}</u>
          </a>
        </template>
      </i18n>
    </section>
    <ul>
      <li class="confirmId">
        <div class="title">
          {{ $t('register.confirmYourId.poidTitle') }}
        </div>
        <div class="confirmId-main">
          <div class="left">
            <div class="tip-text" :class="{ 'tip-left': secondNationalId }">
              {{ $t('register.confirmYourId.uploadDescription') }}
            </div>
            <img class="img" src="@/assets/images/register/id.png" alt="" />
            <ul>
              <li>{{ $t('register.confirmYourId.poidTypes.photoId') }}</li>
              <li>{{ $t('register.confirmYourId.poidTypes.passport') }}</li>
              <li>{{ $t('register.confirmYourId.poidTypes.drivingLicence') }}</li>
            </ul>
            <span>{{ $t('register.confirmYourId.note') }}</span>
          </div>
          <div class="right">
            <div v-if="secondNationalId" class="tip-text red">{{ $t('register.confirmYourId.uploadTip') }}</div>
            <vUpload
              :limit="uploadLimit"
              v-on:updateFileInfo="updateIDInfo"
              :selfTip="false"
              :selfText="true"
              :class="{ finish: checkIdDocFile }"
            >
              <div class="message" v-if="checkIdDocFile">
                <div class="done"><img src="@/assets/images/dialog/dialog_true.png" alt="" /></div>
              </div>
              <div class="message" v-else>
                <div class="top"><img src="@/assets/images/register/upload.png" alt="" /></div>
                <div class="bottom">{{ $t('register.btn.upload') }}</div>
              </div>
            </vUpload>
            <p v-if="checkIdDocFile" class="i-success">{{ $t('register.confirmYourId.pending.verification') }}</p>
            <p class="i-error" v-else>{{ $t('register.confirmYourId.pending.upload') }}</p>
          </div>
        </div>
        <idPoaForm ref="idPoaForm" v-if="checkIdDocFile" :userData="foamData"></idPoaForm>
      </li>
      <li class="confirmId">
        <div class="title">
          {{ $t('register.confirmYourId.porTitle') }}
        </div>
        <div class="tip">{{ $t('register.confirmYourId.uploadDescription') }}</div>
        <div class="confirmId-main">
          <div class="left">
            <img class="img" src="@/assets/images/register/poa.png" alt="" />
            <ul v-html="$t('register.confirmYourId.porTypes')"></ul>
            <span>{{ $t('register.confirmYourId.note') }}</span>
          </div>
          <div class="right">
            <vUpload
              :limit="6"
              v-on:updateFileInfo="updatePOAInfo"
              :selfTip="false"
              :selfText="true"
              :class="{ finish: checkPocDocFile }"
            >
              <div class="message" v-if="checkPocDocFile">
                <div class="done"><img src="@/assets/images/dialog/dialog_true.png" alt="" /></div>
              </div>
              <div class="message" v-else>
                <div class="top"><img src="@/assets/images/register/upload.png" alt="" /></div>
                <div class="bottom">{{ $t('register.btn.upload') }}</div>
              </div>
            </vUpload>

            <p v-if="checkPocDocFile" class="i-success">{{ $t('register.confirmYourId.pending.verification') }}</p>
            <p class="i-error" v-else>{{ $t('register.confirmYourId.pending.upload') }}</p>
          </div>
        </div>
      </li>
    </ul>
  </el-form>
</template>

<script>
import vUpload from '@/components/vUpload';
import idPoaForm from '@/components/home/UploadIdAddress/Form';
import confirmID from '@/mixins/register/confirmID';

export default {
  components: { vUpload, idPoaForm },
  mixins: [confirmID]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/confirmID.scss';
</style>
