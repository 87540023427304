import { apiEligibleCampaigns } from '@/resource'
import { FE_ONLY_CAMPAIGN, addFEOnlyCampaign } from '@/constants/promotion'
import xmasMakeAWishStore from './xmasMakeAWish'
import firstTimeDepositStore from './firstTimeDeposit'
import firstDepositCashback from './firstDepositCashback'
import tradingContest from './tradingContest'
import moment from 'moment'

const state = () => ({
  eligibleCampaigns: [],
  serverTime: '',
})

const mutations = {
  setEligibleCampaigns(state, value) {
    state.eligibleCampaigns = value
  },
  setServerTime(state, value) {
    state.serverTime = value
  },
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      state[key] = []
    })
  },
}

const actions = {
  actionSetServerTime({ commit }, data) {
    commit('setServerTime', data)
  },
  async actionGetEligibleCampaigns({ commit, rootState }) {
    await apiEligibleCampaigns().then(result => {
      const { data, code } = result.data
      const campaigns = []

      if (code === 0 && data) {
        const serverTime = moment(rootState.serverTime)

        // check for campaign expiry date
        Object.keys(FE_ONLY_CAMPAIGN).forEach(campaign => {
          FE_ONLY_CAMPAIGN[campaign].expired > serverTime || !FE_ONLY_CAMPAIGN[campaign].expired
            ? campaigns.push(FE_ONLY_CAMPAIGN[campaign])
            : ''
        })

        const finalCampaigns = addFEOnlyCampaign(data, rootState.common.countryCode, campaigns)

        commit('setEligibleCampaigns', finalCampaigns)

        // trading contest
        const tradingContest = result.data.data.find(item => item.campaignType == 'TRADING_CONTEST')
        if (tradingContest) {
          commit('tradingContest/setTradingContestId', tradingContest.id)
          this.dispatch('promotion/tradingContest/actionTradingContestParticipantInfo', {
            campaignId: tradingContest.id,
            languageCode: this.state.common.lang,
          })
        }
        ////
      }
    })
  },
}

export default {
  namespaced: true,
  modules: {
    xmasMakeAWish: xmasMakeAWishStore,
    firstTimeDeposit: firstTimeDepositStore,
    firstDepositCashback: firstDepositCashback,
    tradingContest: tradingContest,
  },
  state,
  mutations,
  actions,
}
