// 進行異步執行
import { apiGetDepositData_cp, apiRestrictedDeposit } from '@/resource'

export default {
  async actionPaymentList({ commit, state }) {
    if (state.paymentList.length > 0) return
    return new Promise((resolve, reject) => {
      apiGetDepositData_cp().then(result => {
        commit('setPaymentList', result.data)
      })
    })
  },
  async actionRestrictDeposit({ commit, state }) {
    if (state.restrictedDeposit.length > 0) return state.restrictedDeposit
    return new Promise((resolve, reject) => {
      apiRestrictedDeposit().then(result => {
        let data = result.data.length <= 0 ? 0 : result.data
        commit('setRestrictedDeposit', data)
        resolve(data)
      })
    })
  },
  actionSetIsShowFirstPromotionPopup({ commit }, payload) {
    commit('SET_ISSHOWFIRSTPROMOTIONPOPUP', payload)
  },
  actionSetCcValidateDialogVisible({ commit }, payload) {
    commit('SET_CCVALIDATEDIALOGVISIBLE', payload)
  },
}
