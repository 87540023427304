<template>
  <el-form :model="form" :rules="rules" ref="ruleForm" label-position="left" label-width="25%">
    <div class="box box-platform">
      <el-form-item prop="tradingPlatform">
        <div class="box-inner">
          <p class="title">{{ $t('register.accountConfig.chooseTradingPlatform') }}</p>
          <ul>
            <li
              v-for="(item, index) in $config.openLiveAccount.choosePlatform(regulator)"
              :key="index"
              @click.prevent.stop="disableField ? '' : (form.tradingPlatform = item.value)"
              :class="{ active: form.tradingPlatform == item.value }"
              :data-testid="item.value"
            >
              <div class="img" :class="item.value"></div>
              <span>Meta Trader {{ item.label }}</span>
            </li>
          </ul>
        </div>
      </el-form-item>
    </div>

    <div class="box box-type">
      <el-form-item prop="accountType">
        <div class="box-inner">
          <p class="title">{{ $t('register.accountConfig.chooseAccType') }}</p>
          <ul>
            <li
              v-for="item in accountTypeList"
              :key="item.value"
              @click.prevent.stop="disableField ? '' : (form.accountType = item.value)"
              :class="{ active: form.accountType == item.value }"
              :data-testid="item.value"
            >
              <div class="img" :class="item.value"></div>
              <span>{{ item.label }}</span>
            </li>
          </ul>
        </div>
      </el-form-item>
    </div>

    <div class="box box-currency">
      <div class="box-inner">
        <p class="title">{{ $t('register.accountConfig.chooseAccCurrency') }}</p>
        <el-form-item prop="currency">
          <ul>
            <li
              v-for="item in $config.openLiveAccount.chooseCurrency(regulator, form.accountType)"
              :key="item.currency"
              @click.prevent.stop="disableField ? '' : (form.currency = item.currency)"
              :class="{ active: form.currency == item.currency }"
              :data-testid="item.currency"
            >
              <div class="shadow-inner">
                <div class="left"></div>
                <div class="right">
                  <img :src="item.img" alt />
                  <p>{{ item.value }}</p>
                </div>
              </div>
            </li>
          </ul>
        </el-form-item>
        <div class="mt-3" v-if="minDeposit">
          <span>{{ $t('openAdditAcc.minDeposit') }}</span>
          <span class="text-red">{{ minDeposit }} {{ activeCurrency }}</span>
        </div>
      </div>
    </div>

    <div class="last-box">
      <div class="checkBox">
        <el-checkbox id="checkbox" name="type" v-model="checkStatus" :disabled="disableField" data-testid="checkbox">
        </el-checkbox>
        <i18n path="openAdditAcc.byTicking" tag="div" class="tnc">
          <template v-slot:tnc>
            <p class="red" @click="showTNC = !showTNC" data-testid="showTNC">{{ $t('openAdditAcc.tnc') }}</p>
          </template>
        </i18n>
        <span class="error" v-if="!checkStatus">{{ $t('common.formValidation.tnc') }}</span>
      </div>
      <ul
        v-show="showTNC"
        v-html="
          $t($config.openLiveAccount.getAgreeCondition(regulator), {
            platform: $config.info.fullName,
            regulatorUrl: GLOBAL_DOMAIN_WEBSITE
          })
        "
      ></ul>
    </div>
  </el-form>
</template>

<script>
import accountConfiguration from '@/mixins/register/accountConfiguration';

export default {
  mixins: [accountConfiguration]
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/accountConfiguration.scss';
</style>
