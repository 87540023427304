import { apiGetData_old } from '@/resource/register'
import { apiGetAutoStartTradingThreshold } from '@/resource'

export default {
  data() {
    return {
      form: {
        tradingPlatform: null,
        accountType: null,
        currency: null,
        step: 4,
      },
      rules: {
        tradingPlatform: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.platErr'),
          },
        ],
        accountType: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.accTypeErr'),
          },
        ],
        currency: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.currError'),
          },
        ],
        checkStatus: [
          {
            required: true,
            trigger: 'blur',
          },
        ],
      },
      checkStatus: null,
      showTNC: false,
      threshold: {},
    }
  },
  watch: {
    'form.tradingPlatform'() {
      if (!this.disableField) {
        this.form.accountType = null
        this.form.currency = null
      }
    },
    'form.accountType'() {
      if (!this.disableField) {
        this.form.currency = null
      }
    },
  },
  mounted() {
    this.getUserDate()
    this.getAutoStartTradingThreshold()
  },
  methods: {
    getUserDate() {
      apiGetData_old(4).then(resp => {
        if (resp.data.code == 0) {
          this.form = { ...this.form, ...resp.data.data }
          if (this.form.tradingPlatform && this.form.accountType && this.form.currency) this.checkStatus = true
        }
      })
    },
    getAutoStartTradingThreshold() {
      apiGetAutoStartTradingThreshold().then(resp => {
        resp.data.forEach(element => {
          if (!this.threshold[element.alias])
            Object.assign(this.threshold, {
              [element.alias]: {
                [element.currency]: {
                  amount: element.threshold,
                },
              },
            })
          else if (!this.threshold[element.alias][element.currency])
            Object.assign(this.threshold[element.alias], {
              [element.currency]: {
                amount: element.threshold,
              },
            })
        })
      })
    },
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode
    },
    disableField() {
      return this.$store.state.register.disableField
    },
    accountTypeList() {
      if (this.form.tradingPlatform == 'mt4' || !this.form.tradingPlatform) {
        return this.$config.openLiveAccount.mt4RegisterChooseType(this.regulator).filter(f => {
          if (f.restrictCountries) return !f.restrictCountries.includes(parseInt(this.countryCode))
          if (f.onlyCountries) return f.onlyCountries.includes(parseInt(this.countryCode))
          return f
        })
      } else {
        return this.$config.openLiveAccount.mt5RegisterChooseType(this.regulator).filter(f => {
          if (f.restrictCountries) return !f.restrictCountries.includes(parseInt(this.countryCode))
          if (f.onlyCountries) return f.onlyCountries.includes(parseInt(this.countryCode))
          return f
        })
      }
    },
    minDeposit() {
      try {
        return this.threshold[this.form.accountType][this.form.currency].amount
      } catch (e) {
        return null
      }
    },
  },
}
