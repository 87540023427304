// global variable
import { PROMOTION_TYPE } from '@/constants/promotion'

const getters = {
  campaignID: (state, getters, rootState) => {
    let campaign =
      rootState.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.FIRST_DEPOSIT_CASHBACK) || {}

    return campaign.id ? campaign.id : null
  },
}

export default {
  namespaced: true,
  getters,
}
