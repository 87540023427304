import {
  apiCountries,
  apiProvinces,
  apiNationalities,
  apiBirthPlace,
  apiPhoneCodes,
  apiEmploymentFinance,
  apiTrading,
  apiIbtGetData,
  apiGetCurrentStep,
} from '@/resource/register'
import {
  apiGetFirstAccountAuditStatus,
  apiGet_last_identifiy_proof,
  apiAttach_new_document,
  apiVerification2IdentityProofs,
} from '@/resource'
import {
  registerFirstStep,
  convertToUserStep,
  getStatus,
  checkRegisterPageCanAudit,
  getRegisterStepByNum,
} from '@/util/register'
import { statusConfig } from '@/constants/status'

let countriesLang, provincesLang, nationalitiesLang, birthPlaceLang, EFQuestionLang, tradingQuestionLang

const state = () => ({
  currStep: registerFirstStep.personalInformation,
  nationalities: [],
  phoneCodes: [],
  countries: [],
  accountAuditStatus: null,
  poiAuditStatus: statusConfig.init,
  poaAuditStatus: statusConfig.init,
  ibtPoiAuditStatus: statusConfig.init,
  ibtPoaAuditStatus: statusConfig.init,
  disableField: false,
  poiPendingInfo: {},
  poaPendingInfo: {},
})

const mutations = {
  SET_NATIONALITIES: (state, payload) => (state.nationalities = payload),
  SET_PHONE_CODES: (state, payload) => (state.phoneCodes = payload),
  SET_COUNTRIES: (state, payload) => (state.countries = payload),
  SET_CURRENCY_STEP: (state, payload) => (state.currStep = payload),
  SET_ACCOUNT_AUDITSATTUS: (state, payload) => (state.accountAuditStatus = payload),
  SET_POI_AUDIT_STATUS: (state, payload) => (state.poiAuditStatus = payload),
  SET_DISABLE_FIELD: (state, payload) => (state.disableField = payload),
  SET_POI_AUDISTATUS: (state, payload) => (state.poiAuditStatus = payload),
  SET_POA_AUDISTATUS: (state, payload) => (state.poaAuditStatus = payload),
  SET_IBT_POI_AUDISTATUS: (state, payload) => (state.ibtPoiAuditStatus = payload),
  SET_IBT_POA_AUDISTATUS: (state, payload) => (state.ibtPoaAuditStatus = payload),
  reset(state) {
    const initial = state
    Object.keys(initial).forEach(key => {
      if (key == 'disableField') state[key] = false
      else state[key] = []
    })
  },
  SET_POI_PENDING_INFO: (state, payload) => (state.poiPendingInfo = payload),
  SET_POA_PENDING_INFO: (state, payload) => (state.poaPendingInfo = payload),
}

const actions = {
  actionStep({ commit }, step) {
    commit('common/setRegisterStep', convertToUserStep(step), { root: true })
    commit('SET_CURRENCY_STEP', step)
  },
  async actionGetCurrentStep({ dispatch, state }) {
    const response = await apiGetCurrentStep()
    if (response.data.code === 0) {
      const currentStep = response.data.data
      dispatch('actionStep', getRegisterStepByNum(currentStep, state.poaAuditStatus))
    }
  },
  async actionNationalities({ commit, state }, lang) {
    if (nationalitiesLang == lang && state.nationalities.length > 0) return
    nationalitiesLang = lang
    await apiNationalities(lang).then(result => {
      commit('SET_NATIONALITIES', result.data.data)
    })
  },
  async actionPhoneCodes({ commit, state }) {
    if (state.phoneCodes.length > 0) return
    await apiPhoneCodes().then(result => {
      commit('SET_PHONE_CODES', result.data.data)
    })
  },
  async actionCountries({ commit, state }, lang) {
    if (countriesLang == lang && state.countries.length > 0) return
    countriesLang = lang
    await apiCountries(lang).then(result => {
      commit('SET_COUNTRIES', result.data.data)
    })
  },
  async actionAccountAuditStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiGetFirstAccountAuditStatus().then(response => {
        if (response.data.code === 0 && response.data?.data) {
          const accountStatus = getStatus(response.data.data)
          commit('SET_ACCOUNT_AUDITSATTUS', accountStatus)
          resolve(accountStatus)
        } else {
          resolve(null)
        }
      })
    })
  },
  actionChangePoiAuditStatus({ commit }, status) {
    commit('SET_POI_AUDISTATUS', status)
  },
  actionChangePoaAuditStatus({ commit }, status) {
    commit('SET_POA_AUDISTATUS', status)
  },
  async actionPoiAuditStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiGet_last_identifiy_proof(12).then(response => {
        if (response.data?.code === 0 && response.data?.data) {
          const data = response.data?.data
          const poiAuditStatus = getStatus(data.status)
          commit('SET_POI_AUDISTATUS', poiAuditStatus)
          commit('SET_POI_PENDING_INFO', {
            poiRecordId: data.id,
            poiPendingRecord: data?.value,
            poiPendingReason: data?.pending_reason !== 7 ? data.pending_reason_text ?? '' : '',
            poiCustomPendingReason: data?.pending_reason === 7 ? data.custom_pending_reason ?? '' : '',
          })
          resolve(poiAuditStatus)
        }
      })
    })
  },
  async actionPoaAuditStatus({ commit, state }) {
    return new Promise((resolve, reject) => {
      apiGet_last_identifiy_proof(11).then(response => {
        if (response.data?.code === 0 && response.data?.data) {
          const data = response.data?.data
          const poaAuditStatus = getStatus(data.status)
          commit('SET_POA_AUDISTATUS', poaAuditStatus)
          commit('SET_POA_PENDING_INFO', {
            poaRecordId: data.id,
            poaPendingRecord: data?.value,
            poaPendingReason: data?.pending_reason !== 7 ? data.pending_reason_text ?? '' : '',
            poaCustomPendingReason: data?.pending_reason === 7 ? data.custom_pending_reason ?? '' : '',
          })
          resolve(poaAuditStatus)
        }
      })
    })
  },
  async actionUpdateIBTStatus({ commit }) {
    // update both IBT poi, poa status
    const result = await apiIbtGetData()
    if (result && result?.data.code === 0) {
      const { ibtPoaAuditStatus, ibtPoiAuditStatus } = result.data.data
      commit('SET_IBT_POI_AUDISTATUS', getStatus(ibtPoiAuditStatus))
      commit('SET_IBT_POA_AUDISTATUS', getStatus(ibtPoaAuditStatus))
      return result.data.data
    }
  },
  actionChangeIbtPoiAuditStatus({ commit }, status) {
    commit('SET_IBT_POI_AUDISTATUS', status)
  },
  actionChangeIbtPoaAuditStatus({ commit }, status) {
    commit('SET_IBT_POA_AUDISTATUS', status)
  },
  // async actionReSubmit({ commit }, { documentId, files, type }) {
  //   if ((documentId ?? false) && files?.length) {
  //     let documentType = type === 'poa' ? 11 : 12
  //     const resp = await apiAttach_new_document({ documentId, documentType, docPaths: files })
  //     if (resp.data.code == 0) {
  //       return true
  //     }
  //   }
  //   return false
  // },

  async actionPendingThenReSubmit({ commit }, { documentId, files, fileType }) {
    if ((documentId ?? false) && files?.length) {
      const resp = await apiAttach_new_document({ documentId, documentType: fileType, docPaths: files })
      if (resp.data.code == 0) {
        return true
      }
    }
    return false
  },
  async actionRejectThenReSubmit({ commit }, { filePathList, fileType, auditType }) {
    if (filePathList?.length) {
      const resp = await apiVerification2IdentityProofs({ filePathList, fileType, auditType })
      if (resp.data.code == 0) {
        return true
      }
    }
    return false
  },
}

const getters = {
  nationalities: state => {
    return state.nationalities.map(el => {
      return { value: el.id, label: el.nationality }
    })
  },
  phoneCodes: state => {
    return state.phoneCodes.map(el => {
      return { value: el, label: el }
    })
  },
  countries: state => {
    return state.countries.map(el => {
      return { value: el.id, label: el.countryName }
    })
  },
  idPass: (state, getters, rootState) =>
    state.poiAuditStatus === statusConfig.completed ||
    state.ibtPoiAuditStatus === statusConfig.completed ||
    rootState.common.id3Pass,
  isPoiEditable: (state, getters) => !getters.idPass && checkRegisterPageCanAudit(state.poiAuditStatus),
  isPoiUnderReview: (state, getters) =>
    !getters.idPass &&
    (state.poiAuditStatus === statusConfig.submitted || state.poiAuditStatus === statusConfig.reaudit),
  addressPass: state =>
    state.poaAuditStatus === statusConfig.completed || state.ibtPoaAuditStatus === statusConfig.completed,
  isPoaEditable: (state, getters) => !getters.addressPass && checkRegisterPageCanAudit(state.poaAuditStatus),
  isPoaUnderReview: (state, getters) =>
    !getters.addressPass &&
    (state.poaAuditStatus === statusConfig.submitted || state.poaAuditStatus === statusConfig.reaudit),
  poaPass: state =>
    state.ibtPoaAuditStatus === statusConfig.completed || state.poaAuditStatus === statusConfig.completed,
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
