<template>
  <ul class="link-box">
    <!-- CPS Deposit List -->
    <template v-for="(payment, index) in cpsDepositList">
      <DepositListing
        :payment="payment"
        :key="'cps-' + index"
        v-if="permittedCpsCreditCardPaymentMethods.includes(payment.payment_method)"
      ></DepositListing>
    </template>
    <!-- BridgerPay -->
    <li @click="bridgerPayClick" data-testid="creditOrDebit" v-if="notRestricted(1, 8) && restrictedChina">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <!-- appleSolidpay-->
    <li @click="appleSolidpayClick" v-if="showAppleSolidpay && restrictedChina" data-testid="appleSolidpay">
      <div class="top title">{{ $t('common.field.applePay') }}</div>
      <div class="info">
        <div class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7{{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <span>Preferred</span>
            <div></div>
          </div>
        </div>
      </div>
    </li>
    <!-- solidpayment-->
    <!-- <li @click="solidpaymentClick" data-testid="solidPayment" v-if="notRestricted(1, 22)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7{{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <span>Preferred</span>
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- Worldcard -->
    <!-- <li @click="worldcardClick" data-testid="worldCard" v-if="notRestricted(1, 14)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div v-if="OnlyMasterWorldcard.includes(countryCode)" class="logo diamond masterCard"></div>
        <div v-else class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- Sdpay-->
    <!-- <li @click="sdPayClick" data-testid="sdPay" v-if="notRestricted(1, 6)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc ccJCMC"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
    <!-- VirtualPay -->
    <!-- <li @click="virtualPayClick" data-testid="virtualPay" v-if="notRestricted(1, 9)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- maxisPay-->
    <!-- <li @click="maxisPayClick" data-testid="maxisPay" v-if="notRestricted(1, 17)">
      <div class="top title">{{ $t('deposit.default.channels.creditOrDebit') }}</div>
      <div class="info">
        <div class="logo cc cc2way"></div>
        <div class="right">
          <div class="span">
            $0 {{ $t('deposit.default.fee') }} <br />
            24/7 {{ $t('deposit.default.instant') }}
          </div>
          <div class="more">
            <div></div>
          </div>
        </div>
      </div>
    </li> -->
  </ul>
</template>

<script>
import mixin from '@/mixins/page/deposit/deposit';
import showCreditcard from '@/mixins/page/deposit/showCreditcard';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import { countryCodeEnum } from '@/constants/country';
import { permittedCpsCreditCardPaymentMethods } from '@/constants/payment/depositSetting';
import DepositListing from '@/components/cps/DepositListing';

export default {
  mixins: [mixin, showCreditcard, cpsMixin],
  components: { DepositListing },
  props: ['cpsDepositList'],
  data() {
    return {
      OnlyMasterWorldcard: [4101, 6581], //FRANCE, SPAIN
      permittedCpsCreditCardPaymentMethods: permittedCpsCreditCardPaymentMethods,
      appleSolidpayIncludeList: [
        countryCodeEnum.ALBANIA,
        countryCodeEnum.ALGERIA,
        countryCodeEnum.AMERICAN_SAMOA,
        countryCodeEnum.ANDORRA,
        countryCodeEnum.ANGOLA,
        countryCodeEnum.ANGUILLA,
        countryCodeEnum.ANTARCTICA,
        countryCodeEnum.ANTIGUA_AND_BARBUDA,
        countryCodeEnum.ARMENIA,
        countryCodeEnum.ARUBA,
        countryCodeEnum.AUSTRALIA,
        countryCodeEnum.AZERBAIJAN,
        countryCodeEnum.BAHAMAS,
        countryCodeEnum.BAHRAIN,
        countryCodeEnum.BANGLADESH,
        countryCodeEnum.BARBADOS,
        countryCodeEnum.BELARUS,
        countryCodeEnum.BELIZE,
        countryCodeEnum.BENIN,
        countryCodeEnum.BERMUDA,
        countryCodeEnum.BHUTAN,
        countryCodeEnum.BOLIVIA,
        countryCodeEnum.BOSNIA_AND_HERZEGOVINA,
        countryCodeEnum.BOTSWANA,
        countryCodeEnum.BOUVET_ISLAND,
        countryCodeEnum.BRITISH_INDIAN_OCEAN,
        countryCodeEnum.BRUNEI_DARUSSALAM,
        countryCodeEnum.BURKINA_FASO,
        countryCodeEnum.BURUNDI,
        countryCodeEnum.CAMBODIA,
        countryCodeEnum.CAMEROON,
        countryCodeEnum.CAPE_VERDE,
        countryCodeEnum.CAYMAN_ISLANDS,
        countryCodeEnum.CENTRAL_AFRICAN,
        countryCodeEnum.CHAD,
        countryCodeEnum.CHILE,
        countryCodeEnum.CHRISTMAS_ISLAND,
        countryCodeEnum.COCOS_ISLANDS,
        countryCodeEnum.COMOROS,
        countryCodeEnum.CONGO_DEMOCRATIC,
        countryCodeEnum.CONGO_REPUBLIC,
        countryCodeEnum.COOK_ISLANDS,
        countryCodeEnum.COSTA_RICA,
        countryCodeEnum.COTE_D_IVOIRE,
        countryCodeEnum.CROATIA,
        countryCodeEnum.CYPRUS,
        countryCodeEnum.CZECH_REPUBLIC,
        countryCodeEnum.DENMARK,
        countryCodeEnum.DJIBOUTI,
        countryCodeEnum.DOMINICA,
        countryCodeEnum.DOMINICAN_REPUBLIC,
        countryCodeEnum.ECUADOR,
        countryCodeEnum.EGYPT,
        countryCodeEnum.EL_SALVADOR,
        countryCodeEnum.EQUATORIAL_GUINEA,
        countryCodeEnum.ERITREA,
        countryCodeEnum.ESTONIA,
        countryCodeEnum.ETHIOPIA,
        countryCodeEnum.FALKLAND_ISLANDS,
        countryCodeEnum.FAROE_ISLAND,
        countryCodeEnum.FIJI,
        countryCodeEnum.FINLAND,
        countryCodeEnum.FRENCH_GUIANA,
        countryCodeEnum.FRENCH_POLYNESIA,
        countryCodeEnum.FRENCH_SOUTHERN_TERR,
        countryCodeEnum.GABON,
        countryCodeEnum.GAMBIA,
        countryCodeEnum.GEORGIA,
        countryCodeEnum.GERMANY,
        countryCodeEnum.GHANA,
        countryCodeEnum.GIBRALTAR,
        countryCodeEnum.GREECE,
        countryCodeEnum.GREENLAND,
        countryCodeEnum.GRENADA,
        countryCodeEnum.GUADELOUPE,
        countryCodeEnum.GUAM,
        countryCodeEnum.GUATEMALA,
        countryCodeEnum.GUERNSEY,
        countryCodeEnum.GUINEA,
        countryCodeEnum.GUINEA_BISSAU,
        countryCodeEnum.GUYANA,
        countryCodeEnum.HAITI,
        countryCodeEnum.HEARD_MCDONALD_ISLANDS,
        countryCodeEnum.VATICAN_CITY_STATE,
        countryCodeEnum.HONDURAS,
        countryCodeEnum.HONG_KONG,
        countryCodeEnum.HUNGARY,
        countryCodeEnum.ICELAND,
        countryCodeEnum.IRELAND,
        countryCodeEnum.ISLE_OF_MAN,
        countryCodeEnum.JAMAICA,
        countryCodeEnum.JERSEY,
        countryCodeEnum.JORDAN,
        countryCodeEnum.KAZAKHSTAN,
        countryCodeEnum.KENYA,
        countryCodeEnum.KIRIBATI,
        countryCodeEnum.SOUTH_KOREA,
        countryCodeEnum.KOSOVO,
        countryCodeEnum.KUWAIT,
        countryCodeEnum.KYRGYZSTAN,
        countryCodeEnum.LAOS,
        countryCodeEnum.LATVIA,
        countryCodeEnum.LEBANON,
        countryCodeEnum.LESOTHO,
        countryCodeEnum.LIBERIA,
        countryCodeEnum.LIBYA,
        countryCodeEnum.LIECHTENSTEIN,
        countryCodeEnum.LITHUANIA,
        countryCodeEnum.LUXEMBOURG,
        countryCodeEnum.MACAO,
        countryCodeEnum.MACEDONIA,
        countryCodeEnum.MADAGASCAR,
        countryCodeEnum.MALAWI,
        countryCodeEnum.MALDIVES,
        countryCodeEnum.MALI,
        countryCodeEnum.MALTA,
        countryCodeEnum.MARSHALL_ISLANDS,
        countryCodeEnum.MARTINIQUE,
        countryCodeEnum.MAURITANIA,
        countryCodeEnum.MAURITIUS,
        countryCodeEnum.MAYOTTE,
        countryCodeEnum.MEXICO,
        countryCodeEnum.MICRONESIA,
        countryCodeEnum.MOLDOVA,
        countryCodeEnum.MONACO,
        countryCodeEnum.MONGOLIA,
        countryCodeEnum.MONTENEGRO,
        countryCodeEnum.MONTSERRAT,
        countryCodeEnum.MOROCCO,
        countryCodeEnum.MOZAMBIQUE,
        countryCodeEnum.NAMIBIA,
        countryCodeEnum.NAURU,
        countryCodeEnum.NEPAL,
        countryCodeEnum.NEW_CALEDONIA,
        countryCodeEnum.NEW_ZEALAND,
        countryCodeEnum.NICARAGUA,
        countryCodeEnum.NIGER,
        countryCodeEnum.NIGERIA,
        countryCodeEnum.NIUE,
        countryCodeEnum.NORFOLK_ISLAND,
        countryCodeEnum.NORTH_MARIANA,
        countryCodeEnum.NORWAY,
        countryCodeEnum.OMAN,
        countryCodeEnum.PAKISTAN,
        countryCodeEnum.PALAU,
        countryCodeEnum.PALESTINIAN,
        countryCodeEnum.PANAMA,
        countryCodeEnum.PAPUA_NEW_GUINEA,
        countryCodeEnum.PARAGUAY,
        countryCodeEnum.PERU,
        countryCodeEnum.PHILIPPINES,
        countryCodeEnum.PITCAIRN,
        countryCodeEnum.POLAND,
        countryCodeEnum.PORTUGAL,
        countryCodeEnum.PUERTO_RICO,
        countryCodeEnum.QATAR,
        countryCodeEnum.REUNION,
        countryCodeEnum.ROMANIA,
        countryCodeEnum.RWANDA,
        countryCodeEnum.SAINT_BARTHELEMY,
        countryCodeEnum.ST_HELENA,
        countryCodeEnum.ST_KITTS_NEVIS,
        countryCodeEnum.SAINT_LUCIA,
        countryCodeEnum.SAINT_MARTIN,
        countryCodeEnum.ST_PIERRE_MIQUELON,
        countryCodeEnum.SAINT_VINCENT_AND_THE_GRENADINES,
        countryCodeEnum.SAMOA,
        countryCodeEnum.SAN_MARINO,
        countryCodeEnum.SAO_TOME_PRINCIPE,
        countryCodeEnum.SAUDI_ARABIA,
        countryCodeEnum.SENEGAL,
        countryCodeEnum.SERBIA,
        countryCodeEnum.MONTENEGRO,
        countryCodeEnum.SEYCHELLES,
        countryCodeEnum.SIERRA_LEONE,
        countryCodeEnum.SINGAPORE,
        countryCodeEnum.SINT_MAARTEN,
        countryCodeEnum.SLOVAKIA,
        countryCodeEnum.SOLOMON_ISLANDS,
        countryCodeEnum.SOMALIA,
        countryCodeEnum.SOUTH_AFRICA,
        countryCodeEnum.SOUTH_GEORGIA,
        countryCodeEnum.SOUTH_SUDAN,
        countryCodeEnum.SRI_LANKA,
        countryCodeEnum.SURINAME,
        countryCodeEnum.SVALBARD,
        countryCodeEnum.SWAZILAND,
        countryCodeEnum.SWEDEN,
        countryCodeEnum.TAIWAN,
        countryCodeEnum.TAJIKISTAN,
        countryCodeEnum.TANZANIA,
        countryCodeEnum.THAILAND,
        countryCodeEnum.TIMOR_LESTE,
        countryCodeEnum.TOGO,
        countryCodeEnum.TOKELAU,
        countryCodeEnum.TONGA,
        countryCodeEnum.TRINIDAD_AND_TOBAGO,
        countryCodeEnum.TUNISIA,
        countryCodeEnum.TURKEY,
        countryCodeEnum.TURKMENISTAN,
        countryCodeEnum.TURKS_AND_CAICOS_ISLANDS,
        countryCodeEnum.TUVALU,
        countryCodeEnum.UGANDA,
        countryCodeEnum.UKRAINE,
        countryCodeEnum.UNITED_ARAB_EMIRATES,
        countryCodeEnum.UNITED_STATES,
        countryCodeEnum.URUGUAY,
        countryCodeEnum.UZBEKISTAN,
        countryCodeEnum.VANUATU,
        countryCodeEnum.VIETNAM,
        countryCodeEnum.VIRGIN_ISLANDS,
        countryCodeEnum.VIRGIN_ISLANDS_US,
        countryCodeEnum.WALLIS_FUTUNA,
        countryCodeEnum.WESTERN_SAHARA,
        countryCodeEnum.YEMEN,
        countryCodeEnum.ZAMBIA,
        countryCodeEnum.ZIMBABWE,
        countryCodeEnum.FRANCE,
        countryCodeEnum.INDONESIA,
        countryCodeEnum.NETHERLANDS_ANTILLES,
        countryCodeEnum.UNITED_KINGDOM,
        countryCodeEnum.ARGENTINA,
        countryCodeEnum.AUSTRALIA,
        countryCodeEnum.BULGARIA,
        countryCodeEnum.CHINA,
        countryCodeEnum.COLOMBIA,
        countryCodeEnum.ITALY,
        countryCodeEnum.JAPAN,
        countryCodeEnum.MYANMAR,
        countryCodeEnum.NETHERLANDS,
        countryCodeEnum.SLOVENIA,
        countryCodeEnum.SWITZERLAND
      ]
    };
  },
  computed: {
    countryCode() {
      return parseInt(this.$store.state.common.countryCode);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
</style>
