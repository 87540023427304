import { instance } from '@/axios/index.js'
import { cpsWithdrawalUrl, cpsUrl } from './index'

export const apiGetCpsAvailableChannel = data =>
  instance.get(`${cpsUrl}availableChannel`, {
    params: data,
  })

export const apiGetCpsWithdrawalChannel = data =>
  instance.get(`${cpsWithdrawalUrl}getCPSWithdrawalData_cp`, {
    params: data,
  })

export const apiCps_payment = (data, token) => instance.post(`${cpsUrl}deposit`, data, { headers: { token: token } })
export const apiCpsCreditCardpayment = (data, token) => instance.post(`${cpsUrl}credit-card`, data, { headers: { token: token } })
