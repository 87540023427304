<template>
  <ul class="link-box">
    <!-- <li @click="bitcoinClick">
      <div class="top title">BITCOIN</div>
      <div class="info">
        <div class="logo circle bitcoin"></div>
        <div class="right">
          <div class="span">1 {{ $t('deposit.default.hour') }}</div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li>
    <li @click="usdtClick(2)">
      <div class="top title">USDT-OMNI</div>
      <div class="info">
        <div class="logo circle USDT_1"></div>
        <div class="right">
          <div class="span">1 {{ $t('deposit.default.hour') }}</div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li>
    <li @click="usdtClick(4)">
      <div class="top title">USDT-</div>
      <div class="info">
        <div class="logo circle USDT_2"></div>
        <div class="right">
          <div class="span">1 {{ $t('deposit.default.hour') }}</div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->
    <!-- <li @click="bitcoinCPSClick" data-testid="bitcoin">
      <div class="top title">BITCOIN</div>
      <div class="info">
        <div class="logo circle bitcoin"></div>
        <div class="right">
          <div class="span">24/7 {{ $t('deposit.default.instant') }}</div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li>
    <li @click="usdt_cpsClick" data-testid="usdt">
      <div class="top title">USDT-ERC20</div>
      <div class="info">
        <div class="logo circle USDT_2"></div>
        <div class="right">
          <div class="span">24/7 {{ $t('deposit.default.instant') }}</div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li>
    <li @click="trc20_cpsClick" data-testid="trc20">
      <div class="top title">USDT-TRC20</div>
      <div class="info">
        <div class="logo circle USDT_2"></div>
        <div class="right">
          <div class="span">24/7 {{ $t('deposit.default.instant') }}</div>
          <div class="more"><div></div></div>
        </div>
      </div>
    </li> -->

    <!-- CPS Deposit List -->
    <template v-for="(payment, index) in cpsDepositList">
      <DepositListing
        :payment="payment"
        :key="'cps-' + index"
        v-if="permittedCpsCryptoPaymentMethods.includes(payment.payment_method)"
      ></DepositListing>
    </template>
  </ul>
</template>

<script>
import mixin from '@/mixins/page/deposit/deposit';
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';
import DepositListing from '@/components/cps/DepositListingCps';

export default {
  mixins: [mixin, cpsMixin],
  components: { DepositListing },
  props: ['cpsDepositList']
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
</style>
