<template>
  <el-form :model="apiForm" ref="ruleForm">
    <section>
      <p>{{ $t('register.moreAboutYou.complianceExplain') }}</p>
    </section>
    <ul>
      <li v-for="q in EFQuestion" v-bind:key="q.questionId">
        <el-form-item
          :prop="`${0}_${q.questionId}`"
          :rules="{ required: true, message: $t('common.formValidation.answerReq'), trigger: 'blur' }"
        >
          <div class="label">{{ q.desc }}</div>
          <div class="question">
            <el-radio-group :disabled="disableField" v-model="apiForm[`${0}_${q.questionId}`]" data-testid="question">
              <el-radio-button v-for="qo in q.questionOptions" v-bind:key="qo.id" :label="qo.id" :data-testid="qo.id">
                {{ qo.desc }}
              </el-radio-button>
            </el-radio-group>
          </div>
        </el-form-item>
      </li>
    </ul>
    <div>
      <h3>{{ $t('register.moreAboutYou.pageTitle2') }}</h3>
      <ul class="clearfix">
        <li v-for="q in tradingQuestions" v-bind:key="q.questionId">
          <el-form-item
            :prop="`${1}_${q.questionId}`"
            :rules="{ required: true, message: $t('common.formValidation.answerReq'), trigger: 'blur' }"
          >
            <div class="label">{{ q.desc }}</div>
            <div class="question">
              <el-radio-group :disabled="disableField" v-model="apiForm[`${1}_${q.questionId}`]">
                <el-radio-button v-for="qo in q.questionOptions" v-bind:key="qo.id" :label="qo.id">
                  {{ qo.desc }}
                </el-radio-button>
              </el-radio-group>
            </div>
          </el-form-item>
        </li>
      </ul>
    </div>
  </el-form>
</template>

<script>
import moreAboutYou from '@/mixins/register/moreAboutYou';

export default {
  mixins: [moreAboutYou],
  methods: {
    submit() {
      let employmentFinanceAnswers = [],
        tradingAnswers = [];
      Object.keys(this.apiForm).map((m, i) => {
        const split = m.split('_');
        split[0] == 0
          ? employmentFinanceAnswers.push({ questionId: split[1], answers: [this.apiForm[m]] })
          : tradingAnswers.push({ questionId: split[1], answers: [this.apiForm[m]] });
      });
      this.form.employmentFinanceAnswers = employmentFinanceAnswers;
      this.form.tradingAnswers = tradingAnswers;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/register/moreAboutYou.scss';
</style>
