import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

// import registerState from './register/state'
// import registerMutations from './register/mutations'
// import registerActions from './register/actions'

import commonState from './common/state'
import commonMutations from './common/mutations'
import commonActions from './common/actions'

import regulatorState from './regulator/state'
import regulatorMutations from './regulator/mutations'
import regulatorActions from './regulator/actions'

import paymentState from './payment/state'
import paymentMutations from './payment/mutations'
import paymentActions from './payment/actions'

import cpsState from './cps/state'
import cpsMutations from './cps/mutations'
import cpsActions from './cps/actions'

import promotion from './promotion'

import propTrading from './propTrading'
import register from './register'

Vue.use(Vuex)

const common = {
  namespaced: true,
  state: commonState,
  mutations: commonMutations,
  actions: commonActions,
}

// const register = {
//   namespaced: true,
//   state: registerState,
//   mutations: registerMutations,
//   actions: registerActions,
// }

const regulator = {
  namespaced: true,
  state: regulatorState,
  mutations: regulatorMutations,
  actions: regulatorActions,
}

const payment = {
  namespaced: true,
  state: paymentState,
  mutations: paymentMutations,
  actions: paymentActions,
}

const cps = {
  namespaced: true,
  state: cpsState,
  mutations: cpsMutations,
  actions: cpsActions,
}

export default new Vuex.Store({
  modules: {
    common,
    regulator,
    payment,
    promotion,
    cps,
    propTrading,
    register,
  },
  plugins: [
    createPersistedState({
      reducer(val) {
        return {
          common: val.common,
          regulator: val.regulator,
          payment: {
            paymentList: val.payment.paymentList,
            restrictedDeposit: val.payment.restrictedDeposit,
          },
          promotion: {
            eligibleCampaigns: val.promotion.eligibleCampaigns,
            serverTime: val.promotion.serverTime,
          },
          cps: val.cps,
          register: val.register,
        }
      },
    }),
  ],
})
