import { apiCampaignInfo } from '@/resource'

const state = () => ({
  tradingContestInfo: {},
  tradingContestWithdrawRestrict: true,
  tradingContestAcc: '',
  tradingContestId: null,
})

const mutations = {
  setTradingContestInfo(state, value) {
    state.tradingContestInfo = value
  },
  setTradingContestId(state, value) {
    state.tradingContestId = value
  },
  setTradingContestAcc(state, value) {
    state.tradingContestAcc = value
  },
  setTradingContestWithdrawRestrict(state, value) {
    state.tradingContestWithdrawRestrict = value
  },
}

const actions = {
  actionTradingContestParticipantInfo: async ({ commit, getters }, params) => {
    return new Promise((resolve, reject) => {
      apiCampaignInfo(params.campaignId, params.languageCode).then(result => {
        if (result.data.code === 0) {
          let data = result.data.data
          commit('setTradingContestInfo', data)
          commit('setTradingContestWithdrawRestrict', data.withdrawDisqualify)
          let showAccStatus = ['Ineligible', 'Trade Now']
          if (data.participantAccount.length && showAccStatus.includes(data.status)) {
            commit('setTradingContestAcc', data.participantAccount[0].mt4Account?.toString())
          } else {
            commit('setTradingContestAcc', '')
          }
          resolve(result.data.data.mtAccount)
        } else {
          commit('setTradingContestAcc', '')
        }
      })
    })
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  // getters,
}
