const bankInfoDetails = {
  bank_name: { show: true, value: 'Access Bank plc' },
  // branch_address: { show: true, value: 'Plot 635, Akin Adesola Victoria Island, Lagos, Nigeria' },
  account_name: {
    USD: { show: true, value: 'Pacific Union Markets Limited' },
    // EUR: { show: true, value: 'Pacific Union Markets Limited' },
    // GBP: { show: true, value: 'Pacific Union Markets Limited' },
    NGN: { show: true, value: 'Pacific Union Markets Limited' },
    USC: { show: true, value: 'Pacific Union Markets Limited' },
  },
  account_number: {
    USD: { show: true, value: '1880851522' },
    // GBP: { show: true, value: 'GBP 0663285212' },
    USC: { show: true, value: '1880851522' },
  },
  // secondary_account_number: {
  //   show: true,
  //   value: 'NGN 0660446560',
  // },
  // swift_code: { show: true, value: 'GTBINGLA' },
  // sort_code: { show: true, value: '058-083930' },
}

module.exports = {
  bank_infos(currency, showSecAccNumber = true) {
    let bank_infos = []
    bank_infos.push({
      title: 'common.field.bankName',
      info: bankInfoDetails.bank_name,
    })
    // bank_infos.push({
    //   title: 'common.field.branchAddress',
    //   info: bankInfoDetails.branch_address,
    // })
    bank_infos.push({
      title: 'common.field.bankBeneAccName',
      info: bankInfoDetails.account_name[currency],
    })
    bank_infos.push({
      title: 'common.field.accNum',
      info: bankInfoDetails.account_number[currency],
    })
    // if (showSecAccNumber)
    //   bank_infos.push({
    //     info: bankInfoDetails.secondary_account_number,
    //     desc: 'deposit.default.secondaryNGN',
    //   })
    bank_infos.push({
      title: 'common.field.bankBeneAddress',
      info: bankInfoDetails.beneficiary_address,
    })
    // bank_infos.push({
    //   title: 'common.field.swift',
    //   info: bankInfoDetails.swift_code,
    // })
    // bank_infos.push({
    //   title: 'common.field.abaSortCode',
    //   info: bankInfoDetails.sort_code,
    // })
    return bank_infos
  },
}
