import { instance } from '@/axios/index.js'
import { personalDetailUrl, verificationUrl, questionUrl, userUrl } from './index'

export const apiCountries = lang => instance.get(`${personalDetailUrl}countries?lang=${lang}`)
export const apiProvinces = lang => instance.get(`${personalDetailUrl}provinces?lang=${lang}`)
export const apiNationalities = lang => instance.get(`${personalDetailUrl}nationalities?lang=${lang}`)
export const apiBirthPlace = lang => instance.get(`${personalDetailUrl}birthPlace?lang=${lang}`)
export const apiPhoneCodes = () => instance.get(`${personalDetailUrl}phoneCodes`)
export const apiID3Status = () => instance.get(`${personalDetailUrl}id3Passed`)

export const apiEmploymentFinance = lang => instance.get(`${questionUrl}employmentFinance?lang=${lang}`)
export const apiTrading = lang => instance.get(`${questionUrl}trading?lang=${lang}`)

export const apiGetCurrentStep = () => instance.get(`${verificationUrl}getCurrentStep`)
export const apiGetData = step => instance.get(`${verificationUrl}getData?step=${step}`)
export const apiProcess = data => instance.post(`${verificationUrl}process`, data)
export const apiIbtGetData = () => instance.get(`${verificationUrl}ibtGetData`)
export const apiSubmitIbtQuestionnaire = data => instance.post(`${verificationUrl}submitIbtQuestionnaire`, data)

export const apiGetUsername = () => instance.get(`${userUrl}getUsername`)

//old api, delete later
export const apiGetData_old = step => instance.get(`verification/getData?step=${step}`)
export const apiProcess_old = data => instance.post(`verification/process`, data)
