<template>
  <div :class="['button-wrapper', ...buttonTypeClasses, ...calCustomClasses]" :style="cssVars">
    <el-button
      v-bind="$attrs"
      v-on="$listeners"
      :class="[isOutline ? 'outline' : '']"
      :style="{
        color: calTextColor,
        'background-color': calBgColor,
        ...calStyles
      }"
      :data-testid="calDataTestId"
    >
      <slot></slot>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'Button',
  props: {
    buttonType: {
      type: String,
      default: 'default' // default, pending, complete, rejected
    },
    textColor: {
      type: String
    },
    height: {
      type: String | Number,
      default: '100%'
    },
    width: {
      type: String | Number,
      default: '100%'
    },
    outline: {
      type: Boolean,
      default: false
    },
    customClasses: {
      type: Array | String,
      default: ''
    },
    bgColor: {
      type: String
    },
    styles: {
      type: Object,
      default: () => ({})
    },
    dataTestId: {
      type: String | Number,
      default: 'button'
    }
  },
  computed: {
    calDataTestId() {
      return this.dataTestId;
    },
    calStyles() {
      return { ...this.styles };
    },
    calButtonType() {
      return this.buttonType;
    },
    calTextColor() {
      return this.textColor;
    },
    calBgColor() {
      return this.bgColor;
    },
    buttonTypeClasses() {
      return this.buttonType || this.buttonType !== 'default' ? [`${this.buttonType}-button`] : [];
    },
    isOutline() {
      return this.outline;
    },
    calCustomClasses() {
      return typeof this.customClasses === 'string' ? [this.customClasses] : [...this.customClasses];
    },
    cssVars() {
      const formatWidthHeight = value => {
        if (Number(value) || Number(value) == 0) return `${Math.abs(Number(value))}px`;
        return value;
      };
      return {
        '--height': formatWidthHeight(this.height),
        '--width': formatWidthHeight(this.width)
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.button-wrapper {
  width: var(--width);
  height: var(--height);
}

/deep/ .el-button {
  text-transform: none;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  font-size: 18px;
  padding: 12px;
  font-weight: 800;
  background-color: $pug-blue;
  border: 1px solid $pug-blue;
  color: $pug-white;
  word-break: break-word;
  white-space: initial;

  > span {
    display: block;
    padding: 4px;
  }

  &:hover {
    background-color: $pug-blue-hover;
    border: 1px solid $pug-blue-hover;
  }
  &.is-circle {
    border-radius: 50%;
  }
  &.is-round {
    border-radius: 20px;
    padding: 16px 34px;
  }
  &.is-disabled {
    color: $pug-gray;
    border-color: $pug-gray-disabled-bg;
    background-color: $pug-gray-disabled-bg;
    &.outline {
      color: $pug-gray;
      border: 1px solid $pug-gray;
      background-color: $pug-white;
    }
  }
  &:hover.is-disabled {
    cursor: url('../../assets/images/common/not-allowed.png'), not-allowed;
  }
  &.outline {
    color: $pug-black;
    border: 1px solid $pug-gray;
    background-color: $pug-white;
  }
  &:hover.outline {
    color: $pug-blue-hover;
    border: 1px solid $pug-blue-hover;
    background-color: $pug-white;
  }
  &:hover.outline.is-disabled {
    color: $pug-gray;
    border: 1px solid $pug-gray;
    background-color: $pug-white;
  }

  &.el-button--text {
    background: 0 0 !important;
    padding: 0;
    color: $pug-blue;
    border: none;
  }
  &:hover.el-button--text {
    color: $pug-blue-hover;
  }
  &.el-button--text.is-disabled {
    color: $pug-gray;
  }
}
.pending-button {
  /deep/ .el-button {
    cursor: auto;
    color: $pug-yellow;
    background-color: $pug-yellow-bg;
    border: 1px solid $pug-yellow-bg;
    &.outline {
      color: $pug-yellow;
      border: 1px solid $pug-yellow;
      background-color: $pug-white;
    }
    &:hover.outline.is-disabled {
      color: $pug-yellow;
      background-color: $pug-yellow;
    }

    &:hover.el-button--text {
      color: $pug-yellow;
    }
  }
}
.complete-button {
  /deep/ .el-button {
    cursor: auto;
    color: $pug-green;
    background-color: $pug-green-bg;
    border: 1px solid $pug-green-bg;
    &.outline {
      color: $pug-green;
      border: 1px solid $pug-green;
      background-color: $pug-white;
    }
    &:hover.is-disabled.outline {
      color: $pug-green;
      background-color: $pug-green;
    }

    &:hover.el-button--text {
      color: $pug-green;
    }
  }
}
.rejected-button {
  /deep/ .el-button {
    cursor: auto;
    color: $pug-red;
    background-color: $pug-red-bg;
    border: 1px solid $pug-red-bg;
    &.outline {
      color: $pug-red;
      border: 1px solid $pug-red;
      background-color: $pug-white;
    }
    &:hover.is-disabled.outline {
      color: $pug-red;
      background-color: $pug-red;
    }

    &:hover.el-button--text {
      color: $pug-red;
    }
  }
}
</style>
