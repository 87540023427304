/**
 * shoud consistent with backend
 */
export const countryCodeEnum = {
  ALGERIA: 3290,
  ARGENTINA: 3346,
  ARMENIA: 6729,
  AUSTRALIA: 3512,
  BAHAMAS: 3588,
  BANGLADESH: 7028,
  BARBADOS: 7046,
  BOLIVIA: 3781,
  BRAZIL: 3636,
  BRUNEI_DARUSSALAM: 6458,
  CAPE_VERDE: 4160,
  CAMBODIA: 4635,
  CANADA: 4575,
  CAYMAN_ISLANDS: 4711,
  CHILE: 7010,
  CHINA: 1,
  COLOMBIA: 4186,
  COSTA_RICA: 4221,
  DOMINICA: 3981,
  DOMINICAN_REPUBLIC: 3982,
  ECUADOR: 4070,
  EL_SALVADOR: 5961,
  GUATEMALA: 6404,
  HAITI: 4305,
  HONDURAS: 4514,
  HONG_KONG: 7033,
  INDIA: 6845,
  INDONESIA: 6877,
  JORDAN: 6976,
  LAOS: 4850,
  LIBERIA: 4877,
  MADAGASCAR: 4978,
  MALAYSIA: 5015,
  MARSHALL_ISLANDS: 5156,
  MEXICO: 5619,
  MONGOLIA: 5527,
  MOROCCO: 5606,
  NEW_CALEDONIA: 7075,
  NICARAGUA: 5767,
  OMAN: 3389,
  PAKISTAN: 3589,
  PALAU: 5826,
  PANAMA: 3635,
  PAPUA_NEW_GUINEA: 3567,
  PARAGUAY: 3600,
  PERU: 5558,
  PHILIPPINES: 4131,
  RWANDA: 4917,
  SAINT_LUCIA: 6053,
  SAMOA: 5511,
  SEYCHELLES: 6024,
  SINGAPORE: 6645,
  SOUTH_KOREA: 7041,
  SRI_LANKA: 6057,
  SAINT_VINCENT_AND_THE_GRENADINES: 6056,
  SURINAME: 6121,
  TAIWAN: 7043,
  TANZANIA: 7089,
  THAILAND: 6163,
  TRINIDAD_AND_TOBAGO: 6277,
  URUGUAY: 6544,
  VANUATU: 6396,
  VIETNAM: 6991,
  ZAMBIA: 6996,
  ANTARCTICA: 5748,
  BELIZE: 3809,
  JAMAICA: 6713,
  MACAO: 7069,
  FRENCH_GUIANA: 7059,
  MOZAMBIQUE: 5618,
  FRENCH_POLYNESIA: 4127,
  MALAWI: 5010,
  FIJI: 4137,
  GUYANA: 4254,
  TONGA: 6268,
  TURKS_AND_CAICOS_ISLANDS: 7092,
  ETHIOPIA: 3423,
  JAPAN: 5860,
  MAURITANIA: 5161,
  NEPAL: 5751,
  NEW_ZEALAND: 6647,
  SOLOMON_ISLANDS: 6133,
  AFGHANISTAN: 3340,
  EGYPT: 3416,
  BELARUS: 3665,
  NORTH_MARIANA: 3685,
  PUERTO_RICO: 3715,
  BHUTAN: 3817,
  BURUNDI: 3865,
  TIMOR_LESTE: 3959,
  RUSSIA: 3983,
  ERITREA: 4093,
  CUBA: 4233,
  GUAM: 4253,
  KIRIBATI: 4535,
  GUINEA: 4564,
  GUINEA_BISSAU: 4574,
  COOK_ISLANDS: 4809,
  LEBANON: 4869,
  LIBYA: 4896,
  MALDIVES: 4986,
  MALI: 5144,
  UNITED_STATES: 5176,
  MICRONESIA: 5557,
  MYANMAR: 5589,
  NAURU: 5750,
  SUDAN: 6110,
  SOMALIA: 6145,
  TAJIKISTAN: 6146,
  TUNISIA: 6278,
  TUVALU: 6304,
  VENEZUELA: 6432,
  UKRAINE: 6517,
  SYRIA: 6695,
  IRAQ: 6765,
  IRAN: 6766,
  CENTRAL_AFRICAN: 7025,
  AMERICAN_SAMOA: 7027,
  CONGO_DEMOCRATIC: 7030,
  NORTH_KOREA: 7036,
  PALESTINIAN: 7037,
  ST_KITTS_NEVIS: 7039,
  SINT_MAARTEN: 7040,
  KOREA: 7041,
  SOUTH_SUDAN: 7042,
  ARUBA: 7050,
  BRITISH_INDIAN_OCEAN: 7052,
  CHRISTMAS_ISLAND: 7055,
  COCOS_ISLANDS: 7056,
  FALKLAND_ISLANDS: 7058,
  FRENCH_SOUTHERN_TERR: 7060,
  HEARD_MCDONALD_ISLANDS: 7064,
  NETHERLANDS_ANTILLES: 7074,
  NIUE: 7076,
  NORFOLK_ISLAND: 7077,
  PITCAIRN: 7079,
  ST_PIERRE_MIQUELON: 7084,
  TOKELAU: 7091,
  VIRGIN_ISLANDS: 6431,
  WESTERN_SAHARA: 7096,
  ANTIGUA_AND_BARBUDA: 7047,
  AUSTRIA: 3554,
  BELGIUM: 3701,
  CAMEROON: 4688,
  CROATIA: 7057,
  CYPRUS: 6016,
  DENMARK: 3899,
  ESTONIA: 3464,
  FINLAND: 4138,
  FRANCE: 4101,
  GEORGIA: 5859,
  GERMANY: 3915,
  GHANA: 4611,
  GREECE: 6633,
  ICELAND: 3714,
  IRELAND: 3436,
  ITALY: 6777,
  KAZAKHSTAN: 4266,
  KOSOVO: 7034,
  LUXEMBOURG: 4912,
  MACEDONIA: 5155,
  MALTA: 5009,
  NAMIBIA: 5681,
  NETHERLANDS: 4482,
  NIGERIA: 5796,
  NORWAY: 5804,
  POLAND: 3716,
  PORTUGAL: 5828,
  SAINT_MARTIN: 7083,
  SLOVAKIA: 6084,
  SLOVENIA: 6094,
  SPAIN: 6581,
  TURKEY: 6305,
  SWAZILAND: 6109,
  SWEDEN: 5909,
  SWITZERLAND: 5932,
  UNITED_ARAB_EMIRATES: 3382,
  UNITED_KINGDOM: 6907,
  ANDORRA: 3481,
  ANGOLA: 3490,
  BAHRAIN: 3624,
  BULGARIA: 3674,
  BOTSWANA: 3808,
  CZECH_REPUBLIC: 4661,
  KUWAIT: 4735,
  KENYA: 4759,
  LATVIA: 4810,
  LITHUANIA: 4897,
  MOLDOVA: 5605,
  HUNGARY: 6673,
  ISRAEL: 6767,
  QATAR: 4700,
  ROMANIA: 4935,
  SOUTH_AFRICA: 5696,
  SERBIA: 5987,
  SAUDI_ARABIA: 6025,
  UGANDA: 6459,
  GABON: 4624,
  BENIN: 3686,
  LESOTHO: 4838,
  SENEGAL: 6003,
  ALAND_ISLANDS: 7048,
  SAINT_BARTHELEMY: 7081,
  AZERBAIJAN: 3399,
  TOGO: 3974,
  MAURITIUS: 5160,
  GUADELOUPE: 7062,
  MARTINIQUE: 7070,
  REUNION: 7080,
  BURKINA_FASO: 3818,
  GRENADA: 4231,
  GREENLAND: 4232,
  MONACO: 5617,
  ANGUILLA: 7049,
  GIBRALTAR: 7061,
  JERSEY: 7066,
  UZBEKISTAN: 6565,
  GUERNSEY: 7063,
  ISLE_OF_MAN: 7065,
  MAYOTTE: 7071,
  ALBANIA: 3276,
  BOSNIA_AND_HERZEGOVINA: 3795,
  KYRGYZSTAN: 4546,
  CHAD: 7008,
  CONGO_REPUBLIC: 7031,
  VATICAN_CITY_STATE: 7044,
  COTE_D_IVOIRE: 7045,
  BERMUDA: 3673,
  FAROE_ISLAND: 4126,
  GAMBIA: 4183,
  DJIBOUTI: 4540,
  COMOROS: 4713,
  LIECHTENSTEIN: 4910,
  NIGER: 5786,
  SIERRA_LEONE: 5997,
  SAO_TOME_PRINCIPE: 6050,
  SAN_MARINO: 6054,
  TURKMENISTAN: 6385,
  EQUATORIAL_GUINEA: 7032,
  MONTENEGRO: 7035,
  BOUVET_ISLAND: 7051,
  MONTSERRAT: 7073,
  ST_HELENA: 7082,
  SOUTH_GEORGIA: 7085,
  SVALBARD: 7087,
  CENTRAL_AFRICAN_REPUBLIC: 7025,
  ZIMBABWE: 4676,
  YEMEN: 6742,
}
