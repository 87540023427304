export default {
  async actionSetPayment({ commit, state }, payment) {
    commit('setPayment', payment)
  },
  async actionSetWithdrawalPayment({ commit }, payment) {
    commit('setWithdrawalPayment', payment)
  },
  async actionSetSecondaryWithdrawalPayment({ commit }, payment) {
    commit('setSecondaryWithdrawalPayment', payment)
  },
}
