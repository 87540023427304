import store from '@/store';
import { createSecureURL } from '@/util/secureURLUtils'

const redirect = {
  async redirectToIbPortal() {
    const url = process.env.NODE_ENV == 'development' ? 'http://localhost:8083/' : store.state.common.url
    if (url) {
      try {
        const secureURL = await createSecureURL(url, store.state.common.accessToken);
        window.location = secureURL

      } catch (error) {
        console.log(error)
      }
    }
  },
  redirectToLogoutUrl() {
    console.log(window.location.origin)
    window.location = `${window.location.origin}/login`
  },
}

export default redirect
