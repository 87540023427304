export const registerConfig = {
  personalInformation: {
    frontendStep: 1.1,
    backendStep: 1,
  },
  createLiveAccount: {
    frontendStep: 1.2,
    backendStep: 4,
  },
  identityVerification: {
    frontendStep: 2.1,
    backendStep: 5,
  },
  poaVerification: {
    frontendStep: 3.1,
    backendStep: 6,
  },
  financialInfo: {
    frontendStep: 4.1,
    backendStep: 6,
  },
  iDProof: {
    frontendStep: 4.2,
    backendStep: 6,
  },
  addressProof: {
    frontendStep: 4.3,
    backendStep: 6,
  },
}

export const idTypeConfig = {
  passport: 2,
  idCard: 1,
  driverLicense: 3,
  other: 4,
}
