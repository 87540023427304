import { registerConfig, idTypeConfig } from '@/constants/register'
import i18n from '@/lang/index'
import { statusConfig } from '@/constants/status'
import { REGISTERROUTER } from '@/constants/route'
import store from '@/store/index'

export const registerFirstStep = Object.freeze({
  personalInformation: registerConfig.personalInformation.frontendStep,
  createLiveAccount: registerConfig.createLiveAccount.frontendStep,
})

export const registerFinanicalStep = Object.freeze({
  financialInfo: registerConfig.financialInfo.frontendStep,
  iDProof: registerConfig.iDProof.frontendStep,
  addressProof: registerConfig.addressProof.frontendStep,
})

export function getIdTypeList(isIBT=false, isMalaysiaId3=false) {
  const res = Object.entries(idTypeConfig).map(([key, val]) => {
    return { value: val, label: i18n.t(`register.identityVerification.idTypeDropdown.${key}`) }
  })
  // IBT poi IDType does not have "driverLicense" & "other"
  if(isIBT) return [...res].filter(item => item.value !== 3 && item.value !== 4)
  // Malaysia ID3Pass only allowed ID Card
  return isMalaysiaId3 ? [...res].filter(item => item.value === 1) : [...res]
}

export function getUserStep(val) {
  if (val) return registerConfig[val].backendStep
}

export function getNextUserStep(val) {
  if (val) {
    return getNextStep(val).backendStep
  }
}

export function getRegisterStep(val) {
  if (val) return registerConfig[val].frontendStep
}

// migrate RegisterStep 2 & 3 to 1
function migrationRegisterStep (step) {
  if(step) {
    return step == 2 || step == 3 ? 1 : step
  }
  return null
}
export function getRegisterStepByNum(num, poaAuditStatus) {
  const step = migrationRegisterStep(num)
  if (step) {
    if (poaAuditStatus !== statusConfig.init) return registerConfig.financialInfo.frontendStep
    return convertToRegisterStep(step)
  }
}

export function getNextRegisterStep(val, status) {
  if (val) return getNextStep(val).frontendStep
}

function getNextStep(val) {
  let feStep = (registerConfig[val].frontendStep * 10 + 1) / 10
  let config = Object.values(registerConfig).find(val => val.frontendStep === feStep)
  if (!config) {
    feStep = (Math.floor(feStep) * 10 + 11) / 10
    const result = Object.values(registerConfig).find(val => val.frontendStep === feStep)
    // for frontendStep over the last one, set to the last frontendStep
    config = !result ? registerConfig[Object.keys(registerConfig)[Object.keys(registerConfig).length - 1]] : result
  }
  return config
}

export function convertToUserStep(num) {
  if (num) {
    return Object.values(registerConfig).find(val => val.frontendStep === num).backendStep
  }
}

export function convertToRegisterStep(num) {
  if (num) {
    return Object.values(registerConfig).find(val => val.backendStep === num).frontendStep
  }
}

export const getStatus = value => {
  switch (value) {
    case 0:
    case 'Submitted':
      return statusConfig.submitted
    case 1:
    case 'Pending':
      return statusConfig.pending
    case 2:
    case 'Completed':
      return statusConfig.completed
    case 3:
    case 'Rejected':
      return statusConfig.rejected
    case 4:
    case 'Re-Audit':
      return statusConfig.reaudit
    default:
      return statusConfig.init
  }
}

// user can enter page only under init, pending, rejected status
export const checkRegisterPageCanAudit = status => status === statusConfig.init || status === statusConfig.pending || status === statusConfig.rejected

export const generalRegisterDirectPage = ({isIBT}) => {
  // ibt process
  if(isIBT) {
    if (
      store.state.register.ibtPoiAuditStatus === statusConfig.completed &&
      store.state.register.ibtPoaAuditStatus === statusConfig.completed
    ) {
      return ''
    }

    if (
      store.state.register.ibtPoiAuditStatus === statusConfig.completed &&
      store.state.register.ibtPoaAuditStatus !== statusConfig.completed
    ) {
      return checkRegisterPageCanAudit(store.state.register.ibtPoaAuditStatus) ? 'addressProof' : 'register'
    } else if (
      store.state.register.ibtPoiAuditStatus !== statusConfig.completed &&
      store.state.register.ibtPoaAuditStatus === statusConfig.completed
    ) {
      return checkRegisterPageCanAudit(store.state.register.ibtPoiAuditStatus) ? 'iDProof' : 'register'
    } else {
      return 'financialInfo'
    }
  }
  
  // general poi, poa process
  if (store.state.register.currStep < registerConfig.identityVerification.frontendStep) {
    // accountAuditStatus will be 'submitted' when registered from official website
    return store.state.register.currStep === registerConfig.personalInformation.frontendStep
      ? 'personalInformation'
      : 'createLiveAccount'
  }

  if (checkRegisterPageCanAudit(store.state.register.poiAuditStatus)) {
    return 'identityVerification'
  }

  if (checkRegisterPageCanAudit(store.state.register.poaAuditStatus)) {
    return 'poaVerification'
  }

  return 'register'
}
