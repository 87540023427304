<template>
  <div class="bank_info" v-if="isShow">
    <ul v-for="item in bankInfos" :key="item.title">
      <li v-if="item.info && item.info.show">
        <span class="title_name">{{ $t(item.title) }}</span>
        <span class="value_info">{{ getValueInfo(item) }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import NigeriaSA from '@/bankInfo/NigeriaSA';
import EqualsMoney from '@/bankInfo/EqualsMoney';

export default {
  name: 'InternationalDefaultBankInfo',
  props: { isShow: Boolean, currency: String, type: String, selectedAccount: String },
  data() {
    return {
      exceptionCountry: ['6777', '6907', '3701', '4575', '3899', '6581'] // Italy, United Kingdom, Belgium, Canada, Denmark, Spain
    };
  },
  methods: {
    isEqualsMoneyBankAllowedCountry() {
      const whiteList = [
        '7048',
        '7027',
        '3481',
        '7050',
        '3554',
        '3624',
        '3686',
        '3817',
        '3674',
        '4688',
        '4160',
        '7008',
        '7010',
        '1',
        '7055',
        '7056',
        '4186',
        '4713',
        '4221',
        '7097',
        '6016',
        '4661',
        '3981',
        '3982',
        '3416',
        '5961',
        '7032',
        '6109',
        '4126',
        '4137',
        '4138',
        '4101',
        '7059',
        '4127',
        '4624',
        '4183',
        '5859',
        '3915',
        '4232',
        '4231',
        '7062',
        '4253',
        '6404',
        '7063',
        '7033',
        '6845',
        '3436',
        '5860',
        '7066',
        '4850',
        '4810',
        '4838',
        '4910',
        '4897',
        '4912',
        '7069',
        '4978',
        '5010',
        '4986',
        '7070',
        '5161',
        '5619',
        '5617',
        '7073',
        '5618',
        '5750',
        '4482',
        '7075',
        '6647',
        '5786',
        '5796',
        '7076',
        '5804',
        '5826',
        '5558',
        '3716',
        '5828',
        '3715',
        '4700',
        '7080',
        '6053',
        '6054',
        '6025',
        '6645',
        '6084',
        '6133',
        '5696',
        '7041',
        '6121',
        '7087',
        '5909',
        '5932',
        '7043',
        '6163',
        '3959',
        '3974',
        '7091',
        '6268',
        '6544',
        '6565',
        '7044',
        '6991'
      ];
      const grayList = [
        '7049',
        '4482',
        '7031',
        '4809',
        '7058',
        '4127',
        '7071',
        '5605',
        '7077',
        '3567',
        '7079',
        '7081',
        '7082',
        '7084',
        '5511',
        '7092',
        '4101',
        '3290',
        '3490',
        '7047',
        '3346',
        '3512',
        '3588',
        '7028',
        '3809',
        '3781',
        '3808',
        '3636',
        '6431',
        '6458',
        '4540',
        '4070',
        '4611',
        '4254',
        '4514',
        '3714',
        '7065',
        '4266',
        '4759',
        '4535',
        '4735',
        '4546',
        '5156',
        '5160',
        '5557',
        '5527',
        '5681',
        '5751',
        '3389',
        '3600',
        '7039',
        '7083',
        '6056',
        '6050',
        '5987',
        '6024',
        '6057',
        '7089',
        '6278',
        '6385',
        '6304',
        '3382'
      ];
      const totalList = whiteList.concat(grayList);
      return totalList.includes(this.countryCode);
    },
    getValueInfo(item) {
      if (item.needAccountNumber) return this.$t(item.info.value, { accountNumber: this.calSelectedAccount });
      return item.info.value + ' ' + this.$t(item.desc);
    }
  },
  computed: {
    bankInfos() {
      switch (true) {
        case this.regulator === 'fsa' && ['5696'].includes(this.countryCode):
          return NigeriaSA.bank_infos(this.currency);
        case this.regulator === 'svg' && this.isEqualsMoneyBankAllowedCountry():
          return EqualsMoney.bank_infos(this.currency, 1);
        case this.regulator === 'svg' && this.exceptionCountry.includes(this.countryCode):
          return EqualsMoney.bank_infos(this.currency, 2);
        default:
          return this.$config.bank_infos(this.currency, this.regulator, this.type);
      }
    },
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    calSelectedAccount() {
      return this.selectedAccount;
    }
  }
};
</script>
