<template>
  <Cima ref="regulator"></Cima>
</template>

<script>
import Cima from '@/components/template/register/userDetail/Cima';

export default {
  components: { Cima }
};
</script>
