import i18n from '@/lang/index'
import store from '@/store'

const mapVariable = {
  email: 'email',
  totp: 'totp',
  addTwoFactor: 'enable-auth-2fa',
  login: 'login',
  switchLoginEmail: 'login-email-close',
}
const mapOperation = (operation, showDays = 1) => {
  switch (operation) {
    case 'modify-password':
      return {
        title: i18n.t('propTrade.button.changePwd'),
        desc: i18n.t('security.changeDesc', {
          numDays: showDays,
          method: i18n.t('security.password'),
        }),
        factorAuth: 'modify-password',
        success: {
          title: i18n.t('security.changePasswordSuccess'),
          desc: i18n.t('security.changePasswordSuccessDesc'),
        },
        confirmButton: i18n.t('common.button.confirm'),
        cancelButton: i18n.t('common.button.cancel'),
      }
    case 'modify-email':
      return {
        title: i18n.t('security.changeEmail'),
        desc: i18n.t('security.changeDesc', {
          numDays: showDays,
          method: i18n.t('common.field.emailAdd'),
        }),
        factorAuth: 'modify-email',
        success: {
          title: i18n.t('security.changeEmailSuccess'),
          desc: i18n.t('security.changeEmailSuccessDesc'),
        },
        confirmButton: i18n.t('common.button.confirm'),
        cancelButton: i18n.t('common.button.cancel'),
      }
    case 'enable-auth-2fa':
      return {
        title: i18n.t('security.changeAuthenticatorTitle'),
        desc: i18n.t('security.changeDesc', {
          numDays: showDays,
          method: i18n.t('security.securityAuthenticatorApp'),
        }),
        factorAuth: 'enable-auth-2fa',
        success: {
          title: i18n.t('security.enableSecuritySuccess'),
          desc: i18n.t('security.enableSecuritySuccessDesc'),
        },
        confirmButton: i18n.t('common.button.confirm'),
        cancelButton: i18n.t('common.button.cancel'),
      }
    case 'modify-auth-2fa':
      return {
        title: i18n.t('security.changeAuthenticatorTitle'),
        desc: i18n.t('security.changeDesc', {
          numDays: showDays,
          method: i18n.t('security.securityAuthenticatorApp'),
        }),
        factorAuth: 'modify-auth-2fa',
        success: {
          title: i18n.t('security.updateSecuritySuccess'),
          desc: i18n.t('security.updateSecuritySuccessDesc'),
        },
        confirmButton: i18n.t('common.button.confirm'),
        cancelButton: i18n.t('common.button.cancel'),
      }
    default:
      return null
  }
}

const mapVerificationOperation = (operation, emailAddress) => {
  switch (operation) {
    case mapVariable['email']: {
      return {
        showCodeButton: true,
        preDesc: i18n.t('security.verifyPreEmailNote', {
          email: emailAddress,
        }),
        postDesc: i18n.t('security.verifyPostEmailNote', {
          email: emailAddress,
        }),
      }
    }
    case 'new-email': {
      return {
        showCodeButton: true,
        needEmail: true,
        preDesc: i18n.t('security.preEmailCode'),
        postDesc: i18n.t('security.preEmailCode'),
      }
    }
    default: {
      return {
        showCodeButton: false,
        preDesc: i18n.t('security.authenticatorTooltip'),
      }
    }
  }
}

const mapSwitchComponent = (value, usedAuthenticator) => {
  switch (value) {
    case mapVariable['login']:
      return {
        title: i18n.t('security.enableLoginAuthentication'),
        usedVariable: 'loginSwitch',
        factorAuth: usedAuthenticator == mapVariable['email'] ? 'login-email-close' : 'login-totp-close',
      }

    default:
      return null
  }
}

const mapVerifiedAuth = (authType, verifiedAuths) => {
  //for showing verification dialog purpose (don't need for email)
  switch (authType) {
    case mapVariable['email']:
      return true
    case mapVariable['totp']:
      return verifiedAuths.filter(item => item == authType).length > 0
  }
}

const getToPath = (type, verified) => {
  switch (type) {
    case mapVariable['email']:
      return '/verifyAuthentication'

    case mapVariable['totp']:
      return verified ? '/verifyAuthentication' : '/addAuthentication'
  }
}

const mapReverifiedBody = (factorAuth, resp) => {
  const respBody = resp?.data?.data
  const usedAuths = resp?.data?.code == 1201 ? respBody?.lackAuthMethods : respBody?.effectiveAuthMethods
  store.commit('common/setCheckedAuthStatus', respBody.authStatus)

  let usedRequiredAuths = store.state.common.requiredAuths
  usedRequiredAuths[factorAuth] =
    resp?.data?.code == 1201
      ? respBody?.effectiveAuthMethods.concat(respBody?.lackAuthMethods)
      : respBody?.effectiveAuthMethods
  store.commit('common/setRequiredAuths', usedRequiredAuths)
  return usedAuths.map(item => {
    return {
      type: item,
      authenticated: false,
      verified: mapVerifiedAuth(item, respBody.authStatus),
    }
  })
}

const langOptions = () => {
  return [
    { value: 'en_US', label: i18n.t('common.field.english') },
    { value: 'fr_FR', label: i18n.t('common.field.francais') },
    { value: 'es', label: i18n.t('common.field.espanol') },
    { value: 'pt', label: i18n.t('common.field.portuguese') },
    { value: 'ko', label: i18n.t('common.field.korean') },
    { value: 'zh_CN', label: i18n.t('common.field.chinese') },
    { value: 'ja', label: i18n.t('common.field.japanese') },
    { value: 'th', label: i18n.t('common.field.Thai') },
    { value: 'vi', label: i18n.t('common.field.vietnamese') },
    { value: 'de', label: i18n.t('common.field.deutsch') },
    { value: 'id', label: i18n.t('common.field.indonesia') },
    { value: 'ar', label: i18n.t('common.field.arabic') },
  ]
}

export {
  mapOperation,
  mapVerificationOperation,
  mapVariable,
  mapSwitchComponent,
  getToPath,
  mapVerifiedAuth,
  mapReverifiedBody,
  langOptions,
}
