//需转码urls列表
export const FILTER_URLS = [
    'payment/info/getPaymentInfoList/',
    'verificationV2/getData',
    'verification/getData',
    'tool/nv-calculator/products',
    'user/getUsername',
    'to_login', 'Login',
    // 'multiFactorAuth/hintInfo',
    // 'notification/enabled',
    'getPaymentInfoList',
    'downloadAccountTransactionHistoryPDF',
    'profile/info',
    'ticket',
    'withdrawal/creditCard/balances',
    'withdrawal/queryUnionpayCard',
    'card-register/history/list',
    'card-register/get-card-info',
    'funds/transfer-history',
    'transactionHistory/withdrawDetail',
    'deposit/fetch_card',
    'campaign',
    'banner/enabled'
]
