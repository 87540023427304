import { apiEligiblePropTrading } from '@/resource'
import { isSuccess } from '@/util/restApi'

const state = () => ({
  isPropTradingAccount: false, // user from official PT page or user had applied for PT sessions
  session: false,
  eligible: false,
  account: false,
  hasActiveAccounts: false,
})

const mutations = {
  setStatus(state, value) {
    state.session = value.session
    state.eligible = value.eligible
    state.account = value.account
  },
  SET_IS_PROP_TRADING_ACCOUNT: (state, payload) => (state.isPropTradingAccount = payload),

  SET_HAS_ACTIVE_ACCOUNTS(state, value) {
    state.hasActiveAccounts = value
  },
}

const actions = {
  async actionGetEligiblePropTrading({ commit, state }) {
    const result = await apiEligiblePropTrading()
    if (isSuccess(result.data)) {
      commit('setStatus', result.data.data)
    } else {
      commit('setStatus', {
        session: false,
        eligible: false,
        account: false
      })
    }

    return result
  },
  actionChangeIsPropTrading: ({ commit }, value) => commit('SET_IS_PROP_TRADING_ACCOUNT', value),
}

const getters = {
  accountStatusMap: (state, getters, rootState) => {
    return {
      1: 'active',
      2: 'verifying',
      3: 'passed',
      4: 'failed',
    }
  },
  accountStageMap: (state, getters, rootState) => {
    return {
      4: 'liveTrading',
      5: 'liveTradingPlus',
      2: 'evaluation',
      3: 'evaluationPlus',
      0: 'challenge',
      1: 'challengePlus',
    }
  },
  stageMap: (state, getters, rootState) => {
    return {
      0: 'challenge',
      1: 'evaluation',
      2: 'liveTrading',
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
