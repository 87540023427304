<template>
  <div class="info-dialog">
    <GeneralDialog
      :visible.sync="open"
      visibleHeader
      hideChat
      :closeOnClickModal="closeOnClickModal"
      @close="closCbDialog"
    >
      <div class="info-dialog-body">
        <div v-if="calInfoType" class="info-head" :class="[calInfoType]">
          <img class="info-head-icon" :src="require(`@/assets/images/dialog/${calInfoType}.png`)" alt="infoType" />
          <div class="info-head-title">{{ calTitle }}</div>
        </div>

        <div class="info-content">
          <slot />
        </div>

        <div class="actions">
          <slot name="actions"></slot>
        </div>
      </div>
    </GeneralDialog>
  </div>
</template>

<script>
import GeneralDialog from '@/components/dialog/general/Dialog.vue';

export default {
  name: 'InfoDialog',
  components: {
    GeneralDialog
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    infoType: {
      type: String,
      default: 'general' // general, success, pending, fail
    }
  },
  data() {
    return {
      open: false
    };
  },
  computed: {
    calTitle() {
      return this.title;
    },
    calInfoType() {
      return this.infoType;
    }
  },
  watch: {
    visible(val) {
      this.open = val;
    },
    open(bool) {
      this.handleClose(bool);
    }
  },
  methods: {
    handleClose(bool) {
      this.$nextTick(() => {
        this.$emit('update:visible', bool);
      });
    },
    closCbDialog() {
      this.$emit('closCbDialog');
    }
  }
};
</script>

<style lang="scss" scoped>
.info-head {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;

  &-icon {
    width: 60px;
    height: 60px;
    margin-bottom: 20px;
  }
  &-title {
    font-weight: 800;
    font-size: 20px;
    letter-spacing: 0.12px;
    line-height: 20px;
    word-break: break-word;
    text-align: center;
  }

  &.general {
    .info-head-title {
      color: $pug-blue;
    }
  }
  &.pending {
    .info-head-title {
      color: $pug-gray-pending;
    }
  }
  &.success {
    .info-head-title {
      color: $pug-green-success;
    }
  }
  &.fail {
    .info-head-title {
      color: $pug-red;
    }
  }
}

.info-dialog {
  /deep/ .el-dialog {
    max-width: 400px;

    .el-dialog__body {
      padding: 8px 60px 60px 60px;

      .dialog-body {
        padding: 0;
      }
    }
  }
}
.info-content {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  word-break: break-word;
  text-align: center;
  margin-bottom: 32px;
  padding: 0;
}

@media (max-width: 768px) {
  .info-dialog {
    /deep/ .el-dialog {
      top: 50% !important;
      transform: translateY(-50%);

      .el-dialog__body {
        padding: 8px 24px 40px 24px;
      }
    }
  }
}

@media (max-width: 640px) {
  .info-head {
    &-title {
      font-size: 16px;
    }
  }
  .info-dialog {
    /deep/ .el-dialog {
      max-width: 348px;
    }
  }
}
</style>
