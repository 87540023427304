export function handleXssInput (value, isPassword = false) {
  if(value) {
    return isPassword
      ? value
          .replace(/\</g, '')
          .replace(/\>/g, '')
          .replace(/\"/g, '')
          .replace(/\//g, '')
      : value
          .replace(/\&/g, '')
          .replace(/\</g, '')
          .replace(/\>/g, '')
          .replace(/\"/g, '')
          .replace(/\//g, '')
  }

  return value
}

export function inhibitInputSpecialCharacters (value) {
  return value && value.replace(/[\-\_\,\.\!\|\~\`\(\)\#\@\%\-\+\=\/\'\$\%\^\&\*\{\}\:\;\"\<\>\?\\]/g, '');
}

export function inhibitInputNumber(value) {
  return value && value.replace(/[0-9]/g, '');
}

export function currencyToSymbol(currency) {
  switch (currency) {
    case 'USD':
      return '$'
    case 'AUD':
      return 'A$'
    case 'CAD':
      return 'C$'
    case 'EUR':
      return '€'
    case 'GBP':
      return '￡'
    case 'NZD':
      return 'NZ$'
    case 'SGD':
      return 'S$'
    case 'JPY':
      return '￥'
    case 'HKD':
      return 'HK$'
    case 'USC':
      return '￠'
    default:
      return ''
  }
}
