import { apiGetData_old } from '@/resource/register'

export default {
  data() {
    return {
      form: {
        step: 3,
        employmentFinanceAnswers: [],
        tradingAnswers: [],
      },
      apiForm: {},
    }
  },
  mounted() {
    const vm = this
    Promise.all([
      this.$store.dispatch('register/actionEFQuestion', this.lang),
      this.$store.dispatch('register/actionTradingQuestion', this.lang),
    ]).then(function(values) {
      let questionForm = {}

      values.map((m, i) => {
        m.map((mc, j) => {
          questionForm[`${i}_${mc.questionId}`] = ''
        })
      })

      vm.apiForm = questionForm
      vm.getUserDate()
    })
  },
  computed: {
    EFQuestion() {
      return this.$store.state.register.EFQuestion
    },
    tradingQuestions() {
      return this.$store.state.register.tradingQuestions
    },
    lang() {
      return this.$store.state.common.lang
    },
    disableField() {
      return this.$store.state.register.disableField
    },
  },
  methods: {
    getUserDate() {
      apiGetData_old(3).then(resp => {
        const userData = resp.data.data
        userData.employmentFinanceAnswers.map((m, i) => {
          this.apiForm[`0_${m.questionId}`] = m.answers[0]
        })

        userData.tradingAnswers.map((m, i) => {
          this.apiForm[`1_${m.questionId}`] = m.answers[0]
        })
      })
    },
  },
}
