const { countryCodeEnum } = require('@/constants/country')
const {
  REFER_A_FRIEND_PATHNAME,
  DEPOSIT_BONUS_PATHNAME,
  DYNAMIC_DEPOSIT_BONUS_PATHNAME,
  TRADING_BONUS_PATHNAME,
  CASHBACK_PATHNAME,
  DEPOSIT_20_BONUS_PATHNAME,
  DEPOSIT_MY_20_BONUS_PATHNAME,
  XMAS_MAKE_A_WISH_PATHNAME,
  FIRST_DEPOSIT_CASHBACK_PATHNAME,
  MILLION_DOLLAR_CASH_POOL_PATHNAME,
  FIRST_TIME_DEPOSIT_PATHNAME,
  RUGBY_PATHNAME,
  XMAS_SPIN_AND_WIN_PATHNAME,
  NFP_PATHNAME,
  VDAY_PROMO_PATHNAME,
  EURO2024_PATHNAME,
  OLYMPIC2024_PATHNAME,
} = require('@/constants/route')
const { WHITELIST } = require('@/constants/internationalBankTransfer')

module.exports = {
  info: {
    url: 'www.puprime.com',
    map: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4616.687864043498!2d55.45538001942663!3d-4.619136458242956!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x22e028533cfa53ad%3A0x48d516d8499b9550!2sOrion%20Building!5e0!3m2!1sen!2sau!4v1592795342692!5m2!1sen!2sau',
    address: `<span>Suite C, Orion Mall, Palm Street, Victoria, Mahe, Seychelles</span>`,
    phone: '+248 4373 105',
    email: 'info@puprime.com',
    accountEmail: 'info@puprime.com',
    blacklistEmail: 'info@puprime.com',
  },
  menus: [
    {
      name: 'menu.home',
      path: '/home',
      icon: require('@/assets/images/menu/menu_icon_home.png'),
    },
    {
      name: 'menu.authentication',
      path: '/register',
      icon: require('@/assets/images/menu/authentication_icon.png'),
    },
    {
      name: 'menu.accManagement',
      path: '/accountManagement',
      icon: require('@/assets/images/menu/menu_icon_account.png'),
      children: [
        {
          name: 'menu.liveAcc',
          path: '/liveAccount',
          icon: require('@/assets/images/menu/menu_icon_account_live_account.png'),
        },
        {
          optional: true,
          name: 'menu.openAdditAcc',
          path: '/openLiveAccount',
          icon: require('@/assets/images/menu/menu_icon_account_open_account.png'),
        },
        {
          name: 'menu.homeDemo',
          path: '/homeDemo',
          icon: require('@/assets/images/menu/menu_icon_account_demo_account.png'),
        },
      ],
    },
    {
      name: 'menu.funds',
      path: '/funds',
      icon: require('@/assets/images/menu/menu_icon_funds.png'),
      children: [
        {
          checkBlacklist: true,
          name: 'menu.depositFund',
          path: '/depositFunds',
          icon: require('@/assets/images/menu/menu_icon_funds_deposit.png'),
        },
        {
          checkBlacklist: true,
          name: 'menu.withdrawFunds',
          path: '/withdrawFunds',
          icon: require('@/assets/images/menu/menu_icon_funds_withdraw.png'),
        },
        {
          checkBlacklist: true,
          name: 'menu.transferBetweenAccs',
          path: '/transferFunds',
          icon: require('@/assets/images/menu/menu_icon_funds_transfer.png'),
        },
        {
          name: 'menu.payDetails',
          path: '/paymentDetails',
          icon: require('@/assets/images/menu/menu_icon_funds_payment_detail.png'),
        },
        {
          name: 'menu.transactionHis',
          path: '/transactionHistory',
          icon: require('@/assets/images/menu/menu_icon_funds_transaction_history.png'),
        },
      ],
    },
    {
      name: 'menu.promotion',
      path: REFER_A_FRIEND_PATHNAME,
      icon: require('@/assets/images/menu/menu_icon_promotion.png'),
      children: [
        {
          name: 'menu.referFriends',
          path: REFER_A_FRIEND_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_refer_friends.png'),
        },
        {
          name: 'menu.tradingBonus',
          path: TRADING_BONUS_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_trading_bonus.png'),
        },
        {
          name: 'menu.cryptoCashback',
          path: CASHBACK_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_crypto_cashback.png'),
        },
        {
          name: 'menu.xmasMakeAWish',
          path: XMAS_MAKE_A_WISH_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_xmas_make_a_wish.png'),
        },
        {
          name: 'menu.firstDepositCashback',
          path: FIRST_DEPOSIT_CASHBACK_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_first_deposit_cashback.png'),
        },
        {
          name: 'menu.millionDollarCashPool',
          path: MILLION_DOLLAR_CASH_POOL_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_million_dollar_cash_pool.png'),
        },
        {
          name: 'menu.firstTimeDeposit',
          path: FIRST_TIME_DEPOSIT_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_first_time_deposit.png'),
        },
        // {
        //   name: 'menu.rugby',
        //   path: RUGBY_PATHNAME,
        //   icon: require('@/assets/images/menu/menu_icon_promotion_icon_rugby.png'),
        // },
        {
          name: 'menu.xmasSpinAndWin',
          path: XMAS_SPIN_AND_WIN_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_icon_xmas_spin_and_win.png'),
        },
        {
          name: 'menu.nfp',
          path: NFP_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_nfp.svg'),
        },
        {
          name: 'menu.vdayPromo',
          path: VDAY_PROMO_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_promotion_vday_promo.svg'),
        },
        {
          name: 'menu.euro2024',
          path: EURO2024_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_euro2024.png'),
        },
        {
          name: 'menu.olympic2024',
          path: OLYMPIC2024_PATHNAME,
          icon: require('@/assets/images/menu/menu_icon_olympic2024.png'),
        },
      ],
    },
    {
      // prop trading menu will control by backend, "eligible" value of apiEligiblePropTrading
      name: 'menu.propTrade',
      path: '/propTrading',
      icon: require('@/assets/images/menu/menu_icon_proptrade.png'),
      tag: 'propTrade.tags.new',
      children: [
        {
          name: 'menu.propTradeDashboard',
          path: '/propTrading/dashboard',
          icon: require('@/assets/images/menu/menu_icon_proptrade_dashboard.png'),
        },
        {
          name: 'menu.propTradeCreateAccount',
          path: '/propTrading/createAccount',
          icon: require('@/assets/images/menu/menu_icon_proptrade_create_account.png'),
        },
        {
          name: 'menu.propTradeAchievement',
          path: '/propTrading/achievement',
          icon: require('@/assets/images/menu/menu_icon_proptrade_achievement.png'),
        },
      ],
    },
    {
      name: 'menu.autochartist',
      path: '/autochartist',
      icon: require('@/assets/images/menu/menu_icon_autochartist.png'),
    },
    {
      name: 'menu.download',
      path: '/downloads',
      icon: require('@/assets/images/menu/menu_icon_down.png'),
    },
    {
      name: 'menu.contactUs',
      path: '/contactUs',
      icon: require('@/assets/images/menu/menu_icon_contact.png'),
    },
  ],
  tradingPlatform: {
    mt4: true,
    mt5: true,
    app: true,
  },
  restrictDepositFundsByCountries: [],
  restrictOpenAdditionAccountCountries: [1],
  openLiveAccount: {
    choosePlatform: [
      {
        value: 'mt5',
        label: 'register.accOpeningVerification.createLiveAccount.tradingPlatformType.mt5',
      },
      {
        value: 'MTS',
        label: 'register.accOpeningVerification.createLiveAccount.tradingPlatformType.copyTrading',
      },
      {
        value: 'mt4',
        label: 'register.accOpeningVerification.createLiveAccount.tradingPlatformType.mt4',
      },
    ],
    chooseLiveType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
      },
      {
        value: 'swapFreeRawECN',
        label: 'Islamic-Prime',
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_STP_CENT',
        label: 'Islamic-Cent',
      },
      {
        value: 'PAMM',
        label: 'PAMM',
      },
      // 注释掉2000杠杆和pro类型
      // {
      //   value: 'pro1000',
      //   label: 'Pro Standard',
      // },
      {
        value: 'RAW_ECN_CENT',
        label: 'Prime Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_ECN_CENT',
        label: 'Islamic Prime Cent',
      },
      {
        value: 'ECN',
        label: 'ECN',
      },
    ],
    additionalAccountType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'ECN',
        label: 'ECN',
      },
      // {
      //   value: 'pro1000',
      //   label: 'Pro Standard',
      // },
      {
        value: 'Islamic',
        label: 'Islamic',
        children: [
          {
            // Islamic-Standard
            value: 'swapFreeStandardSTP',
            label: 'Standard',
          },
          {
            // Islamic-Cent
            value: 'ISLAMIC_STP_CENT',
            label: 'Standard Cent',
          },
          {
            // Islamic-Prime
            value: 'swapFreeRawECN',
            label: 'Prime',
          },
          {
            // Islamic-Prime-Cent
            value: 'ISLAMIC_ECN_CENT',
            label: 'Prime Cent',
          },
        ],
      },
      {
        value: 'Cent',
        label: 'Cent',
        children: [
          {
            value: 'STANDARD_STP_CENT',
            label: 'Standard',
            restrictCountries: [
              1, // China
            ],
          },
          {
            value: 'RAW_ECN_CENT',
            label: 'Prime',
            restrictCountries: [
              1, // China
            ],
          },
        ],
      },
      {
        value: 'PAMM',
        label: 'PAMM',
      },
    ],
    mt4DemoChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'ECN',
        label: 'ECN',
      },
    ],
    mt5ChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'swapFreeStandardSTP',
        label: 'Islamic-Standard',
      },
      {
        value: 'swapFreeRawECN',
        label: 'Islamic-Prime',
      },
      {
        value: 'PAMM',
        label: 'PAMM',
      },
      {
        value: 'STANDARD_STP_CENT',
        label: 'Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_STP_CENT',
        label: 'Islamic-Cent',
      },
      // {
      //   value: 'pro1000',
      //   label: 'Pro Standard',
      // },
      {
        value: 'RAW_ECN_CENT',
        label: 'Prime Cent',
        restrictCountries: [
          1, //China
        ],
      },
      {
        value: 'ISLAMIC_ECN_CENT',
        label: 'Islamic Prime Cent',
      },
      {
        value: 'ECN',
        label: 'MT5 ECN',
      },
    ],
    registerChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'ECN',
        label: 'ECN',
      },
      // {
      //   value: 'pro1000',
      //   label: 'Pro Standard',
      // },
      {
        value: 'Islamic',
        label: 'Islamic',
        children: [
          {
            // Islamic-Standard
            value: 'swapFreeStandardSTP',
            label: 'Standard',
          },
          {
            // Islamic-Cent
            value: 'ISLAMIC_STP_CENT',
            label: 'Standard Cent',
          },
          {
            // Islamic-Prime
            value: 'swapFreeRawECN',
            label: 'Prime',
          },
          {
            // Islamic-Prime-Cent
            value: 'ISLAMIC_ECN_CENT',
            label: 'Prime Cent',
          },
        ],
      },
      {
        value: 'Cent',
        label: 'Cent',
        children: [
          {
            value: 'STANDARD_STP_CENT',
            label: 'Standard',
            restrictCountries: [
              1, // China
            ],
          },
          {
            value: 'RAW_ECN_CENT',
            label: 'Prime',
            restrictCountries: [
              1, // China
            ],
          },
        ],
      },
    ],
    mt5DemoChooseType: [
      {
        value: 'standardSTP',
        label: 'Standard',
      },
      {
        value: 'rawECN',
        label: 'Prime',
      },
      {
        value: 'ECN',
        label: 'MT5 ECN',
      },
    ],
    chooseCurrency: [
      {
        img: require('@/assets/images/flag/us_flag.png'),
        value: 'USD',
        currency: 'USD',
      },
      {
        img: require('@/assets/images/flag/eu_flag.png'),
        value: 'EUR',
        currency: 'EUR',
      },
      {
        img: require('@/assets/images/flag/uk_flag.png'),
        value: 'GBP',
        currency: 'GBP',
      },
      {
        img: require('@/assets/images/flag/ca_flag.png'),
        value: 'CAD',
        currency: 'CAD',
      },
      {
        img: require('@/assets/images/flag/jp_flag.png'),
        value: 'JPY',
        currency: 'JPY',
      },
      {
        img: require('@/assets/images/flag/au_flag.png'),
        value: 'AUD',
        currency: 'AUD',
      },

      {
        img: require('@/assets/images/flag/sg_flag.png'),
        value: 'SGD',
        currency: 'SGD',
      },
      {
        img: require('@/assets/images/flag/nz_flag.png'),
        value: 'NZD',
        currency: 'NZD',
      },
      {
        img: require('@/assets/images/flag/hk_flag.png'),
        value: 'HKD',
        currency: 'HKD',
      },
    ],
    chooseCurrencyByType: {
      STANDARD_STP_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
      ISLAMIC_STP_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
      RAW_ECN_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
      ISLAMIC_ECN_CENT: [
        {
          img: require('@/assets/images/flag/us_flag.png'),
          value: 'USC',
          currency: 'USC',
        },
      ],
    },
    chooseLeverage: [
      {
        value: 100,
        label: '100:1',
      },
      {
        value: 200,
        label: '200:1',
      },
      {
        value: 300,
        label: '300:1',
      },
      {
        value: 400,
        label: '400:1',
      },
      {
        value: 500,
        label: '500:1',
      },
      {
        value: 1000,
        label: '1000:1',
      },
    ],
    chooseBalance: [
      { value: 2000, name: '$2000' },
      { value: 5000, name: '$5k' },
      { value: 10000, name: '$10k' },
      { value: 20000, name: '$20k' },
      { value: 50000, name: '$50k' },
      { value: 100000, name: '$100k' },
    ],
    getAgreeCondition: 'openAdditAcc.disclaimer',
  },
  metaTraderLinks(url) {
    return {
      mt4: {
        METATRADER4_windows: `https://download.mql5.com/cdn/web/pu.prime.ltd/mt4/puprime4setup.exe`,
        METATRADER4_iPhone: `https://download.mql5.com/cdn/mobile/mt4/ios?server=PUPrime-Demo,PUPrime-Live,PUPrime-Live%202,PUPrime-Live%203`,
        METATRADER4_iOS: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt4/MetaTrader4.dmg`,
        METATRADER4_Android: `https://download.mql5.com/cdn/mobile/mt4/android?server=PUPrime-Demo,PUPrime-Live,PUPrime-Live%202,PUPrime-Live%203`,
        METATRADER4_WebTrader: `https://webtrader.puprime.online/`,
      },
      mt5: {
        METATRADER5_windows: `https://download.mql5.com/cdn/web/pu.prime.ltd/mt5/puprime5setup.exe`,
        METATRADER5_iPhone: `https://download.mql5.com/cdn/mobile/mt5/ios?server=PUPrime-Demo,PUPrime-Live`,
        METATRADER5_iOS: `https://download.mql5.com/cdn/web/metaquotes.software.corp/mt5/MetaTrader5.dmg`,
        METATRADER5_Android: `https://download.mql5.com/cdn/mobile/mt5/android?server=PUPrime-Demo,PUPrime-Live`,
        METATRADER5_WebTrader: `https://webtrader.puprime.online/`,
      },
      app: {
        APP_iOS: 'https://puprime.onelink.me/O5Jx/APPCLIENTPORTAL',
        APP_Android: 'https://puprime.onelink.me/O5Jx/MAINAPPINSTALLANDROID',
      },
    }
  },
  bankInfoDetails: {
    bank_name: { show: true, value: 'DBS Bank' },
    currency: ['EUR', 'USD', 'GBP', 'USC'],
    branch_address: {
      AUD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      USD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      USC: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      EUR: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      GBP: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      JPY: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      CAD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      SGD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      NZD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
      HKD: {
        show: true,
        value: '12 Marina Boulevard, DBS Asia Central, Marina Bay Financial Centre Tower 3, Singapore 018982',
      },
    },
    branch_name: {
      AUD: { show: false, value: '' },
      USD: { show: false, value: '' },
      USC: { show: false, value: '' },
      EUR: { show: false, value: '' },
      GBP: { show: false, value: '' },
      JPY: { show: false, value: '' },
      CAD: { show: false, value: '' },
      SGD: { show: false, value: '' },
      NZD: { show: false, value: '' },
      HKD: { show: false, value: '' },
    },
    account_name: { show: true, value: "WTSG PACIFIC UNION - CUSTOMERS'ACCOUNT" },
    account_number: {
      USD: { show: true, value: '0720381505' },
      USC: { show: true, value: '0720381505' },
      EUR: { show: true, value: '0720381505' },
      GBP: { show: true, value: '0720381505' },
      CAD: { show: true, value: '0720381505' },
      SGD: { show: true, value: '0720381505' },
    },
    beneficiary_address: { show: true, value: 'Suite C, Orion Mall, Palm Street, Victoria, Mahe, Seychelles' },
    bsb_number: {
      AUD: { show: true, value: '034-234' },
      USD: { show: true, value: '034-702' },
      USC: { show: true, value: '034-702' },
      EUR: { show: true, value: '034-705' },
      GBP: { show: true, value: '034-703' },
      JPY: { show: true, value: '034-702' },
      CAD: { show: true, value: '034-702' },
      SGD: { show: true, value: '034-702' },
      NZD: { show: true, value: '034-702' },
      HKD: { show: true, value: '034-702' },
    },
    swift_code: { show: true, value: 'DBSSSGSG' },
  },
  banner(url) {
    return [
      {
        img: require('@/assets/images/banner/new02.png'),
        info: {
          title: 'reliable broker',
          tip: 'serving 120,000+ customers globaly',
        },
      },
      {
        img: 'deposit20Bonus/',
        rightSideImg: require('@/assets/images/banner/deposit20Bonus/box.png'),
        bannerUrl: DEPOSIT_20_BONUS_PATHNAME,
        promotionType: 'DEPOSIT_BONUS_2',
        extraClass: 'deposit20bns',
        bannerVersion: 'v1',
        promotionId: 32,
        title: 'promotion.deposit20Bonus.banner.title',
        ltr: true,
        info: {
          multiLang: true,
        },
      },
      {
        img: 'deposit20Bonus/',
        rightSideImg: require('@/assets/images/banner/deposit20Bonus/box.png'),
        bannerUrl: DEPOSIT_MY_20_BONUS_PATHNAME,
        promotionType: 'DEPOSIT_BONUS_2',
        extraClass: 'deposit20bns',
        bannerVersion: 'v1',
        promotionId: 43,
        title: 'promotion.depositMY20Bonus.banner.title',
        ltr: true,
        info: {
          multiLang: true,
        },
      },
      {
        img: require('@/assets/images/banner/trading_bonus/bg.png'),
        rightSideImg: require('@/assets/images/banner/trading_bonus/box.png'),
        bannerUrl: TRADING_BONUS_PATHNAME,
        promotionType: 'TRADING_BONUS',
        extraClass: 'tradingBonus',
        bannerVersion: 'v1',
        promotionId: 11,
        title: 'promotion.tradingBonus.banner.title',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/crypto_cashback_bonus/bg.png'),
        imgSmall: require('@/assets/images/banner/crypto_cashback_bonus/bg_sm.png'),
        imgSmallAr: require('@/assets/images/banner/crypto_cashback_bonus/bg_sm_ar.png'),
        rightSideImg: require('@/assets/images/banner/crypto_cashback_bonus/box.png'),
        bannerUrl: CASHBACK_PATHNAME,
        promotionType: 'CASHBACK',
        extraClass: 'cashback',
        bannerVersion: 'v1',
        promotionId: 31,
        title: 'promotion.cryptoCashback.banner.bannerContext',
        info: {
          multiLang: false,
          secondBanner: {
            img: require('@/assets/images/banner/crypto_cashback_bonus/activity_page/banner_bg.png'),
            imgSmall: require('@/assets/images/banner/crypto_cashback_bonus/activity_page/banner_bg_sm.png'),
            imgLarge: require('@/assets/images/banner/crypto_cashback_bonus/activity_page/banner_bg_lg.png'),
            title: 'promotion.cryptoCashback.banner.activityBannerContext',
            extraClass: 'cashback-act',
            bannerVersion: 'v1',
          },
        },
      },
      {
        img: require('@/assets/images/banner/xmas_make_a_wish/banner_bg.png'),
        imgSmall: require('@/assets/images/banner/xmas_make_a_wish/banner_bg_sm.png'),
        imgLarge: require('@/assets/images/banner/xmas_make_a_wish/banner_bg_lg.png'),
        bannerUrl: XMAS_MAKE_A_WISH_PATHNAME,
        promotionType: 'LUCKY_DRAW',
        extraClass: 'xmasMakeAWish',
        bannerVersion: 'v1',
        promotionId: 34,
        title: 'promotion.xmasMakeAWish.banner.bannerContext',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/millon_dollar_cash_pool/bg.png'),
        imgSmall: require('@/assets/images/banner/millon_dollar_cash_pool/bg_sm.png'),
        backgroundPosition: 'right',
        leftSideImg: {
          img: require('@/assets/images/banner/millon_dollar_cash_pool/promo_img.png'),
          imgSmall: require('@/assets/images/banner/millon_dollar_cash_pool/promo_img_sm.png'),
          breakpoint: 601,
        },
        bannerUrl: MILLION_DOLLAR_CASH_POOL_PATHNAME,
        promotionType: 'MILLION_DOLLAR_CASH_POOL',
        promotionName: 'millionDollarCashPool',
        bannerVersion: 'v2',
        promotionId: 1000,
        openResize: true,
        title: 'promotion.millionDollarCashPool.banner.bannerContext',
        info: {
          multiLang: false,
        },
      },
      {
        bgClass: 'bg-future-image',
        rightSideImg: {
          img: require('@/assets/images/banner/first_deposit_cashback/right.png'),
        },
        leftSideImg: {
          img: require('@/assets/images/banner/first_deposit_cashback/left.png'),
        },
        title: 'promotion.firstDepositCashback.banner.title',
        bannerUrl: FIRST_DEPOSIT_CASHBACK_PATHNAME,
        promotionName: 'firstDepositCashback',
        promotionType: 'FIRST_TIME_DEPOSIT_BONUS_CASHBACK',
        promotionId: 40,
        openResize: true,
      },
      {
        img: require('@/assets/images/banner/first_time_deposit/banner_bg.png'),
        imgLarge: require('@/assets/images/banner/first_time_deposit/banner_bg_large.png'),
        imgMid: require('@/assets/images/banner/first_time_deposit/banner_bg_mid.png'),
        imgSmall: require('@/assets/images/banner/first_time_deposit/banner_bg_small.png'),
        rightSideImg: {
          img: require('@/assets/images/banner/first_time_deposit/person.png'),
          imgSmall: require('@/assets/images/banner/first_time_deposit/person_small.png'),
          breakpoint: 735,
        },
        bannerUrl: FIRST_TIME_DEPOSIT_PATHNAME,
        promotionType: 'FIRST_TIME_DEPOSIT',
        promotionName: 'firstTimeDeposit',
        bannerVersion: 'v2',
        promotionId: 36,
        openResize: true,
        title: 'promotion.firstTimeDeposit.banner.bannerContext',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/rugby/banner_bg.png'),
        imgLarge: require('@/assets/images/banner/rugby/banner_bg_lg.png'),
        imgSmall: require('@/assets/images/banner/rugby/banner_bg_sm.png'),
        bannerUrl: RUGBY_PATHNAME,
        promotionType: 'RUGBY',
        promotionName: 'rugby',
        bannerVersion: 'v1',
        promotionId: 1001,
        openResize: true,
        title: '',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/xmas_spin_and_win/banner_bg.png'),
        imgLarge: require('@/assets/images/banner/xmas_spin_and_win/banner_bg_lg.png'),
        imgSmall: require('@/assets/images/banner/xmas_spin_and_win/banner_bg_sm.png'),
        bannerUrl: XMAS_SPIN_AND_WIN_PATHNAME,
        promotionType: 'XMASSPINANDWIN',
        promotionName: 'xmasSpinAndWin',
        bannerVersion: 'v1',
        promotionId: 1002, // temporary promo id
        openResize: true,
        title: '',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/nfp/banner_bg.png'),
        imgLarge: require('@/assets/images/banner/nfp/banner_bg_xl.png'),
        imgMedium: require('@/assets/images/banner/nfp/banner_bg_lg.png'),
        imgSmall: require('@/assets/images/banner/nfp/banner_bg_sm.png'),
        bannerUrl: NFP_PATHNAME,
        promotionType: 'NFP',
        promotionName: 'nfp',
        bannerVersion: 'v1',
        promotionId: 1003, // temporary promo id
        openResize: true,
        title: '',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/vday_promo/banner_bg.png'),
        imgLarge: require('@/assets/images/banner/vday_promo/banner_bg_lg.png'),
        imgSmall: require('@/assets/images/banner/vday_promo/banner_bg_sm.png'),
        bannerUrl: VDAY_PROMO_PATHNAME,
        promotionType: 'VDAYPROMO',
        promotionName: 'vdayPromo',
        bannerVersion: 'v1',
        promotionId: 1004, // temporary promo id
        openResize: true,
        title: '',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/euro2024/banner_bg.png'),
        imgLarge: require('@/assets/images/banner/euro2024/banner_bg_lg.png'),
        imgSmall: require('@/assets/images/banner/euro2024/banner_bg_sm.png'),
        bannerUrl: EURO2024_PATHNAME,
        promotionType: 'EURO2024',
        promotionName: 'euro2024',
        bannerVersion: 'v1',
        promotionId: 1005, // temporary promo id
        openResize: true,
        title: '',
        info: {
          multiLang: false,
        },
      },
      {
        img: require('@/assets/images/banner/olympic2024/banner_bg.png'),
        imgLarge: require('@/assets/images/banner/olympic2024/banner_bg_lg.png'),
        imgSmall: require('@/assets/images/banner/olympic2024/banner_bg_sm.png'),
        bannerUrl: OLYMPIC2024_PATHNAME,
        promotionType: 'OLYMPIC2024',
        promotionName: 'olympic2024',
        bannerVersion: 'v1',
        promotionId: 1006, // temporary promo id
        openResize: true,
        title: '',
        info: {
          multiLang: false,
        },
      },
    ]
  },
  internationalBankTransfer: {
    DEPOSIT: {
      ALLOW_ALL: false,
      WHITELIST: WHITELIST,
    },
  },
  restrictCreditCardCountryList: ['3589'], //Pakistan
  registerId3Countries: [],
  registerId3NeedAdditionalForm: [countryCodeEnum.UNITED_KINGDOM, countryCodeEnum.FRANCE],
}
