<template>
  <Cima ref="regulator" :countries="countries"></Cima>
</template>

<script>
import Cima from '@/components/template/register/userAddress/Cima';

export default {
  components: { Cima },
  computed: {
    countries() {
      return this.$store.state.register.countries.filter(country => country.id !== 6645); //exclude singapore
    }
  }
};
</script>
