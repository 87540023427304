<template>
  <InternationalDefaultBankInfo
    :isShow="isShow"
    :currency="currency"
    :type="type"
    :selectedAccount="selectedAccount"
  ></InternationalDefaultBankInfo>
</template>

<script>
import InternationalDefaultBankInfo from '@/components/template/deposit/InternationalDefaultBankInfo';

export default {
  name: 'InternationalBankInfo',
  props: { isShow: Boolean, currency: String, type: String, selectedAccount: String },
  components: { InternationalDefaultBankInfo }
};
</script>
