<template>
  <li
    @click="cpsClick(payment)"
    :data-testid="
      convertDataTestId(
        payment.merchant_variable.label ? payment.merchant_variable.label : payment.merchant_variable.description_title
      )
    "
  >
    <div class="top title">
      {{
        payment.merchant_variable.label
          ? langTranslate('deposit.default.channels.', payment.merchant_variable.label)
          : langTranslate('deposit.default.channels.', payment.merchant_variable.description_title)
      }}
    </div>
    <div class="info">
      <div class="logo" :class="paymentLogo(payment.payment_method, payment.actual_currency_code)"></div>
      <div class="right">
        <div class="span">
          <div v-if="payment.merchant_variable.fee">
            {{ payment.merchant_variable.fee }} {{ $t('deposit.default.fee') }} <br />
          </div>
          {{ langTranslate('deposit.time.', payment.merchant_variable.processing_time) }}
        </div>
        <div class="more"><div></div></div>
      </div>
    </div>
  </li>
</template>

<script>
import cpsMixin from '@/mixins/page/deposit/cpsDeposit';

export default {
  name: 'DepositListing',
  mixins: [cpsMixin],
  props: ['payment'],
  methods: {
    cpsClick(payment) {
      payment.attach_variable = payment.attach_variable ? payment.attach_variable : '';
      payment.merchant_variable = payment.merchant_variable ? payment.merchant_variable : '';
      this.$store.dispatch('cps/actionSetPayment', payment);

      let paramsLabel = payment.merchant_variable?.label
        ? this.langTranslate('deposit.default.channels.', this.paymentDetails.merchant_variable?.label)
        : this.langTranslate('deposit.default.channels.', this.paymentDetails.merchant_variable?.description_title);
      this.$router.push({ name: 'cps', params: { name: paramsLabel } });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/deposit/depositList.scss';
</style>
