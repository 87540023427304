// global variable
import { PROMOTION_TYPE } from '@/constants/promotion'
// global function
import { accountTypeMaps } from '@/config'
// services
import { apiCampaignInfo } from '@/resource'
import { isSuccess } from '@/util/restApi'

const state = () => ({
  participantStatus: 'S', //  'S': not yet participate, 'E': participate in, 'DP': has first deposit, 'RE': can redeem, 'I': FTD but < $200
  participateAccount: null, // formatted participate account from api data
  cumulativeLot: 0, // accumulate lots
  voucher: 0, // FTD voucher to get
  callbackErrorMsg: '',
})

const mutations = {
  UPDATE_PARTICIPANT_STATUS: (state, payload) => (state.participantStatus = payload),
  SET_PARTICIPATE_ACCOUNT: (state, payload) => (state.participateAccount = payload),
  UPDATE_CUMULATIVELOT: (state, payload) => (state.cumulativeLot = payload),
  UPDATE_VOUCHER: (state, payload) => (state.voucher = payload),
  SET_CALLBACK_ERROR_MSG: (state, payload) => (state.callbackErrorMsg = payload),
}

const actions = {
  actionFetchCampaignInfo: async ({ commit, getters }) => {
    if (!getters.campaignID) return;

    try {
      const { data: apiData } = await apiCampaignInfo(getters.campaignID)
      if (isSuccess(apiData)) {
        const { participantStatus: status, cumulativeLot, voucher, accountMt4Dto } = apiData.data

        commit('UPDATE_PARTICIPANT_STATUS', status || null)
        if (accountMt4Dto) {
          commit('SET_PARTICIPATE_ACCOUNT', { ...accountMt4Dto })
        }

        commit('UPDATE_CUMULATIVELOT', Number(cumulativeLot) || 0);
        commit('UPDATE_VOUCHER', Number(voucher) || 0)
      }
    } catch (error) {
      commit('SET_CALLBACK_ERROR_MSG', error)
    }
  }
}

const getters = {
  campaignID: (state, getters, rootState) => {
    let campaign =
      rootState.promotion.eligibleCampaigns.find(c => c.campaignType === PROMOTION_TYPE.FIRST_TIME_DEPOSIT) || {}

    return campaign.id ? campaign.id : null
  },
  getFormatParticipateAccount: state => {
    if (state.participateAccount && Object.keys(state.participateAccount).length) {
      const { mt4Account, currency, accountDisplayType } = state.participateAccount
      let accountType = accountTypeMaps[Number(accountDisplayType)] || null
      return accountType ? `${mt4Account} - ${accountType} - ${currency}` : `${mt4Account} - ${currency}`
    }
    return ''
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
