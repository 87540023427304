import {
  DEPOSIT_BONUS_PATHNAME,
  DYNAMIC_DEPOSIT_BONUS_PATHNAME,
  NO_DEPOSIT_BONUS_3_PATHNAME,
  REFER_A_FRIEND_PATHNAME,
  TRADING_BONUS_PATHNAME,
  CASHBACK_PATHNAME,
  DEPOSIT_20_BONUS_PATHNAME,
  XMAS_MAKE_A_WISH_PATHNAME,
  FIRST_DEPOSIT_CASHBACK_PATHNAME,
  MILLION_DOLLAR_CASH_POOL_PATHNAME,
  FIRST_TIME_DEPOSIT_PATHNAME,
  RUGBY_PATHNAME,
  XMAS_SPIN_AND_WIN_PATHNAME,
  NFP_PATHNAME,
  EURO2024_PATHNAME,
  OLYMPIC2024_PATHNAME,
  REGISTERROUTER,
  DEPOSIT_MY_20_BONUS_PATHNAME,
  PROP_TRADING,
  PROP_TRADING_ACHIEVEMENT,
  VDAY_PROMO_PATHNAME,
  TRADING_CONTEST_PATHNAME,
  FUNDS_GROWTH_PROMO_PATHNAME,
} from '@/constants/route'
import Vue from 'vue'
import VueRouter from 'vue-router'
import identity from './identity'
import store from '@/store/index'
import { PROMOTION_TYPE } from '@/constants/promotion'
import { isSuccess } from '@/util/restApi'
import { checkRegisterPageCanAudit, generalRegisterDirectPage, registerFinanicalStep } from '@/util/register'
import { statusConfig } from '@/constants/status'
import { WHITELIST, WHITELIST2 } from '@/constants/internationalBankTransfer'
import { countryCodeEnum } from '@/constants/country'

Vue.use(VueRouter)

function canParticipate(CAMPAIGN_TYPE) {
  //https://stackoverflow.com/questions/8217419/how-to-determine-if-javascript-array-contains-an-object-with-an-attribute-that-e
  //https://stackoverflow.com/questions/42603909/accessing-vuex-state-when-defining-vue-router-routes
  return (
    store.state.promotion.eligibleCampaigns.filter(campaign => {
      return campaign.campaignType === CAMPAIGN_TYPE
    }).length > 0
  )
}

async function verifyPropTradingDashboardEligibility() {
  let { account, eligible, session } = store.state.propTrading

  if (eligible && (session || account)) {
    return true
  }

  const result = await store.dispatch('propTrading/actionGetEligiblePropTrading')

  if (isSuccess(result.data)) {
    ;({ account, eligible, session } = store.state.propTrading)
  }

  if (eligible && (session || account)) {
    return true
  }

  return false
}

async function verifyPropTradingEligibility() {
  let { eligible } = store.state.propTrading

  if (eligible) {
    return true
  }

  const result = await store.dispatch('propTrading/actionGetEligiblePropTrading')

  if (isSuccess(result.data)) {
    ;({ eligible } = store.state.propTrading)
  }

  if (eligible) {
    return true
  }

  return false
}
async function financialVerificationMiddleware(to, from, next) {
  // TODO: inhibit user to entry IBT series pages if country code is not in white list
  // const currentCountryCode = store.state.common.countryCode
  // const specialAllowToIBTCountries = [countryCodeEnum.QATAR.toString(), countryCodeEnum.INDIA.toString()]
  // const allowToIBTCountries = store.state.common.regulator === 'fsa' ? WHITELIST : WHITELIST2;
  // if(!allowToIBTCountries[currentCountryCode] && !specialAllowToIBTCountries.includes(currentCountryCode) ) {
  //   return next({ name: 'depositFunds' })
  // }

  const accountAuditStatus = store.state.register.accountAuditStatus
  const poiAuditStatus = store.state.register.poiAuditStatus
  const poaAuditStatus = store.state.register.poaAuditStatus
  const ibtPoiAuditStatus = store.state.register.ibtPoiAuditStatus
  const ibtPoaAuditStatus = store.state.register.ibtPoaAuditStatus

  const isFirstTimeIBT = ibtPoiAuditStatus === statusConfig.init && ibtPoaAuditStatus === statusConfig.init

  if (accountAuditStatus === statusConfig.init) {
    return next({ name: 'register' })
  }

  // if user first does IBT verification and had not submitted,
  // refresh 'iDProof' & 'addressProof' page should direct to 'financialInfo'
  if (isFirstTimeIBT && (to.name === 'iDProof' || to.name === 'addressProof') && !Object.keys(to.params)?.length) {
    return next({ name: 'financialInfo' })
  }

  switch (to.name) {
    case 'financialInfo':
      return isFirstTimeIBT ? next() : next({ name: 'register' })
    case 'iDProof':
      return checkRegisterPageCanAudit(ibtPoiAuditStatus) ? next() : next({ name: 'register' })
    case 'addressProof':
      return checkRegisterPageCanAudit(ibtPoaAuditStatus) ? next() : next({ name: 'register' })
    default:
      return next()
  }
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          path: '/',
          name: '/',
          meta: {
            noShowLoading: true,
          },
        },
        {
          path: '/logout',
          name: 'logout',
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
            restrictBanner: true,
          },
        },
        // temporarily not remove old register
        // {
        //   path: '/register',
        //   name: 'register',
        //   component: () => import('@/views/register/Register'),
        //   meta: {
        //     restrictBanner: true,
        //   },
        // },
        // {
        //   path: '/register',
        //   name: 'registration',
        //   component: () => import('@/views/register/Registration'),
        //   meta: {
        //     restrictSideBar: true,
        //   },
        // },
        {
          path: '/propTrading',
          component: () => import('@/views/propTrading/Index'),
          children: [
            {
              name: 'propTrading',
              path: '',
              redirect: () => ({ name: 'propTradingDashboard' }),
            },
            {
              name: 'propTradingCreateAccount',
              path: PROP_TRADING,
              component: () => import('@/views/propTrading/ChildView'),
              meta: {
                restrictBanner: true,
                restrictSideBar: true,
              },
              beforeEnter: async (to, from, next) => {
                const isEligibleForPropTrading = await verifyPropTradingEligibility()

                if (isEligibleForPropTrading) {
                  return next()
                }

                return next({ name: from.name ?? 'home' })
              },
            },
            {
              name: 'propTradingAchievement',
              path: PROP_TRADING_ACHIEVEMENT,
              component: () => import('@/views/propTrading/ChildView'),
              meta: {
                restrictBanner: true,
              },
              beforeEnter: async (to, from, next) => {
                const isEligibleForPropTrading = await verifyPropTradingEligibility()

                if (isEligibleForPropTrading) {
                  return next()
                }

                return next({ name: from.name ?? 'home' })
              },
            },
            {
              name: 'propTradingDashboard',
              path: '/propTrading/dashboard',
              component: () => import('@/views/propTrading/ChildView'),
              meta: {
                restrictBanner: true,
              },
              beforeEnter: async (to, from, next) => {
                const isEligibleForPropTrading = await verifyPropTradingDashboardEligibility()

                if (isEligibleForPropTrading) {
                  return next()
                }

                return next({ path: from.fullPath })
              },
            },
            {
              name: 'propTradingMetricsIndex',
              path: '/propTrading/metrics',
              component: () => import('@/views/propTrading/ChildView'),
              meta: {
                restrictBanner: true,
              },
              redirect: () => ({ name: 'propTradingDashboard' }),
            },
            {
              name: 'propTradingMetrics',
              path: '/propTrading/metrics/:accountNumber',
              component: () => import('@/views/propTrading/ChildView'),
              meta: {
                restrictBanner: true,
              },
            },
            {
              name: 'propTradingPayout',
              path: '/propTrading/payout/:accountNumber',
              component: () => import('@/views/propTrading/ChildView'),
              meta: {
                restrictBanner: true,
              },
            },
          ],
        },
        {
          path: REGISTERROUTER.REGISTER,
          name: 'register',
          component: () => import('@/views/register/Step'),
        },
        {
          path: REGISTERROUTER.ACC_OPENING_VERIFICATION,
          component: () => import('@/views/register/accOpeningVerification/Index'),
          redirect: () => {
            return {
              name: 'personalInformation',
            }
          },
          children: [
            {
              path: REGISTERROUTER.PERSONAL_INFORMATION,
              name: 'personalInformation',
              component: () => import('@/views/register/accOpeningVerification/ChildView'),
              meta: {
                restrictBanner: true,
                restrictSideBar: true,
              },
            },
            {
              path: REGISTERROUTER.CREATE_LIVE_ACCOUNT,
              name: 'createLiveAccount',
              component: () => import('@/views/register/accOpeningVerification/ChildView'),
              meta: {
                restrictBanner: true,
                restrictSideBar: true,
              },
            },
          ],
        },
        {
          path: REGISTERROUTER.IDENTITY_VERIFICATION,
          component: () => import('@/views/register/identityVerification/Index'),
          redirect: () => {
            return {
              name: 'identityVerification',
            }
          },
          children: [
            {
              path: '',
              name: 'identityVerification',
              component: () => import('@/views/register/identityVerification/ChildView'),
              meta: {
                restrictBanner: true,
                restrictSideBar: true,
              },
            },
          ],
        },
        {
          path: REGISTERROUTER.POA_VERIFICATION,
          component: () => import('@/views/register/poaVerification/Index'),
          redirect: () => {
            return {
              name: 'poaVerification',
            }
          },
          children: [
            {
              path: '',
              name: 'poaVerification',
              component: () => import('@/views/register/poaVerification/ChildView'),
              meta: {
                restrictBanner: true,
                restrictSideBar: true,
              },
            },
          ],
        },
        {
          path: REGISTERROUTER.FINANCIAL_VERIFICATION,
          component: () => import('@/views/register/financialVerification/Index'),
          redirect: () => {
            return {
              name: 'financialInfo',
            }
          },
          children: [
            {
              path: REGISTERROUTER.FINANCIAL_INFO,
              name: 'financialInfo',
              component: () => import('@/views/register/financialVerification/ChildView'),
              meta: {
                restrictBanner: true,
                restrictSideBar: true,
              },
              beforeEnter: financialVerificationMiddleware,
            },
            {
              path: REGISTERROUTER.ID_PROOF,
              name: 'iDProof',
              component: () => import('@/views/register/financialVerification/ChildView'),
              meta: {
                restrictBanner: true,
                restrictSideBar: true,
              },
              beforeEnter: financialVerificationMiddleware,
            },
            {
              path: REGISTERROUTER.ADDRESS_PROOF,
              name: 'addressProof',
              component: () => import('@/views/register/financialVerification/ChildView'),
              meta: {
                restrictBanner: true,
                restrictSideBar: true,
              },
              beforeEnter: financialVerificationMiddleware,
            },
          ],
        },
        {
          path: '/demo',
          name: 'demo',
          component: () => import('@/views/register/Demo'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: '/authority',
          name: 'authority',
          component: () => import('@/views/register/Authority'),
        },
        {
          path: '/registerFinish',
          name: 'registerFinish',
          component: () => import('@/views/register/RegisterFinish'),
        },
        {
          path: '/home',
          name: 'home',
          component: () => import('@/views/Home'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/homeDemo',
          name: 'homeDemo',
          component: () => import('@/views/HomeDemo'),
        },
        {
          path: '/liveAccount',
          name: 'liveAccount',
          component: () => import('@/views/Home'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/openLiveAccount',
          name: 'openLiveAccount',
          component: () => import('@/views/OpenLiveAccount'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/openDemoAccount',
          name: 'openDemoAccount',
          component: () => import('@/views/OpenDemoAccount'),
        },
        {
          path: '/depositFunds',
          name: 'depositFunds',
          component: () => import(`@/views/deposit/DepositFunds`),
          meta: {
            authority: true,
          },
        },
        // temporary for testing
        {
          path: '/depositFundsCps',
          name: 'depositFunds',
          component: () => import(`@/views/deposit/DepositFundsCps`),
          meta: {
            authority: true,
          },
        },
        {
          path: '/withdrawFunds',
          name: 'withdrawFunds',
          component: () => import('@/views/withdraw/WithdrawFunds'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/transferFunds',
          name: 'transferFunds',
          component: () => import('@/views/TransferFunds'),
          meta: {
            authority: true,
          },
        },
        {
          path: '/transactionHistory',
          name: 'transactionHistory',
          component: () => import('@/views/TransactionHistory'),
          meta: {
            authority: true,
          },
        },
        {
          path: NO_DEPOSIT_BONUS_3_PATHNAME + ':campaignId',
          name: 'noDepositBonus',
          component: () => import('@/views/promotion/dynamicNoDepositBonus'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: REFER_A_FRIEND_PATHNAME,
          name: 'referFriends',
          component: () => import('@/views/promotion/ReferFriends'),
          meta: {
            restrictBanner: true,
          },
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.REFER_A_FRIEND)) next()
            else next({ path: from.fullPath })
          },
        },

        {
          path: DYNAMIC_DEPOSIT_BONUS_PATHNAME + ':campaignId',
          name: 'dynamicDepositBonus',
          component: () => import('@/views/promotion/DynamicDepositBonus'),
          meta: {
            restrictBanner: true,
            authority: true,
          },
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.DEPOSIT_BONUS)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: DEPOSIT_20_BONUS_PATHNAME,
          name: 'deposit20Bonus',
          component: () => import('@/views/promotion/DepositBonus'),
          meta: {
            restrictBanner: true,
            authority: true,
          },
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.DEPOSIT_BONUS)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: DEPOSIT_MY_20_BONUS_PATHNAME,
          name: 'depositMY20Bonus',
          component: () => import('@/views/promotion/DepositBonus'),
          meta: {
            restrictBanner: true,
          },
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.DEPOSIT_BONUS)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: TRADING_BONUS_PATHNAME,
          name: 'tradingBonus',
          component: () => import('@/views/promotion/TradingBonus'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.TRADING_BONUS)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: CASHBACK_PATHNAME,
          name: 'cryptoCashback',
          component: () => import('@/views/promotion/CryptoCashback'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.CASHBACK)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: XMAS_MAKE_A_WISH_PATHNAME,
          name: 'xmasMakeAWish',
          component: () => import('@/views/promotion/XmasMakeAWish'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.XMASMAKEAWISH)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: FIRST_DEPOSIT_CASHBACK_PATHNAME,
          name: 'firstDepositCashback',
          component: () => import('@/views/promotion/FirstDepositCashback'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.FIRST_DEPOSIT_CASHBACK)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: MILLION_DOLLAR_CASH_POOL_PATHNAME,
          name: 'millionDollarCashPool',
          component: () => import('@/views/promotion/MillionDollarCashPool'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.MILLION_DOLLAR_CASH_POOL)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: NFP_PATHNAME,
          name: 'nfp',
          component: () => import('@/views/promotion/NfpPromotion'),
          meta: {
            restrictBanner: true,
          },
          props: true,
        },
        // {
        //   path: RUGBY_PATHNAME,
        //   name: 'rugby',
        //   component: () => import('@/views/promotion/Rugby'),
        //   meta: {
        //     restrictBanner: true,
        //   },
        //   props: true,
        //   beforeEnter: (to, from, next) => {
        //     if (canParticipate(PROMOTION_TYPE.RUGBY)) next()
        //     else next({ path: from.fullPath })
        //   },
        // },
        {
          path: NFP_PATHNAME,
          name: 'nfp',
          component: () => import('@/views/promotion/NfpPromotion.vue'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.NFP)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: EURO2024_PATHNAME,
          name: 'euro2024',
          component: () => import(`@/views/promotion/Euro2024`),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.EURO2024)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: OLYMPIC2024_PATHNAME,
          name: 'olympic2024',
          component: () => import(`@/views/promotion/Olympic2024`),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.OLYMPIC2024)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: XMAS_SPIN_AND_WIN_PATHNAME,
          name: 'xmasSpinAndWin',
          component: () => import('@/views/promotion/XmasSpinAndWin.vue'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.XMAS_SPIN_AND_WIN)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: VDAY_PROMO_PATHNAME,
          name: 'vdayPromo',
          component: () => import('@/views/promotion/VdayPromo.vue'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.VDAY_PROMO)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: TRADING_CONTEST_PATHNAME + ':campaignId',
          name: 'tradingContest',
          component: () => import('@/views/promotion/TradingContest.vue'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.TRADING_CONTEST)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: FUNDS_GROWTH_PROMO_PATHNAME,
          name: 'fundsGrowthPromotion',
          component: () => import('@/views/promotion/FundsGrowthPromotion.vue'),
          meta: {
            restrictBanner: true,
          },
          props: true,
          beforeEnter: (to, from, next) => {
            if (canParticipate(PROMOTION_TYPE.FUNDS_GROWTH_PROMO)) next()
            else next({ path: from.fullPath })
          },
        },
        {
          path: '/downloads',
          name: 'downloads',
          component: () => import('@/views/Downloads'),
        },
        {
          path: '/myProfile',
          name: 'myProfile',
          component: () => import('@/views/MyProfile'),
        },
        // {
        //   path: '/changePassword',
        //   name: 'changePassword',
        //   component: () => import('@/views/ChangePassword'),
        // },
        {
          path: '/securityManagement',
          name: 'securityManagement',
          component: () => import('@/views/SecurityManagement'),
          meta: {
            restrictBanner: true,
          },
        },
        {
          path: '/contactUs',
          name: 'contactUs',
          component: () => import(`@/views/ContactUs`),
        },
        {
          path: '/paymentDetails',
          name: 'paymentDetails',
          component: () => import('@/views/PaymentDetails'),
          meta: { authority: true },
        },
        {
          path: '/autochartist',
          name: 'autochartist',
          component: () => import('@/views/Autochartist'),
        },
        // pages of thirdParty
        {
          path: '/fasapay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/neteller/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/neteller/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paysafe_neteller/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/skrill/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/skrill/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytrust/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paywize/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/bitwallet/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/kr_df/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytrust/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/zotapay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/paytoday/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/unionpay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cps/thirdPartyDepositResult',
          name: 'cpsThirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/tinkbit/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/creditcard/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/apg/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/sd/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/crypto/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/crypto/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cryptousdt/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/cryptousdt/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/stic/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/stic/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/xpay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/trustly/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
            restrictApi: true,
            noShowLoading: true,
          },
        },
        {
          path: '/trustly/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
            restrictApi: true,
            noShowLoading: true,
          },
        },
        {
          path: '/astropay/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/astropay/thirdPartyDepositFailed',
          name: 'thirdPartyDepositFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/solid/thirdPartyDepositResult',
          name: 'thirdPartyDepositResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/thirdPartyDepositResult/:id',
          name: 'thirdPartyResult',
          component: () => import('@/views/callback/ThirdPartyDepositResult'),
          meta: {
            restrictReload: true,
          },
        },
        {
          path: '/thirdPartyDepositFailed/:id',
          name: 'thirdPartyFailed',
          component: () => import('@/views/callback/ThirdPartyDepositFailed'),
          meta: {
            restrictReload: true,
          },
        },
        // pages of deposit
        {
          path: '/deposit/cps/:name',
          name: 'cps',
          component: () => import('@/views/deposit/cps/Index'),
          beforeEnter: async (to, from, next) => {
            // TODO: if International Bank Trnasfer switch to CPS, needs to add the logic
            // if (to.params.name === 'internationalSwift') {
            //   const redirectPath = generalRegisterDirectPage({ isIBT: true })
            //   if (redirectPath?.length > 0) {
            //     next({ name: redirectPath })
            //   }
            // }
            next()
          },
        },
        {
          path: '/deposit/cpsIndex/:name',
          name: 'cpsIndex',
          component: () => import('@/views/deposit/cps/IndexCps'),
        },
        {
          path: '/deposit/apg',
          name: 'apgDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/ApgPay'),
        },
        {
          path: '/deposit/sdPay',
          name: 'sdPayDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/Sdpayment'),
        },
        {
          path: '/deposit/virtualPay',
          name: 'virtualPayDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/VirtualPay'),
        },
        {
          path: '/deposit/solidpayment',
          name: 'solidpayment',
          component: () => import('@/views/deposit/CreditOrDebit/Solidpayment'),
        },
        {
          path: '/deposit/appleSolidpay',
          name: 'appleSolidpay',
          component: () => import('@/views/deposit/CreditOrDebit/AppleSolidpay'),
        },
        {
          path: '/deposit/worldcard',
          name: 'worldcardDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/Worldcard'),
        },
        // {
        //   path: '/deposit/maxispay',
        //   name: 'maxisPayDeposit',
        //   component: () => import('@/views/deposit/CreditOrDebit/Maxispayment'),
        // },
        // {
        //   path: '/deposit/bitwalletDeposit',
        //   name: 'bitwalletDeposit',
        //   component: () => import('@/views/deposit/BitwalletDeposit'),
        // },
        {
          path: '/deposit/bridgerPay',
          name: 'bridgerPayDeposit',
          component: () => import('@/views/deposit/CreditOrDebit/BridgerPay'),
        },
        {
          path: '/deposit/dragonPhoenixDeposit',
          name: 'dragonPhoenixDeposit',
          component: () => import('@/views/deposit/DragonPhoenixDeposit'),
        },
        // {
        //   path: '/deposit/ebuyDeposit',
        //   name: 'ebuyDeposit',
        //   component: () => import('@/views/deposit/ebuyDeposit'),
        // },
        // {
        //   path: '/deposit/fasaPay',
        //   name: 'fasaPay',
        //   component: () => import('@/views/deposit/FasaPay'),
        // },
        {
          path: '/deposit/internationalSwift',
          name: 'internationalSwift',
          component: () => import('@/views/deposit/international/InternationalSwift'),
          beforeEnter: async (to, from, next) => {
            if (to.name === 'internationalSwift') {
              const redirectPath = generalRegisterDirectPage({ isIBT: true })
              if (redirectPath?.length > 0) {
                next({ name: redirectPath })
              } else {
                next()
              }
            }
          },
        },
        {
          path: '/deposit/internationalEqualsMoney',
          name: 'internationalEqualsMoney',
          component: () => import('@/views/deposit/international/EqualsMoney'),
          beforeEnter: async (to, from, next) => {
            if (to.name === 'internationalEqualsMoney') {
              const redirectPath = generalRegisterDirectPage({ isIBT: true })
              if (redirectPath?.length > 0) {
                next({ name: redirectPath })
              } else {
                next()
              }
            }
          },
        },
        // {
        //   path: '/deposit/canadaLocalBankDeposit',
        //   name: 'canadaLocalBank',
        //   component: () => import('@/views/deposit/CanadaLocalBankDeposit'),
        // },
        // {
        //   path: '/deposit/mobilePay',
        //   name: 'mobilePay',
        //   component: () => import('@/views/deposit/MobilePay'),
        // },
        // {
        //   path: '/deposit/sticPay',
        //   name: 'sticPay',
        //   component: () => import('@/views/deposit/SticPay'),
        // },
        // {
        //   path: '/deposit/skrillPay',
        //   name: 'skrillPay',
        //   component: () => import('@/views/deposit/SkrillPay'),
        // },
        {
          path: '/deposit/unionPay',
          name: 'unionPay',
          component: () => import('@/views/deposit/UnionPay'),
        },
        // {
        //   path: '/deposit/unionPayChina',
        //   name: 'unionPayChina',
        //   component: () => import('@/views/deposit/UnionPayChina'),
        // },
        // {
        //   path: '/deposit/thailandZotaPay/:id',
        //   name: 'thailandZotaPay',
        //   component: () => import('@/views/deposit/ThailandZotapay'),
        // },
        // {
        //   path: '/deposit/thailandXpay',
        //   name: 'thailandXpay',
        //   component: () => import('@/views/deposit/thailand/Xpay'),
        // },
        // {
        //   path: '/deposit/nigeriaZotapay',
        //   name: 'nigeriaZotapay',
        //   component: () => import('@/views/deposit/NigeriaZotapay'),
        // },
        {
          path: '/deposit/Paystack',
          name: 'paystack',
          component: () => import('@/views/deposit/Paystack'),
        },
        // {
        //   path: '/deposit/vietnamXpay',
        //   name: 'vietnamXpay',
        //   component: () => import(`@/views/deposit/vietnam/Xpay`),
        // },
        // {
        //   path: '/deposit/vietnamZotapay',
        //   name: 'vietnamZotapay',
        //   component: () => import(`@/views/deposit/vietnam/Zotapay`),
        // },
        // {
        //   path: '/deposit/malaysiaPaytrust',
        //   name: 'malaysiaPaytrust',
        //   component: () => import(`@/views/deposit/malaysia/PayTrust`),
        // },
        // {
        //   path: '/deposit/malaysiaZotapay',
        //   name: 'malaysiaZotapay',
        //   component: () => import(`@/views/deposit/malaysia/Zotapay`),
        // },
        // {
        //   path: '/deposit/malaysiaXpay',
        //   name: 'malaysiaXpay',
        //   component: () => import(`@/views/deposit/malaysia/Xpay`),
        // },
        // {
        //   path: '/deposit/philipinessPayment',
        //   name: 'philipinessPayment',
        //   component: () => import(`@/views/deposit/PhilipinessPayment`),
        // },
        // {
        //   path: '/deposit/indonesiaBankwire',
        //   name: 'indonesiaBankwire',
        //   component: () => import(`@/views/deposit/indonesia/Bankwire`),
        // },
        // {
        //   path: '/deposit/indonesiaXpay',
        //   name: 'indonesiaXpay',
        //   component: () => import(`@/views/deposit/indonesia/Xpay`),
        // },
        // {
        //   path: '/deposit/indonesiaZotapay',
        //   name: 'indonesiaZotapay',
        //   component: () => import(`@/views/deposit/indonesia/Zotapay`),
        // },
        {
          path: '/deposit/indiaZotapay',
          name: 'indiaZotapay',
          component: () => import('@/views/deposit/india/Zotapay'),
        },
        // {
        //   path: '/deposit/bitcoin',
        //   name: 'bitcoin',
        //   component: () => import('@/views/deposit/crypto/Bitcoin'),
        // },
        // {
        //   path: '/deposit/usdt/:id',
        //   name: 'usdt',
        //   component: () => import('@/views/deposit/crypto/USDT'),
        // },
        // {
        //   path: '/deposit/bitcoinCPS',
        //   name: 'bitcoinCPS',
        //   component: () => import('@/views/deposit/crypto/Bitcoin_CPS'),
        // },
        // {
        //   path: '/deposit/OmniCPS',
        //   name: 'omniCPS',
        //   component: () => import('@/views/deposit/crypto/Omni_CPS'),
        // },
        // {
        //   path: '/deposit/usdt_cps',
        //   name: 'usdt_cps',
        //   component: () => import('@/views/deposit/crypto/USDT_CPS'),
        // },
        // {
        //   path: '/deposit/trc20_cps',
        //   name: 'trc20_cps',
        //   component: () => import('@/views/deposit/crypto/TRC20_CPS'),
        // },
        // {
        //   path: '/deposit/astropayDeposit',
        //   name: 'astropayDeposit',
        //   component: () => import('@/views/deposit/AstropayDeposit'),
        // },
        // {
        //   path: '/deposit/southAfricaPayment',
        //   name: 'southAfricaPayment',
        //   component: () => import('@/views/deposit/SouthAfricaPayment'),
        // },
        // {
        //   path: '/deposit/neteller',
        //   name: 'neteller',
        //   component: () => import('@/views/deposit/NetellerDeposit'),
        // },
        // {
        //   path: '/deposit/AfricaZotapay',
        //   name: 'africaZotapay',
        //   component: () => import('@/views/deposit/AfricaZotapay'),
        // },
        // {
        //   path: '/deposit/PerfectMoney',
        //   name: 'perfectMoney',
        //   component: () => import('@/views/deposit/PerfectMoney'),
        // },
        // {
        //   path: '/deposit/IndiaBankTransfer',
        //   name: 'indiaBankTransfer',
        //   component: () => import('@/views/deposit/india/IndiaBankTransfer'),
        // },
        {
          path: '/deposit/NigeriaBankTransfer',
          name: 'nigeriaBankTransfer',
          component: () => import('@/views/deposit/nigeria/NigeriaBankTransfer'),
        },
        // {
        //   path: '/deposit/localdepositor',
        //   name: 'localdepositor',
        //   component: () => import('@/views/deposit/LocalDepositor'),
        // },
        {
          path: '/deposit/iDeal',
          name: 'iDeal',
          component: () => import('@/views/deposit/iDeal'),
        },
        {
          path: '/deposit/Uaelocalbanktransfer',
          name: 'uaelocalbanktransfer',
          component: () => import('@/views/deposit/uae/Uaelocalbanktransfer'),
        },
        {
          path: '/deposit/euroSepa',
          name: 'euroSepa',
          component: () => import('@/views/deposit/EuroSepa'),
        },
      ],
    },
    {
      path: '/',
      component: () => import('@/views/shared/LoginLayout'),
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(`@/views/LoginClient`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/addAuthentication',
          name: 'AddAuthentication',
          component: () => import(`@/views/AddAuthentication`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/verifyAuthentication',
          name: 'VerifyAuthentication',
          component: () => import(`@/views/VerifyAuthentication`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/login/to_login',
          name: 'to_login',
          component: () => import(`@/views/LoginClient`),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
        {
          path: '/forgetPassword',
          name: 'forgetPassword',
          component: () => import(`@/views/ForgetPasswordReq`),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/resetProfilePassword',
          name: 'resetProfilePassword',
          component: () => import(`@/views/ResetProfilePassword`),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/resetPassword',
          name: 'resetPassword',
          component: () => import('@/views/ResetPassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        // Close Pt reset password page
        // {
        //   path: '/ptResetPassword',
        //   name: 'ptResetPassword',
        //   component: () => import('@/views/PtResetPassword'),
        //   meta: {
        //     allowAnonymous: true,
        //   },
        // },
        {
          path: '/forceChangePassword',
          name: 'forceChangePassword',
          component: () => import('@/views/ForceChangePassword'),
          meta: {
            allowAnonymous: true,
          },
        },
        {
          path: '/error',
          name: 'error',
          component: () => import('@/views/register/Error'),
          meta: {
            allowAnonymous: true,
            noShowLoading: true,
          },
        },
      ],
    },
    {
      path: '',
      component: () => import('@/views/shared/Layout'),
      children: [
        {
          name: '404',
          path: '*',
          component: () => import('@/views/404'),
        },
      ],
    },
  ],
})

export default router
identity.authorize()
