/**
 * 神策埋点配置
 */
import sensors from './sensorsdata.es6.min.js'
import pageleave from './pageleave.es6.min.js'

export function setupSensorsdata(Vue) {
  sensors.init({
    server_url: getServerUrl(),
    is_track_single_page: true, // 单页面配置，默认开启，若页面中有锚点设计，需要将该配置删除，否则触发锚点会多触发 $pageview 事件
    use_client_time: true,
    send_type: 'beacon',
    show_log: process.env.VUE_APP_ENV_MODE !== 'production',
    heatmap: {
      //是否开启点击图，default 表示开启，自动采集 $WebClick 事件，可以设置 'not_collect' 表示关闭。
      clickmap: 'default',
      //是否开启触达图，not_collect 表示关闭，不会自动采集 $WebStay 事件，可以设置 'default' 表示开启。
      scroll_notice_map: 'not_collect',
    },
  })
  sensorsRegister({
    brand_name: 'PU',
    platform_type: 'Client Portal',
    is_login: false,
  })
  // 用于采集 $WebPageLeave 事件
  sensors.use(pageleave, {
    heartbeat_interval_time: 5,
    max_duration: 5 * 24 * 60 * 60,
  })
  sensors.quick('autoTrack') //用于采集 $pageview 事件。
  Vue.prototype.$sensors = sensors
}

function getServerUrl() {
  const urlMap = {
    development: '',
    test: '',
    production: 'https://data.techmsolution.com/sa?project=PU',
  }
  return urlMap[process.env.VUE_APP_ENV_MODE || 'production']
}

// 注册公共属性
export function sensorsRegister(params) {
  if (params.current_lang) {
    params.current_lang = sensorsdataLanguageMap[params.current_lang] || params.current_lang
  }
  sensors.registerPage(params)
}

// 绑定业务ID
export function sensorsBind(params) {
  if (typeof params === 'object' && typeof params !== 'null') {
    Object.keys(params).forEach(key => {
      sensors.bind(key, params[key])
    })
  }
}

// 神策登录
export function sensorsLogin(value) {
  sensors.login(value)
}

// 神策退出登录
export function sensorsLogout() {
  sensors.logout()
}

/**
 * 绑定用户信息
 * 若同时获取到邮箱、手机号，则先调用bind接口上报$identity_email，再调用login接口上报uid信息
 * 若无法同时获取到邮箱、手机号：
 * 只有邮箱，则调用bind接口上报identity_uid_email和$identity_email
 * 只有手机号，则调用bind接口上报identity_uid_phone
 * @param {*} userId
 * @param {*} eventEmailId 加密后的邮箱
 * @param {*} eventMobileId 加密后的手机号
 */
export function sensorsBindLogin(userId, eventEmailId = '', eventMobileId = '') {
  if (eventEmailId && eventMobileId) {
    sensorsBind({
      $identity_email: `${eventEmailId}`,
    })
    sensorsLogin(`${userId}`)
  } else if (eventEmailId) {
    sensorsBind({
      $identity_email: `${eventEmailId}`,
      identity_uid_email: `${userId}`,
    })
  } else if (eventMobileId) {
    sensorsBind({
      identity_uid_phone: `${userId}`,
    })
  }
}

/**
 * 上报到神策的语言映射
 */
const sensorsdataLanguageMap = {
  en_US: 'en',
  zh_CN: 'hans',
  zh_TW: 'hant',
  fr_FR: 'fr',
  ko: 'ko',
  ja: 'jp',
  th: 'th',
  vi: 'vi',
  es: 'es',
  pt: 'pt',
  de: 'de',
  id: 'id',
  ar: 'ar',
  ru: 'ru',
  mn: 'mn',
  km: 'kh',
  nl: 'nl',
  it: 'it',
  ms: 'ms',
  pl: 'pl',
  tl: 'tl',
  et: 'et',
  kk: 'kk',
  sv: 'sv',
  la: 'la',
  fa: 'fa',
}
