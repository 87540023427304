import i18n from '@/lang/index'

const valiCreditNumberFirst = (rule, value, callback) => {
  var reg = /^[0-9]{6}$/
  if (!reg.test(value)) {
    callback(new Error(i18n.t('common.formValidation.digitsFirst6')))
  } else {
    callback()
  }
}

const valiCreditNumberLast = (rule, value, callback) => {
  var reg = /^[0-9]{4}$/
  if (!reg.test(value)) {
    callback(new Error(i18n.t('common.formValidation.digitsLast4')))
  } else {
    callback()
  }
}

const validateEmail = (rule, value, callback) => {
  const pattern =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (value !== '' && !pattern.test(value)) callback(new Error(i18n.t('common.formValidation.emailFormat')))
  else callback()
}

const validateNumber = (rule, value, callback) => {
  var reg = /^\d+$/
  if (!reg.test(value.toString())) {
    callback(new Error(i18n.t('common.formValidation.numberReq')))
  } else {
    callback()
  }
}

const valiAlphanumeric = (rule, value, callback) => {
  var reg = /^[a-z0-9]+$/i
  if (!reg.test(value.toString())) {
    callback(new Error(i18n.t('common.formValidation.alphanumericReq')))
  } else {
    callback()
  }
}

const validateAlphaAndNumeric = (rule, value, callback) => {
  const reg = /^(?=.*[a-zA-Z])(?=.*[0-9])[A-Za-z0-9]+$/
  if (!reg.test(value.toString())) {
    callback(new Error(i18n.t('common.formValidation.alphanumericReq')))
  } else {
    callback()
  }
}

const checkAgreedTNC = (rule, value, callback) => {
  if (value == false) {
    return callback(new Error(i18n.t('common.formValidation.tnc')))
  }
  return callback()
}

const checkAgreedCopyTradingTNC = (rule, value, callback) => {
  if (value == false) {
    return callback(new Error(i18n.t('common.formValidation.copyTradingTnc')))
  }
  return callback()
}

const checkAgreedCancellation = (rule, value, callback) => {
  if (value == false) {
    return callback(new Error(i18n.t('common.formValidation.cancellation')))
  }
  return callback()
}

const validatePass = (rule, value, callback) => {
  if (value === '') {
    callback(new Error(i18n.t('common.formValidation.newPwReq')))
  } else {
    const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*.()])(?=.*[0-9])(?=.*[a-z]).{8,16}$/
    if (!reg.test(value)) {
      callback(new Error(i18n.t('common.formValidation.newPwReqErr')))
    }
  }
  callback()
}

export {
  valiCreditNumberFirst,
  valiCreditNumberLast,
  validateEmail,
  validateNumber,
  valiAlphanumeric,
  validateAlphaAndNumeric,
  checkAgreedTNC,
  checkAgreedCancellation,
  validatePass,
  checkAgreedCopyTradingTNC
}
