import router from '@/router'
import {
  apiSetlanguage,
  apiTo_logout,
  apiTo_login_by_token,
  apiTo_login_register,
  apiTo_login,
  apiTo_sms_code_login,
} from '@/resource'
import store from '@/store'
import redirect from '@/util/redirect'
import { getRegulator } from '@/util/regulator'
import { MessageBox } from 'element-ui'
import { setCookies } from '@/util/cookies'
import { getRegisterStepByNum, getStatus } from '@/util/register'
import { mapVariable, getToPath, mapVerifiedAuth } from '@/components/security/Security.js'
import { sensorsBindLogin, sensorsLogout, sensorsRegister } from './sensorsdata'

function setUserProfile(resp) {
  const userInfo = resp.data.data
  const poiAuditStatus = getStatus(userInfo.identityProofStatus.poiAuditStatus)
  const poaAuditStatus = getStatus(userInfo.identityProofStatus.poaAuditStatus)
  const ibtPoiAuditStatus = getStatus(userInfo.identityProofStatus.ibtPoiAuditStatus)
  const ibtPoaAuditStatus = getStatus(userInfo.identityProofStatus.ibtPoaAuditStatus)

  store.commit('common/setLoginStatus', true)
  store.commit('common/setUserName', userInfo.userName)
  store.commit('common/setIndividualName', userInfo.userName.split('&')[0].trim())
  store.commit('common/setUserType', userInfo.userType)
  store.commit('common/setUserTypeEnum', userInfo.userTypeEnum)
  store.commit('common/setQuestionnaire', userInfo.pendingQuestionnaireCompletion)
  store.commit('common/setOpenAccountAction', userInfo.openAccountActionEnabled)
  store.commit('common/setCountryCode', userInfo.countryCode)
  store.commit('common/setAccessToken', userInfo.accessToken)
  store.commit('common/setEmail', userInfo.email)
  setCookies('token', userInfo.accessToken, 30)
  store.dispatch('register/actionChangePoiAuditStatus', poiAuditStatus)
  store.dispatch('register/actionChangePoaAuditStatus', poaAuditStatus)
  store.dispatch('register/actionChangeIbtPoiAuditStatus', ibtPoiAuditStatus)
  store.dispatch('register/actionChangeIbtPoaAuditStatus', ibtPoaAuditStatus)
  store.commit('common/setId3Pass', userInfo.id3Pass ?? false)
  store.commit('common/setRegulator', getRegulator(userInfo.regulator))
  store.commit('common/setEmailMasked', userInfo.emailMasked)
  store.commit('common/setUserId', userInfo.userId)
  store.commit('common/setEventEmailId', userInfo.eventEmailId)
  store.commit('common/setEventMobileId', userInfo.eventMobileId)

  sensorsRegister({
    is_login: true,
  })
  sensorsBindLogin(userInfo.userId, userInfo.eventEmailId, userInfo.eventMobileId)

  // store is-prop-trading-account to store
  store.dispatch('propTrading/actionChangeIsPropTrading', userInfo.propTrading)

  //if user didn't change language at login page, change the language by customer's preference
  if (!store.state.common.isChangedLangAtLandingPage) {
    store.commit('common/setLang', userInfo.language)
    //if changed, save the user's preference
  } else {
    apiSetlanguage({ language: store.state.common.lang })
  }
  store.commit('common/setIbUrl', userInfo.url)

  store.dispatch('register/actionStep', getRegisterStepByNum(userInfo.registerStep, poaAuditStatus))
  if (userInfo.demo) store.commit('common/setDemo', true)
  if (userInfo.registerStep >= 5) store.commit('register/SET_DISABLE_FIELD', true)
}

function relogin(resp) {
  MessageBox.confirm(resp.data.errmsg + ' Would you like to log out this account and then log in again?', 'Info', {
    confirmButtonText: 'Yes',
    cancelButtonText: 'Cancel',
    type: 'info',
  }).then(() => {
    signOut(521)
  })
}

function signOut(method, reason) {
  store.commit('common/setAuthHeaders', {
    etxId: '',
    eCode: '',
    ptxId: '',
    pCode: '',
    tCode: '',
  })

  const requestData = {
    reason: reason,
  }

  if (store.state.common.extras.logoutStacktrace) {
    requestData['stack'] = store.state.common.extras.logoutStacktrace
  }

  if (store.state.common.loginStatus || method === 521) {
    apiTo_logout(requestData).then(resp => {
      store.commit('common/setLoginStatus', '')
      store.commit('common/setAutoNotifyStatus')
      store.commit('register/reset')
      store.commit('regulator/reset')
      store.commit('payment/reset')
      store.commit('promotion/reset')

      setCookies('token', '')
      sensorsRegister({
        is_login: false,
      })
      sensorsLogout()
      redirect.redirectToLogoutUrl()
    })
  } else {
    sensorsRegister({
      is_login: false,
    })
    sensorsLogout()
    redirect.redirectToLogoutUrl()
  }
}

// 提取code码为1201时的公共处理部分 邮箱登录、手机号登录、注册登录都需要该code码的处理
function handleSecurityResp(resp, method, requestParams) {
  const respBody = resp.data.data
  store.commit('common/setLoginCredentials', { method: method, request: requestParams })
  store.commit('common/setEmailMasked', respBody.encodeEmail)
  store.commit('common/setRegulator', getRegulator(respBody.r))
  store.commit('regulator/setContactEmail', respBody.contactEmail)

  const lackAuthItems = respBody.lackAuthMethods.map(item => {
    const showVerified = mapVerifiedAuth(item, respBody.authStatus)
    return {
      type: item,
      authenticated: false,
      verified: showVerified,
      path: getToPath(item, showVerified),
    }
  })
  let usedRequiredAuths = store.state.common.requiredAuths
  usedRequiredAuths['login'] = respBody.lackAuthMethods.concat(respBody.effectiveAuthMethods)
  store.commit('common/setRequiredAuths', usedRequiredAuths)

  store.commit('common/setCheckedAuthStatus', lackAuthItems)
  const pushObject = lackAuthItems.find(item => !item.authenticated)

  if (pushObject?.path != router.currentRoute.path) {
    router.push(pushObject?.path)
  }
}

export default {
  async signIn(userName, password) {
    const loginRequest = {
      userName_login: userName,
      password_login: password,
      utc: 39600000,
    }
    apiTo_login(loginRequest)
      .then(resp => {
        if (resp.data.code === 427) {
          //Password reset every 90 days.
          const callbackData = resp.data.data
          router.push({
            name: 'forceChangePassword',
            params: {
              token: callbackData.token,
              maskedEmail: callbackData.email,
              callbackRegulator: callbackData.regulator,
            },
          })
        } else if (resp.data.code === 0) {
          store.dispatch('regulator/actionGetInformation')
          setUserProfile(resp)
          store.commit('common/setLoginCredentials', { method: 'signIn', request: null })
          if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
          else if (resp.data.data.demo) router.push('/homeDemo')
          else if (resp.data.data.registerStep < 6) router.push('/register')
          else router.push('/home')
        } else if (resp.data.code === 521) {
          //A user already logged in on this device
          relogin(resp)
        } else if (resp.data.code === 529) {
          //input wrong password more than 2 times and invoke slide verification
          store.commit('common/setFailedSecondTime', true)
        } else if (resp.data.code === 1201) {
          handleSecurityResp(resp, 'signIn', loginRequest)
        } else if (resp.data.code === 1202) {
          store.commit('common/setAuthHeaders', {
            etxId: '',
            eCode: '',
            ptxId: '',
            pCode: '',
            tCode: '',
          })
          router.push('/login')
        }
      })
      .catch(error => {
        console.log(error)
      })
  },

  signInMobile(countrycode, userName, password) {
    const loginRequest = {
      country_code: countrycode,
      mobile_login: userName,
      password_login: password,
      utc: 39600000,
    }
    apiTo_login(loginRequest).then(resp => {
      if (resp.data.code === 427) {
        //Password reset every 90 days.
        const callbackData = resp.data.data
        router.push({
          name: 'forceChangePassword',
          params: {
            token: callbackData.token,
            maskedEmail: callbackData.email,
            callbackRegulator: callbackData.regulator,
          },
        })
      } else if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
        else if (resp.data.data.demo) router.push('/homeDemo')
        else if (resp.data.data.registerStep < 6) router.push('/register')
        else router.push('/home')
      } else if (resp.data.code === 521) {
        //A user already logged in on this device
        relogin(resp)
      } else if (resp.data.code === 529) {
        //input wrong password more than 2 times and invoke slide verification
        store.commit('common/setFailedSecondTime', true) // 滑动验证
      } else if (resp.data.code === 1201) {
        handleSecurityResp(resp, 'signInMobile', loginRequest)
      } else if (resp.data.code === 1202) {
        store.commit('common/setAuthHeaders', {
          etxId: '',
          eCode: '',
          ptxId: '',
          pCode: '',
          tCode: '',
        })
        router.push('/login')
      }
    })
  },
  signOut(method, reason) {
    signOut(method, reason)
  },
  refreshToken(accessToken, pk, path) {
    apiTo_login_by_token({
      headers: {
        accessToken: accessToken,
        pk: pk,
      },
    }).then(resp => {
      if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        store.commit('common/setAutoNotifyStatus', false)
        setUserProfile(resp)
        if (path) router.push(path)
        else router.push('/home')
      } else if (resp.data.code === 1500) {
        router.push('/home')
      }
    })
  },
  async register(registerToken) {
    const loginRequest = {
      a: registerToken,
    }

    apiTo_login_register(loginRequest)
      .then(resp => {
        if (resp.data.code === 0) {
          store.commit('common/setLoginCredentials', { method: 'register', request: null })
          store.dispatch('regulator/actionGetInformation')
          setUserProfile(resp)
          if (resp.data.data.userTypeEnum == 'IB') redirect.redirectToIbPortal()
          else if (resp.data.data.propTrading) router.push({ name: 'propTradingCreateAccount' })
          else if (resp.data.data.demo) router.push('/demo')
          else if (resp.data.data.registerStep < 6) router.push('/register')
          else router.push('/home')
        } else if (resp.data.code === 1201) {
          handleSecurityResp(resp, 'register', loginRequest)
        } else if (resp.data.code === 1202) {
          store.commit('common/setAuthHeaders', {
            etxId: '',
            eCode: '',
            ptxId: '',
            pCode: '',
            tCode: '',
          })
          router.push('/login')
        } else {
          router.push('/login')
        }
      })
      .catch(error => {})
  },
  smsCodeLogin(token) {
    apiTo_sms_code_login({
      b: token,
    }).then(resp => {
      console.log(resp)
      if (resp.data.code === 0) {
        store.dispatch('regulator/actionGetInformation')
        setUserProfile(resp)
        if (resp.data.data.resetMobilePassword) {
          router.push({ path: 'resetPasswordMobile', query: { resetToken: resp.data.data.accessToken } })
        }
      } else {
        router.push('/loginByMobile')
      }
    })
  },
}
