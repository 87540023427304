export default {
  setPayment(state, value) {
    state.payment = value
  },
  reset(state) {
    state.payment = {}
    state.withdrawalPayment = {}
    state.secondaryWithdrawalPayment = {}
  },
  setWithdrawalPayment(state, value) {
    state.withdrawalPayment = value
  },
  setSecondaryWithdrawalPayment(state, value) {
    state.secondaryWithdrawalPayment = value
  },
}
