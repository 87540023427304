export const cpsCurrencyCode = {
  USD: '840',
  AUD: '036',
  GBP: '826',
  EUR: '978',
  NZD: '554',
  CAD: '124',
  SGD: '702',
  HKD: '344',
  JPY: '392',
  PLN: '985',
  BRL: '986',
  USC: '840',
}
