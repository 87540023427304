import {
  DEPOSIT_BONUS_PATHNAME,
  DYNAMIC_DEPOSIT_BONUS_PATHNAME,
  NO_DEPOSIT_BONUS_3_PATHNAME,
  REFER_A_FRIEND_PATHNAME,
  TRADING_BONUS_PATHNAME,
  CASHBACK_PATHNAME,
  DEPOSIT_20_BONUS_PATHNAME,
  XMAS_MAKE_A_WISH_PATHNAME,
  FIRST_DEPOSIT_CASHBACK_PATHNAME,
  MILLION_DOLLAR_CASH_POOL_PATHNAME,
  FIRST_TIME_DEPOSIT_PATHNAME,
  // RUGBY_PATHNAME,
  DEPOSIT_MY_20_BONUS_PATHNAME,
  XMAS_SPIN_AND_WIN_PATHNAME,
  NFP_PATHNAME,
  VDAY_PROMO_PATHNAME,
  TRADING_CONTEST_PATHNAME,
  EURO2024_PATHNAME,
  OLYMPIC2024_PATHNAME,
  FUNDS_GROWTH_PROMO_PATHNAME,
} from '@/constants/route'
import { countryCodeEnum as COUNTRYCODEENUM } from '@/constants/country'
import moment from 'moment'

export const PROMOTION_TYPE = {
  NO_DEPOSIT_BONUS_3: 'NO_DEPOSIT_BONUS_3',
  REFER_A_FRIEND: 'REFER_A_FRIEND_3',
  DEPOSIT_BONUS: 'DEPOSIT_BONUS_2',
  TRADING_BONUS: 'TRADING_BONUS',
  CASHBACK: 'CASHBACK',
  XMASMAKEAWISH: 'LUCKY_DRAW',
  FIRST_DEPOSIT_CASHBACK: 'FIRST_TIME_DEPOSIT_BONUS_CASHBACK',
  MILLION_DOLLAR_CASH_POOL: 'MILLION_DOLLAR_CASH_POOL',
  FIRST_TIME_DEPOSIT: 'FIRST_TIME_DEPOSIT',
  NFP: 'NFP',
  XMAS_SPIN_AND_WIN: 'XMAS_SPIN_AND_WIN',
  VDAY_PROMO: 'VDAY_PROMO',
  TRADING_CONTEST: 'TRADING_CONTEST',
  EURO2024: 'EURO2024',
  OLYMPIC2024: 'OLYMPIC2024',
  FUNDS_GROWTH_PROMO: 'FUNDS_GROWTH_PROMO',
}

export const PROMOTION_TYPE_PATH_MAP = {
  [PROMOTION_TYPE.NO_DEPOSIT_BONUS_3]: NO_DEPOSIT_BONUS_3_PATHNAME,
  [PROMOTION_TYPE.REFER_A_FRIEND]: REFER_A_FRIEND_PATHNAME,
  [PROMOTION_TYPE.TRADING_BONUS]: TRADING_BONUS_PATHNAME,
  [PROMOTION_TYPE.CASHBACK]: CASHBACK_PATHNAME,
  [PROMOTION_TYPE.XMASMAKEAWISH]: XMAS_MAKE_A_WISH_PATHNAME,
  [PROMOTION_TYPE.FIRST_DEPOSIT_CASHBACK]: FIRST_DEPOSIT_CASHBACK_PATHNAME,
  [PROMOTION_TYPE.MILLION_DOLLAR_CASH_POOL]: MILLION_DOLLAR_CASH_POOL_PATHNAME,
  [PROMOTION_TYPE.FIRST_TIME_DEPOSIT]: FIRST_TIME_DEPOSIT_PATHNAME,
  [PROMOTION_TYPE.DEPOSIT_BONUS]: DYNAMIC_DEPOSIT_BONUS_PATHNAME,
  // [PROMOTION_TYPE.RUGBY]: RUGBY_PATHNAME,
  [PROMOTION_TYPE.XMAS_SPIN_AND_WIN]: XMAS_SPIN_AND_WIN_PATHNAME,
  [PROMOTION_TYPE.NFP]: NFP_PATHNAME,
  [PROMOTION_TYPE.VDAY_PROMO]: VDAY_PROMO_PATHNAME,
  [PROMOTION_TYPE.TRADING_CONTEST]: TRADING_CONTEST_PATHNAME,
  [PROMOTION_TYPE.EURO2024]: EURO2024_PATHNAME,
  [PROMOTION_TYPE.OLYMPIC2024]: OLYMPIC2024_PATHNAME,
  [PROMOTION_TYPE.FUNDS_GROWTH_PROMO]: FUNDS_GROWTH_PROMO_PATHNAME,
}

export const PROMOTION_ID_PATH_MAP = {
  // 6: DEPOSIT_BONUS_PATHNAME,
  // 32: DEPOSIT_20_BONUS_PATHNAME,
  // 43: DEPOSIT_MY_20_BONUS_PATHNAME, //MY20
}

export const PROMOTION_WITH_CHILDS = [
  PROMOTION_TYPE.DEPOSIT_BONUS,
  PROMOTION_TYPE.TRADING_CONTEST,
  PROMOTION_TYPE.NO_DEPOSIT_BONUS_3,
]

export const PROMOTION_MENU_ICON = {
  [PROMOTION_TYPE.DEPOSIT_BONUS]: require('@/assets/images/menu/menu_icon_generic.png'),
  [PROMOTION_TYPE.TRADING_CONTEST]: require('@/assets/images/menu/menu_icon_tc.png'),
  [PROMOTION_TYPE.NO_DEPOSIT_BONUS_3]: require('@/assets/images/menu/menu_icon_generic.png'),
}

export const FE_ONLY_CAMPAIGN = {
  // millionDollarCashPool: {
  //   id: 1000,
  //   campaignType: 'MILLION_DOLLAR_CASH_POOL',
  //   excludeCountry: ['CHINA'],
  // },

  // rugby: {
  //   id: 1001,
  //   campaignType: PROMOTION_TYPE.RUGBY,
  //   excludeCountry: ['CHINA'],
  //   expired: moment('2023-10-28 02:00:00'), // campaign finish date
  // },
  xmasSpinAndWin: {
    id: 1002,
    campaignType: PROMOTION_TYPE.XMAS_SPIN_AND_WIN,
    expired: moment('2024-01-01 02:00:00'), // campaign finish date
  },
  nfp: {
    id: 1003,
    campaignType: PROMOTION_TYPE.NFP,
    expired: null,
  },
  vdayPromo: {
    id: 1004,
    campaignType: PROMOTION_TYPE.VDAY_PROMO,
    expired: moment('2024-03-01 00:00:00'), // campaign finish date
  },
  euro2024: {
    id: 1005,
    campaignType: PROMOTION_TYPE.EURO2024,
    expired: moment('2024-07-29 00:00:00'), // campaign finish date
  },
  olympic2024: {
    id: 1006,
    campaignType: PROMOTION_TYPE.OLYMPIC2024,
    expired: moment('2024-08-26 00:00:00'), // campaign finish date
  },
}

export const addFEOnlyCampaign = (campaignArray = [], countryCode, feOnlyCampaigns) => {
  if (!countryCode) return [...campaignArray]

  const uniqueCampaigns = new Set(campaignArray.map(campaign => campaign.id))
  const res = [...campaignArray]

  feOnlyCampaigns.forEach(campaignItem => {
    const excludeCountry =
      campaignItem.excludeCountry && campaignItem.excludeCountry.length ? [...campaignItem.excludeCountry] : []

    const excludeCountryCodeArray = excludeCountry.length
      ? excludeCountry.reduce((acc, item) => {
          if (COUNTRYCODEENUM[item]) {
            acc.push(COUNTRYCODEENUM[item])
            return acc
          }
        }, [])
      : []

    if (
      !excludeCountryCodeArray.includes(Number(countryCode)) &&
      !uniqueCampaigns.has(campaignItem.id) // Check if the campaign is not already in the array
    ) {
      res.push(campaignItem)
      uniqueCampaigns.add(campaignItem.id) // Add campaign ID to the set
    }
  })

  return [...res]
}
