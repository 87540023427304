<template>
  <InternationalDefaultBankInfo :isShow="isShow" :currency="currency"></InternationalDefaultBankInfo>
</template>

<script>
import InternationalDefaultBankInfo from '@/components/template/deposit/InternationalDefaultBankInfo';

export default {
  name: 'InternationalBankInfo',
  props: { isShow: Boolean, currency: String },
  components: { InternationalDefaultBankInfo }
};
</script>
